import { DEVSA2_DOMAINS } from "../constants";
import { handleResponse, handleError } from "./apiUtils";
const baseUrl = DEVSA2_DOMAINS.includes(window.location.host) ? 'https://devsa2.amakkn.com' : process.env.REACT_APP_API_URL;

function POST(data) {
  return {
    method: "POST", // POST for create, PUT to update when id already exists.
    headers: { Accept: "application/json", "content-type": "application/json" },
    body: JSON.stringify({
      ...data,
    }),
  };
}

export function getMyNotifications(data) {
  return fetch(baseUrl + "/Notification/getMyNotifications/", POST(data))
    .then(res => handleResponse(res, data))
    .catch(handleError);
}

export function markAsRead(data) {
  return fetch(baseUrl + "/Notification/markAsRead/", POST(data))
    .then(res => handleResponse(res, data))
    .catch(handleError);
}

export function getUnsentNotifications(data) {
  return fetch(baseUrl + "/Notification/getUnsentNotifications/", POST(data))
    .then(res => handleResponse(res, data))
    .catch(handleError);
}
