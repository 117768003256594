import React, { useEffect, useState } from "react";
import { getProjects } from "../../api/userApi";
import { Link } from "react-router-dom";
import AppLayout from "../../components/layouts/app-layout";
import PageHeader from "../../components/global-components/page-header";
import { useAuth } from "../../helpers/context";
import { Marker } from "../../constants/icons";
import HolidayVillageIcon from '@mui/icons-material/HolidayVillage';
import { InfoOutlined } from "@mui/icons-material";
import NoData from "../../components/global-components/no-data";
import LoadingData from "../../components/global-components/loading-data";
import { CLASSIC_TEMPLATES, DARK_TEMPLATES, MODERN_TEMPLATES } from "../../constants";
import { Swiper, SwiperSlide } from "swiper/react";
import { Keyboard, Pagination } from "swiper";
import { useTranslation } from "react-i18next";
import { getLocalizedText } from "../../helpers";

const publicUrl = process.env.REACT_APP_URL + "/";

export default function Projects() {
  const [ProjectItems, setProjectItems] = useState([]);
  const [loadingData, setLoadingData] = useState(true);
  const { vendor, templateId } = useAuth();
  const { t, i18n } = useTranslation();

  useEffect(() => {
    if (vendor) {
      setLoadingData(true);
      getProjects({
        userName: vendor,
        language: i18n.language === 'ar' ? '0' : '1',
      }).then((_response) => {
        if (_response.resCode === 0) setProjectItems(_response.response);
      }).finally(() => setLoadingData(false));
    }
  }, [vendor, i18n.language]);

  useEffect(() => {
    window.scroll({ top: 0, left: 0, behavior: "smooth" });
  }, []);
  
  return (
    <AppLayout withFooter pageTitle={t("OurProjects")}>
      <PageHeader name={t("OurProjects")} />
      <div className='featured-props projects-page'>
        <div className='row justify-content-center pd-top-60'>
          <div className='row align-items-center col-12 pd-bottom-60'>
            {loadingData ? <LoadingData /> : ProjectItems.length > 0 ?
              ProjectItems.map((item, i) => (
                // <div key={i} className='col-lg-12 project-wrapper' data-aos='flip-left'
                //   style={{ marginTop:'20px', background: `url(${item.images.length > 0 ? item.images[0] : publicUrl + "assets/img/defimgs/1.svg"}) no-repeat center #D8D8D8`, backgroundSize: item.images.length > 0 ? 'cover': 'contains' }}>
                    
                //   <div className='single-project'>
                //     <img
                //       src={item.images.length > 0 ? item.images[0] : publicUrl + "assets/img/defimgs/1.svg"}
                //       alt={item.name}
                //       className={`project-img ${item.images.length > 0 ? "" : "contain-img"}`}
                //    />
                //     <div className='details'>
                //         <h4 className='project-title'>{item.name}</h4>
                //      {item.address && <p className='project-location'>{item.address}</p>} 
                //       {item.description && <p className='project-desc'>{item.description}</p>}
                //       {item.status && <h5 className='project-status'>{item.status}</h5>}
                //       <Link to={"/project/" + item.id} className="primary-btn py-2 px-3">
                //         المزيد من التفاصيل
                //     </Link>
                //     </div>
                       
                //   </div>
                // </div>
                <div
                  key={item.id}
                  className='col-xl-3 col-lg-4 col-md-6 col-sm-6'
                  style={{ cursor: "pointer" }}>
                  <div className={`single-project single-leading-feature ${[...MODERN_TEMPLATES, ...CLASSIC_TEMPLATES, ...DARK_TEMPLATES].includes(+templateId) ? 'single-project-v2' : ''}`}>
                    <Link to={"/project/" + item.id}>
                      <div className='thumb'>
                        {item.images?.length > 0 ?
                          <Swiper
                            spaceBetween={0}
                            key={i18n.language + 'ii'}
                            slidesPerView={1}
                            modules={[Keyboard, Pagination]}
                            keyboard={{ enabled: true }}
                            pagination={{
                              clickable: true, el: `.swiper-pagination.pagination-${item.id}`,
                              dynamicBullets: true,
                              dynamicMainBullets: 4
                            }}
                            speed={2600}>
                            {item.images?.map((image, index) => (
                              <SwiperSlide key={index}>
                                <img src={image} alt={`${getLocalizedText(item.name, i18n.language, false)} ${index}`} />
                              </SwiperSlide>
                            ))}
                          </Swiper>
                          :
                          <img
                            src={item.images.length > 0 ? item.images[0] : publicUrl + "assets/img/defimgs/1.svg"}
                            alt={getLocalizedText(item.name, i18n.language, false)}
                            className={item.images.length > 0 ? "" : "contain-img"}
                          />}
                        {item.images?.length > 0 ? <div className={`swiper-pagination pagination-${item.id}`}></div> : null}
                        
                        {[...MODERN_TEMPLATES, ...CLASSIC_TEMPLATES, ...DARK_TEMPLATES].includes(+templateId) ? <div className='project-status'>
                          {t("ResidentialUnit", { count: item.totalUnits })} / {item.status ? getLocalizedText(item.status, i18n.language, false) : ''}
                        </div> : null}
                      </div>
                      <div className='details details2'>
                        <h4 className='title property-name'>
                          {getLocalizedText(item.name, i18n.language, false)}
                        </h4>
                        {[...MODERN_TEMPLATES, ...CLASSIC_TEMPLATES, ...DARK_TEMPLATES].includes(+templateId) ? <>
                          <h5 className='project-address'>
                            {item.address}
                          </h5>
                        </> :
                          <div style={{ display: 'flex', gap: '10px', lineHeight: 1, alignItems: 'center' }}>
                            <span style={{ width: '15px' }}><Marker /></span>
                            <h5 className='price '>
                              {item.address}
                            </h5>
                          </div>
                        }
                        {[...MODERN_TEMPLATES, ...CLASSIC_TEMPLATES, ...DARK_TEMPLATES].includes(+templateId) ? null :
                          <div style={{ display: 'flex', gap: '10px', lineHeight: 1, alignItems: 'center', marginTop: '4px' }}>
                            <span style={{ width: '15px' }}><HolidayVillageIcon sx={{ width: '20px', color: 'var(--main-color-one)' }} /></span>
                            <h5 className='price'>
                              {t("ResidentialUnit", { count: item.totalUnits })}
                            </h5>
                          </div>
                        }
                        {item.status && ![...MODERN_TEMPLATES, ...CLASSIC_TEMPLATES, ...DARK_TEMPLATES].includes(+templateId) && (
                          <div style={{ display: 'flex', gap: '10px', lineHeight: 1, alignItems: 'center', marginTop: '4px' }}>
                            <span style={{ width: '15px' }}><InfoOutlined sx={{ width: '20px', color: 'var(--main-color-one)' }} /></span>
                            <h5 className='price '>
                              {getLocalizedText(item.status, i18n.language, false)}
                            </h5>
                          </div>
                        )}
                      </div>
                    </Link>

                  </div>
                </div>
              )) : <NoData msg={t("ThereAreNoProjects")} />}
          </div>
        </div>
      </div>
    </AppLayout>
  );
}
