import React, { useRef } from "react";
import html2canvas from "html2canvas";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import { useState } from "react";
import { useEffect } from "react";
import { getFontspace } from "../helpers";
import QRCode from "react-qr-code";
import { GreenCheck, RedClose } from "../constants/icons";
import LoadingButton from "./global-components/loading-btn";
import jsPDF from 'jspdf';
import { ClickAwayListener, Grow, MenuItem, MenuList, Paper, Popper } from "@mui/material";
import { getPropertyDescription } from "../api/propertyApi";
import { useParams } from "react-router-dom";
import { DEVSA2_DOMAINS, HIDDEN_ADITTIONAL_INFO_FOR_PAPER } from "../constants";
import { useTranslation } from "react-i18next";

const publicUrl = process.env.REACT_APP_URL + "/";

export default function PropertyPapper({ data }) {
  const exportRef = useRef();
  const isHidden = true;
  const [image, setImage] = useState("");
  const [logo, setLogo] = useState("");
  const [roomsAndFeatuers, setRoomsAndFeatuers] = useState([]);
  const [additionalInfo, setAdditionalInfo] = useState([]);
  const [property, setProperty] = useState(data);
  const [exportingImage, setExportingImage] = useState(false);
  const [open, setOpen] = useState(false);
  const anchorRef = useRef(null);
  const { t, i18n } = useTranslation();
  const options = [t("DownloadAsImage"), t("DownloadAsPDF")];
  const { id, type } = useParams();
  // const [exportingPdf, setExportingPdf] = useState(false);

  const getBase64FromUrl = async (url, type) => {
    const data = await fetch(url);
    const blob = await data.blob();
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onloadend = () => {
        const base64data = reader.result;
        if (type === 'logo') {
          setLogo(base64data);
        } else {
          setImage(base64data);
        }
        
        resolve(base64data);
      };
    });
  };

  const handlePropertyData = (property) => {
    if (property.photosNew) {
      //   setImage(property.photosNew.split(",")[0]);
      let imgUrl = property.photosNew.split(",")[0];
      imgUrl = imgUrl.split("?")[0];
      imgUrl = imgUrl.indexOf('#') > -1 ? imgUrl.substring(0, imgUrl.indexOf('#')) : imgUrl;
      getBase64FromUrl(imgUrl + '?new', 'image');
    } else {
      setImage(
        publicUrl + "assets/img/defimgs/" + property.propertyType + ".png"
      );
    }
    if ((property.hostInfo?.userType !== "5" && property.hostInfo?.avatar) || (property.hostInfo?.userType === "5" && property.hostInfo?.companyAvatar)) {
      getBase64FromUrl(property.hostInfo?.userType === "5" ? property.hostInfo?.companyAvatar : property.hostInfo?.avatar + "?new", 'logo');
    }
    // startDownload(property.photosNew.split(",")[0]);

    if ((property.rooms.length > 0 || property.features.length > 0) && roomsAndFeatuers.length === 0) {
      let temp = [], roomsTemp = [], featuresTemp = [];
      property.rooms.map((item, i) => {
        if (i % 2 === 0) {
          temp = [];
          temp.push({
            name: item.name, value: item.key === "11" || item.key === "26" ? (item.value === "0" ? t("GroundFloor") : item.value) : (item.value === '0' ? t("NotAvailable") : item.value)
          });
          if (i === property.rooms.length - 1) {
            roomsTemp.push(temp);
          }
        } else {
          temp.push({
            name: item.name, value: item.key === "11" || item.key === "26" ? (item.value === "0" ? t("GroundFloor") : item.value) : (item.value === '0' ? t("NotAvailable") : item.value)
          });
          roomsTemp.push(temp);
        }
        return roomsTemp;
      });

      temp = [];
      property.features.map((item, i) => {
        if (i % 2 === 0) {
          temp = [];
       
          temp.push({ ...item, value: item.key === "7" ? getFontspace(item.value) : Intl.NumberFormat("en").format(item.value) + " " + item.unit });
          if (i === property.features.length - 1) {
            featuresTemp.push(temp);
          }
        } else {
          temp.push({ ...item, value: item.key === "7" ? getFontspace(item.value) : Intl.NumberFormat("en").format(item.value) + " " + item.unit });
          featuresTemp.push(temp);
        }
        return featuresTemp;
      });
      setRoomsAndFeatuers([...roomsTemp, ...featuresTemp]);
    }

   
    if (property.additionalInfo?.filter((i) =>
      !HIDDEN_ADITTIONAL_INFO_FOR_PAPER.includes(i.key)).length > 0 && additionalInfo.length === 0) {
      let additionalInfoTemp = [],
        temp = [],
        additionalData = property.additionalInfo.filter((i) => !HIDDEN_ADITTIONAL_INFO_FOR_PAPER.includes(i.key));

      additionalData.map((item, i) => {
        if (i % 2 === 0) {
          temp = [];
          temp.push(item);
          if (i === additionalData.length - 1) {
            additionalInfoTemp.push(temp);
          }
        } else {
          temp.push(item);
          additionalInfoTemp.push(temp);
        }
        return additionalInfoTemp;
      });
      setAdditionalInfo(additionalInfoTemp);
    }
  }

  useEffect(
    () => {
      if (property) {
        handlePropertyData(property)
      } else {
        getPropertyDescription({ propertyId: id, language: i18n.language === 'ar' ? '0' : '1' }).then((res) => {
          handlePropertyData(res.response);
          setProperty(res.response);
          let adLicense = res.response.additionalInfo.filter((i) => i.key === '10')?.[0]?.valueText;
          setTimeout(() => {
            if (type === "pdf") {
              exportAsImage(exportRef.current, `${t("RealEstateADImage")} ${adLicense}`, 'pdf');
            } else {
              exportAsImage(exportRef.current, `${t("RealEstateADImage")} ${adLicense}`, 'image');
            }
          }, 500);
        });
      }
    },
    // eslint-disable-next-line
    [id, type]
  );

  const exportAsImage = async (element, imageFileName, type) => {
    const doc = new jsPDF({
      format: 'a4',
      unit: 'px',
    });

    try {
      html2canvas(element, {
        useCORS: true,
        allowTaint: true,
        windowWidth: element.scrollWidth,
        windowHeight: element.scrollHeight,
      })
        .then((canvas) => {
          const image = canvas.toDataURL("image/png", 1.0);
          if (type === 'image')
            downloadImage(image, imageFileName);
          else {
            doc.addImage(image, 'PNG', 0, 0, 445, 630);
            doc.save(t("RealEstateADImage"));
          }
        
        })
        .finally(() => {
          // setExportingPdf(false);
          setExportingImage(false);
        });
    } finally {
      setExportingImage(false);
      // setExportingPdf(false);
    }

 
  };

  const downloadImage = (blob, fileName) => {
    const fakeLink = window.document.createElement("a");
    fakeLink.style = "display:none;";
    fakeLink.download = fileName;
    fakeLink.href = blob;
    document.body.appendChild(fakeLink);
    fakeLink.click();
    document.body.removeChild(fakeLink);
    fakeLink.remove();
  };

  return (
    <>
      {!window.location.href.includes("get-property-image") ? (
        <div ref={anchorRef}>
          <LoadingButton classes='btn fav-btn btn-white-bg btn-info-bg text-info info-border px-2'
            icon={<CloudDownloadIcon />}
            loading={exportingImage}
            handleClick={() => {
              setOpen((prevOpen) => !prevOpen);
            }}
            iconLeft
            label={t("DownloadTheADImage")} />
              
          <Popper
            sx={{
              zIndex: 1,
            }}
            open={open}
            anchorEl={anchorRef.current}
            role={undefined}
            transition
            disablePortal
          >
            {({ TransitionProps, placement }) => (
              <Grow
                {...TransitionProps}
                style={{
                  transformOrigin:
                    placement === 'bottom' ? 'center top' : 'center bottom',
                }}
              >
                <Paper>
                  <ClickAwayListener onClickAway={(event) => {
                    if (anchorRef.current && anchorRef.current.contains(event.target)) return;
                    setOpen(false);
                  }}>
                    <MenuList id="split-button-menu">
                      {options.map((option, index) => (
                        <MenuItem
                          key={option}
                          onClick={(event) => {
                            setOpen(false);
                            setExportingImage(true);
                            exportAsImage(exportRef.current, t("RealEstateADImage"), index === 0 ? 'image' : 'pdf');
                          }}
                        >
                          {option}
                        </MenuItem>
                      ))}
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>
        </div>
      ) : (
        // <div className="d-flex align-items-center justify-content-center flex-column" style={{ gap: 15, height: '100vh' }}>
        //   <LoadingButton classes='btn fav-btn btn-white-bg btn-info-bg text-info info-border'
        //     icon={<CloudDownloadIcon />}
        //     style={{ width: 190 }}
        //     loading={exportingImage}
        //     handleClick={() => {
        //       setExportingImage(true);
        //       exportAsImage(exportRef.current, "صورة الإعلان العقاري", 'image');
        //     }}
        //     iconLeft
        //     label={'تحميل كصورة'} />
        //   <LoadingButton classes='btn fav-btn btn-white-bg btn-info-bg text-info info-border'
        //     icon={<CloudDownloadIcon />}
        //     loading={exportingPdf}
        //     style={{ width: 190 }}
        //     handleClick={() => {
        //       setExportingPdf(true);
        //       exportAsImage(exportRef.current, "صورة الإعلان العقاري", 'pdf');
        //     }}
        //     iconLeft
        //     label={'تحميل كملف PDF'} />
        // </div>
        <>
        </>
      )}
          
      <div
        className='property-papper'
        style={{
          position: isHidden ? "fixed" : "relative",
          left: isHidden ? "10000px" : "unset",
        }}>
        <div
          style={{
            background: "#333",
            display: "flex",
            justifyContent: "center",
          }}>
          <div
            ref={exportRef}
            style={{
              width: 900,
              minHeight: 1100,
              display: "flex",
              justifyContent: "center",
              flexDirection: 'column',
              alignItems: 'center',
              gap: 20,
            }}>
            <div
              style={{
                width: "100%",
                height: "100%",
                // border: "2px solid #081963",
                // borderRadius: "10px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
                position: "relative",
                background:
                  "linear-gradient(150deg, rgba(255, 255, 255, 1) 0%, rgba(245, 245, 245, 1) 50%, rgba(255, 255, 255, 1) 100%)",
              }}>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  gap: 15,
                  marginTop: 30,
                  width: '93%',
                  fontSize: 16,
                  color: "#081963",
                }}>
                <div style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  gap: 15
                }}>
                  {logo && (
                    <img
                      src={logo}
                      style={{
                        objectFit: "contain",
                        maxWidth: "90px",
                      }}
                      alt='company avatar'
                    />
                  )}
                  <div style={{
                    display: "flex",
                    flexDirection: 'column',
                    gap: 2
                  }}>
                    <span style={{ fontWeight: 700 }}> {property?.hostInfo?.name}</span>
                    <span>
                      {property?.hostInfo?.commercialRecordNumber && (
                        <>
                          {t("CommercialRegistrationNo")}:  {property?.hostInfo?.commercialRecordNumber}
                        </>
                      )}
                    </span>
                    <span>
                      {property?.additionalInfo.length > 0 &&
                        property?.additionalInfo.filter((i) => i.key === "21")
                          ?.length > 0 &&
                        property?.additionalInfo.filter((i) => i.key === "21")[0]
                          .valueText !== "لا" && (
                          <>
                            {t("FalLicense")}:
                            {' ' +
                              property?.additionalInfo.filter(
                                (i) => i.key === "21"
                              )[0].valueText
                            }
                          </>
                        )}
                    </span>
                    {property?.hostInfo?.website && (
                      <span>
                        {t("Website")}:  {property?.hostInfo?.website}
                      </span>
                    )}
                  </div>
                </div>
              </div>
            
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: 'space-between',
                  gap: 30,
                  marginTop: 10,
                  width: '93%',
                  fontSize: 16,
                  color: "#081963",
                }}>
                <div style={{ width: '50%' }}>
                  <div
                    style={{
                      margin: '10px auto 10px',
                      height: 280,
                      width: 380,
                      borderRadius: '10px',
                      background: '#eee',
                      textAlign: 'center',
                      display: 'flex'
                    }}>
                    <img
                      src={image}
                      style={{
                        maxHeight: 280,
                        maxWidth: 380,
                        margin: 'auto',
                        objectFit: 'contain',
                        borderRadius: '10px'
                      }}
                      alt='property img'
                    />
                  </div>
                </div>
                <div style={{
                  display: "flex",
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                  textAlign: 'center',
                  gap: 10,
                  width: '50%',
                  position: 'relative',
                  overflow: 'hidden',
                  background: '#eee',
                  paddingBottom: 10,
                  borderRadius: 10
                }}>
                  <div className="ribbon ribbon-top-left black">
                    <span style={{ fontWeight: 700, fontSize: 15 }}>
                      {`${property?.propertyTypeName} ${property?.listedFor === "2" ? t("ForSale") : t("ForRent")}`}
                    </span>
                  </div>
                  <div
                    style={{
                      height: "auto",
                      margin: "35px auto 0",
                      maxWidth: 125,
                      width: "100%",
                    }}>
                    <QRCode
                      size={256}
                      fgColor='#444'
                      style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                      value={`https://www.amakkn${DEVSA2_DOMAINS.includes(window.location.host)|| process.env.REACT_APP_API_URL?.includes('devsa2') ? '.org' : '.com'}/property-details/${property?.idToShare}`}
                      viewBox={`0 0 256 256`}
                    />
                  </div>
                  <span>{property?.address}</span>
                  
                  {property?.additionalInfo.length > 0 &&
                    property?.additionalInfo.filter((i) => i.key === "10")?.length > 0 && (
                      <span>
                        {
                          `${property?.additionalInfo.filter((i) => i.key === "10")[0]
                            .name}: ${property?.additionalInfo.filter((i) => i.key === "10")[0]
                              .valueText}`
                        }
                      </span>
                    )}
                  <span style={{ fontWeight: 700, fontSize: 19 }}>{t("Price")}:  {Intl.NumberFormat("en").format(
                    property?.defaultPrice
                  )}{" "}
                    {t("SAR")}
                    {property?.listedFor === "1" && (
                      <span>
                        &nbsp;({property?.defaultPriceType.name})
                      </span>
                    )}
                  </span>
                </div>
              </div>

              <table
                border={1}
                className="no-background"
                style={{
                  width: "93%",
                  margin: "auto",
                  marginTop: 30,
                  borderColor: "#999",
                  fontSize: 15,
                  background: '#fff'
                }}>
                <thead style={{ textAlign: 'center' }}>
                  <tr>
                    <td
                      colSpan={4}
                      style={{
                        padding: 8,
                        background: "#eee",
                        color: "#000",
                        fontSize: 18,
                        fontWeight: 600
                      }}>
                      <span style={{
                        display: 'block',
                        margin: 'auto',
                        textAlign: 'center',
                        width: 'fit-content',
                        color: '#111'
                      }}>
                        {t("RealEstateADData")}
                      </span>
                      
                    </td>
                  </tr>
                </thead>
                <tbody>
                  {/* <tr>
                    <td className="paper-table-cell">
                      {t("PropertyType")}
                    </td>
                    <td style={{ width: "25%", padding: "4px 10px" }}>
                      {property.propertyTypeName}
                    </td>
                    <td className="paper-table-cell">
                      الغرض من الإعلان
                    </td>
                    <td style={{ width: "25%", padding: "4px 10px" }}>
                      {property.listedFor === "2" ? t("ForSale") : t("ForRent")}
                    </td>
                  </tr> */}

                  {/* {property.additionalInfo.length > 0 &&
                    property.additionalInfo.filter((i) =>
                      ["16", "17"].includes(i.key)
                    )?.length > 0 && (
                      <tr>
                        <td className="paper-table-cell">
                          {
                            property.additionalInfo.filter(
                              (i) => i.key === "16"
                            )[0].name
                          }
                        </td>
                        <td style={{ width: "25%", padding: "4px 10px" }}>
                          {
                            property.additionalInfo.filter(
                              (i) => i.key === "16"
                            )[0].valueText
                          }
                        </td>
                        <td className="paper-table-cell">
                          {
                            property.additionalInfo.filter(
                              (i) => i.key === "17"
                            )[0].name
                          }
                        </td>
                        <td style={{ width: "25%", padding: "4px 10px" }}>
                          {
                            property.additionalInfo.filter(
                              (i) => i.key === "17"
                            )[0].valueText
                          }
                        </td>
                      </tr>
                    )} */}
                  {roomsAndFeatuers.length > 0 &&
                    roomsAndFeatuers.map((item, index) => {
                      let room = item;
                      return (
                        <tr key={index}>
                          {room[0] && (
                            <>
                              <td className="paper-table-cell">
                                {room[0].name}{" "}
                              </td>
                              <td style={{ width: "25%", padding: "4px 10px", background: '#fff', color: '#111' }}>
                                <span style={{
                                  display: 'block',
                                  width: 'fit-content',
                                  margin: 'auto',
                                  color: '#111'
                                }}>
                                  {room[0].value}
                                </span>
                              </td>
                            </>
                          )}
                          {room[1] && (
                            <>
                              <td className="paper-table-cell">
                                {room[1].name}{" "}
                              </td>
                              <td style={{ width: "25%", padding: "4px 10px", background: '#fff', color: '#111' }}>
                                <span style={{
                                  display: 'block',
                                  width: 'fit-content',
                                  margin: 'auto',
                                  color: '#111'
                                }}>
                                  {room[1].value}
                                </span>
                              </td>
                            </>
                          )}
                        </tr>
                      );
                    })}
               

               

                  {property?.amenities?.length > 0 && (
                    <tr>
                      <td className="paper-table-cell">
                        {t("Features")}
                      </td>
                      <td
                        colSpan={3}
                        style={{ width: "80%", padding: "4px 10px", background: '#fff', color: '#111' }}>
                        <span style={{
                          display: 'block',
                          width: 'fit-content',
                          margin: 'auto',
                          color: '#111'
                        }}>
                          {property?.amenities.map(
                            (item, i) =>
                              item.name +
                              (i === property?.amenities.length - 1 ? "" : ", ")
                          )}
                        </span>
                      </td>
                    </tr>
                  )}

                  {/* <tr>
                    <td className="paper-table-cell">
                      موقع العقار
                    </td>
                    <td
                      colSpan={3}
                      style={{ width: "80%", padding: "4px 10px" }}>
                      {property.address}
                    </td>
                  </tr> */}
                  {property?.additionalInfo.length > 0 &&
                    property?.additionalInfo.filter((i) =>
                      ["5", "6", "7", '50', '51', '52'].includes(i.key) && i.valueBoolean === "1"
                    )?.length > 0 && (
                      <tr>
                        <td className="paper-table-cell">{t("RealEstateRelatedServices")}</td>
                        <td
                          colSpan={3}
                          style={{ width: "80%", padding: "4px 10px", background: '#fff' }}>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              flexWrap: 'wrap',
                              color: '#111'
                            }}>
                            {property?.additionalInfo
                              .filter((i) => ["5", "6", "7", '50', '51', '52'].includes(i.key) && i.valueBoolean === "1")
                              .map((AIItem, i) => (
                                <span
                                  style={{
                                    display: "inline-flex",
                                    alignItems: "center",
                                    gap: 5,
                                    width: '33%',
                                    color: '#111'
                                  }}
                                  key={AIItem.key}>
                                  {AIItem.valueBoolean === "1" ? (
                                    <GreenCheck gray />
                                  ) : (
                                    <RedClose gray />
                                  )}
                                  <span
                                    style={{ top: -2, position: "relative" }}>
                                    {AIItem.name}
                                  </span>
                                </span>
                              ))}
                          </div>
                        </td>
                      </tr>
                    )}
                  
                  {additionalInfo?.length > 0 &&
                    additionalInfo?.map((item, index) => {
                      return (
                        <tr key={index}>
                          {item[0] && (
                            <>
                              <td className="paper-table-cell">
                                {item[0].name}{" "}
                              </td>
                              <td style={{ width: "25%", padding: "4px 10px", background: '#fff' }}>
                                <span style={{
                                  display: 'block',
                                  width: 'fit-content',
                                  margin: 'auto',
                                  color: '#111'
                                }}>
                                  {item[0].valueText === "0" ? "لا" : item[0].valueText === "1" ? t("Yes") : item[0].valueText}
                                </span>
                                
                              </td>
                            </>
                          )}
                          {item[1] && (
                            <>
                              <td className="paper-table-cell">
                                {item[1].name}{" "}
                              </td>
                              <td style={{ width: "25%", padding: "4px 10px", background: '#fff' }}>
                                <span style={{
                                  display: 'block',
                                  width: 'fit-content',
                                  margin: 'auto',
                                  color: '#111'
                                }}>
                                  {item[1].valueText === "0" ? "لا" : item[1].valueText === "1" ? t("Yes") : item[1].valueText}
                                </span>
                              </td>
                            </>
                          )}
                        </tr>
                      );
                    })}
                </tbody>
              </table>
              <span style={{ fontSize: 27, fontWeight: 400, marginBottom: 40, color: '#222' }}>
                {t("ToContact")}: &nbsp;
                {property?.hostInfo?.phone?.startsWith('0') ? property?.hostInfo?.phone : '0' + property?.hostInfo?.phone}
              </span>
              <hr style={{ borderTopColor: '#333', width: '100%' }} />

              <div style={{ textAlign: 'center', display: 'flex', marginBottom: 5 }}>
                <span style={{ color: 'black', fontSize: 15, fontWeight: 500 }}>{t("ServiceProvidedBy")}</span>
                <img
                  src={i18n.language === "ar" ? `https://${window.location.host}/assets/img/Logo_black.png` : `https://${window.location.host}/assets/img/amakknLogo.png`}
                  alt='amakkn-logo'
                  style={{
                    position: "relative",
                    top: "-7px",
                    right: i18n.language === "ar" ? '3px' : "unset",
                    marginInline: i18n.language !=="ar" ?'6px':"0",
                    width: i18n.language === "ar" ? "80px" : '30px'
                  }}
                />
                {i18n.language !== "ar" && <span style={{ color: '#000', fontSize: 15, fontWeight: 500 }}>Amakkn</span>}

              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
