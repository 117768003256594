import { Close } from '@mui/icons-material';
import { Dialog, DialogContent, DialogTitle, Grid, IconButton } from '@mui/material';
import React, { useEffect, useState } from 'react'

import { useTranslation } from 'react-i18next';
import { getProjectDetails, removePropertyFromProject } from '../../../../api/userApi';
import PropertyCardForAdmin from '../../../shared/properties/components/property-card-for-admin';
import { openDeleteModal } from '../../../../helpers';
import { toast } from 'react-toastify';
import { Plus, Trash } from '../../../../constants/icons';
import { useAuth } from '../../../../helpers/context';
import NoData from '../../../../components/global-components/no-data';
import LoadingData from '../../../../components/global-components/loading-data';
import ResidentialUnitsPopup from '../residential-units-popup';

const RelatedADsModal = ({ id, open, onClose, onOK }) => {
    const [loading, setLoading] = useState(true);
    const { t, i18n } = useTranslation();
    const [data, setData] = useState();
    const { token } = useAuth();
    const [relatedADsDialog, setRelatedADsDialog] = useState(false);
    const [relatedProperties, setRelatedProperties] = useState([]);

    useEffect(() => {
        if (open)
            callGetProjectDetails();
    }, // eslint-disable-next-line
        [open]);
    
    function callGetProjectDetails() {
        setLoading(true);
        getProjectDetails({
            projectId: id,
            language: i18n.language === 'ar' ? '0' : '1',
        }).then((project) => {
            if (project.resCode === 0) {
                setData(project.response);
                setRelatedProperties(project.response.relatedProperties);
            }
        }).finally(() => setLoading(false));
    }

    return (
        <Dialog
            fullWidth={true}
            maxWidth={"lg"}
            open={open}
            className="custom-dialog model "
            onClose={onClose}>
            <DialogTitle>
                <div className="dialog-head flex-column flex-lg-row">
                    <div>
                        <h2>{t("ResidentialUnits")}</h2>
                    </div>
                    <div className="dialog-actions flex-row">
                        <button className="custom-btn px-4" onClick={() => setRelatedADsDialog(true)}>
                            <Plus /><span>{t("AddResidentialUnits")}</span>
                        </button>
                        <span onClick={onClose} style={{ cursor: 'pointer' }}>
                            <Close />
                        </span>
                    </div>
                </div>
            </DialogTitle>
            <DialogContent>
                <div className='row justify-content-start p-0' style={{ minHeight: 370 }}>
                    {!loading ? data?.relatedProperties && data?.relatedProperties?.length > 0 ? (
                        <>
                            <Grid container spacing={3}>
                                {data?.relatedProperties?.map((item, i) => (
                                    <Grid xs={12} md={6} lg={4} xl={3} item className="mb-3 position-relative" key={i}>
                                        <PropertyCardForAdmin
                                            item={item}
                                            hideActions
                                            residentialUnit
                                            setSelectedProperty={() => { }}
                                            disableLink
                                        />
                                        <IconButton
                                            style={{
                                                cursor: 'pointer'
                                            }}
                                            onClick={(e) => {
                                                openDeleteModal(t("ConfirmUnlink"),
                                                    t("AreYouSureYouWantToUnlinkThisPropertyFromProject"),
                                                    () => {
                                                        removePropertyFromProject({
                                                            "language": i18n.language === "ar" ? '0' : '1',
                                                            "userId": token,
                                                            "projectId": id,
                                                            "propertyId": item?.propertyId
                                                        }).then(res => {
                                                            if (res.resCode === 0) {
                                                                toast.success(t("DoneSuccessfully"));
                                                                callGetProjectDetails();
                                                            }
                                                        });
                                                    }, t("Confirm"));
                                            }}
                                            sx={{
                                                zIndex: 99,
                                                position: 'absolute',
                                                cursor: 'pointer',
                                                top: '35px',
                                                right: '15px',
                                                '&:hover,&:focus': {
                                                    color: '#fff',
                                                    background: '#d50000'
                                                },
                                                background: '#fff',
                                                color: 'var(--main-color-one)',
                                                boxShadow: 'rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px',
                                               
                                            }}>
                                            <Trash />
                                        </IconButton>
                    
                                    </Grid>
                                ))}
                            </Grid>
                        </>
                    ) : <NoData /> : <LoadingData />}
                </div>

                <ResidentialUnitsPopup
                    projectId={id}
                    open={relatedADsDialog}
                    setPopup={() => setRelatedADsDialog(false)}
                    onOK={() => {
                        onOK?.();
                        onClose?.();
                    }}
                    linkedOld={relatedProperties?.map(i => +i.propertyId)}
                />
            </DialogContent>
        </Dialog>
    );
}

export default RelatedADsModal;
