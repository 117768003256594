import { toast } from "react-toastify";
import React, { useEffect, useState } from "react";
import { Fab, FormControl, Grid, InputLabel, MenuItem, OutlinedInput, Select } from "@mui/material";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { openDeleteModal } from "../../../helpers";
import {getPropertyTypesForCategory, publishMyListing } from "../../../api/propertyApi";
import LoadingData from "../../../components/global-components/loading-data";
import PropertyCardForAdmin from "../../shared/properties/components/property-card-for-admin";
import ActionsPopper from "../../../components/global-components/actions-popper";
import NoData from "../../../components/global-components/no-data";
import { useAuth } from "../../../helpers/context";
import { getAdswithoutAdNumber, getCustomers, getUserProile } from "../../../api/userApi";
import PropertyInterestsModal from "../../shared/properties/components/interests-modal";
import SubHeader from "../../../components/global-components/sub-header";
import { Plus } from "../../../constants/icons";

const UnlicensedADs = () => {
  const [Properties, setProperties] = useState([]);
  const [SelectedProperties, setSelectedProperties] = useState([]);
  const { token, vendor, UserId, UserType } = useAuth();
  const [loadingData, setLoadingData] = useState(true);
  const { search } = useLocation();
  const { t, i18n } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();
  const [Settings, setSettings] = useState({
    userId: token,
    page: searchParams.get('page') || "1",
    pageSize: searchParams.get('pageSize') || "50",
    propertyType: searchParams.get('propertyType') || '-1',
  });
  
  const [TotalCount, setTotalCount] = useState(0);
  const [isUserVerified, setIsUserVerified] = useState(undefined);
  const [openInterestsModal, setOpenInterestsModal] = useState({ visible: false, data: [], property: '', propertyName: '', loading: false, totalCount: 0, intialized: false });
  const [interestsPaginationOptions, setInterestsPaginationOptions] = useState({ page: 1, pageSize: 10 });
  const [openPopper, setOpenPopper] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedProperty, setSelectedProperty] = useState();
  const [propertyTypes, setPropertyTypes] = useState([]);
  const history = useNavigate();

  function onChange() {
    setSettings({ ...Settings, pageSize: "100" });
    setSearchParams({
      page: Settings.page,
      pageSize: "100",
      propertyType: Settings.propertyType,
    },
      { replace: true });
  }

  function onSelect(id) {
    let item = SelectedProperties.filter((e) => e === id);
    if (item.length < 1) {
      setSelectedProperties([...SelectedProperties, id]);
    }
  }

  function onUnSelect(id) {
    let index = SelectedProperties.indexOf(id);
    if (index > -1) {
      setSelectedProperties(SelectedProperties.filter((item) => item !== id));
    }
  }

  function handlePublishUnPublishClick(Status) {
    let propertyIds = "";

    SelectedProperties.forEach((element) => {
      propertyIds = propertyIds + element + ",";
    });
    let isPublish = "4";
    if (Status === 1) isPublish = Settings.status === "1" ? "0" : "1";

    if (propertyIds.length > 1) propertyIds = propertyIds.slice(0, -1);
    if (propertyIds.length < 2) propertyIds = "-1";

    publishMyListing({
      propertyIds: propertyIds,
      userId: token,
      isPublish: isPublish,
      language: i18n.language === 'ar' ? '0' : '1'
    })
      .then((property) => {
        if (property.resCode === 0) toast.success(t("DoneSuccessfully"));
        else toast.error(property.resStr);
        onChange();
      })
      .catch((error) => {
        throw error;
      });
  }

  useEffect(() => {
    getPropertyTypesForCategory({
      category: '1,2',
      language: i18n.language === 'ar' ? '0' : '1',
    }).then((res) => setPropertyTypes(res.response));
  }, [i18n.language]);

  useEffect(() => {
    if (token)
      getUserProile({
        userId: token,
        language: i18n.language === 'ar' ? '0' : '1',
      }).then((_userInfo) => {
        if (_userInfo.resCode === 0) {
          setIsUserVerified(_userInfo.response.userProfile.isUserVerified);
        } else {
          toast.error(_userInfo.resStr);
        }
      });
  }, [token, i18n.language]);

  useEffect(() => {
    setLoadingData(true);
    getAdswithoutAdNumber({
      ...Settings,
      userId: token,
      language: i18n.language === 'ar' ? '0' : '1',
      propertyType: Settings.propertyType === "-1" ? "" : Settings.propertyType,
    })
      .then((property) => {
        if (property.resCode === 0) {
          setProperties(property.response.propertyArray);
          setTotalCount(property.response.totalCount);
          setLoadingData(false);
          setSelectedProperties([]);
          
        } else {
          toast.error(property.resStr);
          setLoadingData(false);
        }
      });
    
  
  },// eslint-disable-next-line
    [Settings, i18n.language]);

  const getPropertyCustomrs = (item, page, pageSize) => {
    setOpenInterestsModal({
      property: item,
      visible: true,
      data: [],
      loading: true,
      propertyName: `${item?.propertyTypeName} ${item?.listedFor === "1" ? t("ForRent") : t("ForSale")}`,
      intialized: false
    });
    getCustomers({
      propertyId: item.propertyId,
      page: page,
      pageSize: pageSize,
      userId: token
    }).then(res => {
      if (res.resCode === 0) {
        setOpenInterestsModal({
          visible: true,
          property: item,
          data: res.response.array,
          totalCount: res.response.totalCount,
          loading: false,
          propertyName: `${item?.propertyTypeName} ${item?.listedFor === "1" ? t("ForRent") : t("ForSale")}`,
          intialized: false
        });
      } else {
        toast.error(res.resStr);
      }
    }).catch(() => setOpenInterestsModal({ ...openInterestsModal, intialized: false, loading: false, visible: true, property: item }));
  }
  
  useEffect(() => {
    if (search.includes("propertyId=") && vendor !== 'amakkn' && openInterestsModal.data?.length === 0 && !openInterestsModal.visible && !openInterestsModal?.intialized) {
      let query = search?.split("&");
      let id = query?.[0]?.substring(12);
      getPropertyCustomrs({
        propertyId: id
      }, interestsPaginationOptions.page, interestsPaginationOptions.pageSize);
    
    }
    if (!search.includes("propertyId=") && vendor !== 'amakkn' && openInterestsModal.property && openInterestsModal.data?.length > 0) {
      getPropertyCustomrs(openInterestsModal.property, interestsPaginationOptions.page, interestsPaginationOptions.pageSize);
    }
  },
    // eslint-disable-next-line
    [vendor, interestsPaginationOptions, openInterestsModal.property, search, i18n.language]);


  
  function handleDelete() {
   
    openDeleteModal(t("ConfirmDeletion"), t("AreYouSureToDeleteThisAD"), () => callDeleteApi());
    
  }

  function callDeleteApi() {
    publishMyListing({
      propertyIds: selectedProperty.propertyId,
      userId: token,
      isPublish: "4",
      language: i18n.language === 'ar' ? '0' : '1'
    })
      .then((property) => {
        if (property.resCode === 0) {
          toast.success(t("TheADHasBeenSuccessfullyDeleted"));
          onChange();
        } else {
          toast.error(property.resStr);
        }
      });
  }


  const renderPropertiesList = () => {
    return (
      <div className="properties-list col-12 px-0 pt-0 pb-5">
        <div className='row mx-0'>
          {loadingData ? (
            <LoadingData />
          ) : Properties && Properties.length > 0 ? (
            <Grid container spacing={2}>
              {Properties.map((item, i) => (
                <Grid item xs={12} md={6} lg={4} xl={3} key={i} className="custom-grid-item">
                  <PropertyCardForAdmin
                    item={item}
                    setOpenPopper={setOpenPopper}
                    handlePublishAndUnPublish={() => { }}
                    setAnchorEl={setAnchorEl}
                    isUserVerified={isUserVerified}
                    openPopper={openPopper}
                    loadingPublishButton={false}
                    anchorEl={anchorEl}
                    unlicensed
                    setSelectedProperty={setSelectedProperty}
                    onChange={onChange}
                    onSelect={onSelect}
                    currentPlan={undefined}
                    onUnSelect={onUnSelect}
                    userId={token}
                  />
                </Grid>
              ))}
              <ActionsPopper
                openPopper={openPopper}
                anchorEl={anchorEl}
                setAnchorEl={setAnchorEl}
                setOpenPopper={setOpenPopper}
                items={
                  [
                    {
                      title: t("ViewDetails"),
                      action: () => history(`/admin/unlicensed-ads/details/${selectedProperty.idToShare}`)
                    },
                    (UserType !== "5" || (UserType === "5" && selectedProperty?.userId === UserId)) && {
                      title: t("Delete"),
                      action: () => handleDelete()
                    },
                    vendor !== 'amakkn' && {
                      title: t("ShowInterestedCustomers"),
                      action: () => getPropertyCustomrs(selectedProperty, 1, 10)
                    }
                  ]
                }
              />
            </Grid>) : (
            <NoData msg={t("ThereAreNoADs")} />
          )}
        </div>
      </div>
    );
  }

  const renderSelectionList = () => {
    return SelectedProperties.length > 0 ? (
      <div className='top-btns justify-content-center justify-content-lg-end flex-wrap mt-3 mt-lg-0'>
        <span className="col-12 text-center col-sm-auto">{t("SelectedADs")}: {SelectedProperties.length}</span>
       
        <button
          id='delete-btn'
          className='btn btn-danger'
          onClick={(e) => {
            openDeleteModal(t("ConfirmDeletion"), t("AreYouSureYouWantToDeleteAllTheSelectedRealEstateADs"), () => handlePublishUnPublishClick(0));
          }}>
          {t("Delete")}
        </button>
       
      </div>
    ) : (
      <></>
    );
  }
  
  return (
    <div className="px-1">
      <SubHeader
         
        OKElement={
          <Fab
            size="large"
            color="primary"
            className="ok-fab"
            onClick={() => history('/admin/unlicensed-ads/add')}
          >
            <Plus sx={{ fontSize: 30 }} />
            <span>{t("NewAD")}</span>
          </Fab>
        }
      />
      <div className='row properties-list-wrapper m-0 radius-16'>
        <div className='col-12 p-0 heading'>
          <div className='row '>
            <div className='col-12 col-lg-6 text-center text-lg-start mb-0 d-flex flex-wrap align-items-center' style={{ gap: '15px' }}>
              <h4 className="mb-0" style={{ direction: 'rtl !important' }}>
                {t("ListOfRealEstateADs1")} ({!loadingData ? TotalCount : <>&nbsp;&nbsp;&nbsp;</>})
              </h4>
              <div className="d-flex" style={{ gap: '8px' }}>
                <FormControl variant="outlined">
                  <InputLabel shrink htmlFor='unitid1'>{t("PropertyType")}</InputLabel>
                  <Select
                    sx={{ width: "120px" }}
                    size="small"
                    onChange={(event) => {
                      setSettings({
                        ...Settings,
                        propertyType: event.target.value,
                      });
                      setSearchParams({
                        page: Settings.page,
                        pageSize: Settings.pageSize,
                        propertyType: event.target.value,
                      },
                        { replace: true });
                    }}
                    label={t("PropertyType")}
                    inputProps={{
                      name: "unitid1",
                      id: "unitid1",
                    }}
                    value={Settings.propertyType}
                               
                    input={<OutlinedInput
                      notched
                      value={Settings.propertyType}
                      label={t("PropertyType")} />}
                    labelId="unitid1"
                  >
                    <MenuItem value={'-1'} key={'-1'}>{t("All")}</MenuItem>
                    {propertyTypes.map((item) => <MenuItem value={item.key} key={item.key}>{item.name}</MenuItem>)}
                  </Select>
                </FormControl>
                 
              </div>
             
             
            </div>
            
            <div className='col-12 col-lg-6 '>
              {renderSelectionList()}
            </div>
          </div>
        </div>
        {renderPropertiesList()}
      </div>
     
      
      <PropertyInterestsModal
        open={openInterestsModal.visible}
        unlicensedADs
        data={openInterestsModal.data || []}
        property={openInterestsModal.property}
        totalCount={openInterestsModal.totalCount || 0}
        paginationOptions={interestsPaginationOptions}
        setPaginationOptions={setInterestsPaginationOptions}
        onClose={() => setOpenInterestsModal({ visible: false, data: [], property: '', propertyName: '', loading: false, totalCount: 0, intialized: true })}
        loadingData={openInterestsModal.loading}
        propertyName={search?.includes("propertyId=") ? '' : openInterestsModal.propertyName}
      />
  
    </div>
  );
}

export default UnlicensedADs;
