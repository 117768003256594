import React from "react";
import { useTranslation } from "react-i18next";
import { getLocalizedText } from "../../../helpers";

const T06WhyUS = ({ data }) => {
    const { t, i18n } = useTranslation();

    return (
        <>
            <div className="col-12 justify-content-center align-items-center flex-column d-flex" >
                <div className="row mx-0">
                    <div className="col-12  d-flex flex-column justify-content-center align-items-center">
                        <span className="section-prefix tag" data-aos="fade-up" data-aos-duration="1500">{t("OurAdvantages")}</span>
                        <h2 className='sec-title text-center border-lg-start' data-aos="fade-up" data-aos-duration="1500">{t("WhatMakesUsDifferentFromOthers")}</h2>
                    </div>
                    <div className={`col-12`} data-aos="fade-up" data-aos-duration="1500">
                        <p className="sec-desc text-center">{getLocalizedText(data.desc, i18n.language,false)}</p>
                    </div>
                </div>
            </div>
            <div className="row features-row mx-0 mx-lg-auto mt-5" >
                {(getLocalizedText(data.title1, i18n.language,false)?.trim() !== "" || getLocalizedText(data.content1, i18n.language,false)?.trim() !== "") && (
                    <div className="feature col-11 col-lg-4 col-xl-3 shine-animate-item" data-aos="fade-up" data-aos-duration="1500" data-aos-delay="300">
                        <div className="feature-icon shine-animate">01</div>
                        <h5 className="feature-title">{getLocalizedText(data.title1, i18n.language,false)}</h5>
                        <p className="feature-desc">{getLocalizedText(data.content1, i18n.language,false)}</p>
                    </div>
                )}
                {(getLocalizedText(data.title2, i18n.language,false)?.trim() !== "" || getLocalizedText(data.content2, i18n.language,false)?.trim() !== "") && (
                    <div className="feature col-11 col-lg-4 col-xl-3 shine-animate-item" data-aos="fade-up" data-aos-duration="1500" data-aos-delay="500">
                        <div className="feature-icon shine-animate">02</div>
                        <h5 className="feature-title">{getLocalizedText(data.title2, i18n.language,false)}</h5>
                        <p className="feature-desc">{getLocalizedText(data.content2, i18n.language,false)}</p>
                    </div>
                )}
                {(getLocalizedText(data.title3, i18n.language,false)?.trim() !== "" || getLocalizedText(data.content3, i18n.language,false)?.trim() !== "") && (
                    <div className="feature col-11 col-lg-4 col-xl-3 shine-animate-item" data-aos="fade-up" data-aos-duration="1500" data-aos-delay="700">
                        <div className="feature-icon shine-animate">03</div>
                        <h5 className="feature-title">{getLocalizedText(data.title3, i18n.language,false)}</h5>
                        <p className="feature-desc">{getLocalizedText(data.content3, i18n.language,false)}</p>
                    </div>
                )}
            </div>
        </>
    );
}

export default T06WhyUS;
