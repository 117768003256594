import { Avatar, Chip, CircularProgress, Divider, List, ListItem, ListItemAvatar, ListItemText, Stack } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import Loadingdata from '../../../components/global-components/loading-data';
import NoData from '../../../components/global-components/no-data';
import SubHeader from "../../../components/global-components/sub-header";
import { useAuth } from "../../../helpers/context";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { getMyNotifications, markAsRead } from "../../../api/notifications-api";
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import { useNavigate } from "react-router-dom";

const pageSize = 8;

const NotificationsPage = () => {
  const [data, setData] = useState([]);
  const [loadingData, setLoadingData] = useState(true);
  const { token, setUnreadNotificationsCount, unreadNotificationsCount } = useAuth();
  const { t, i18n } = useTranslation();
  const [page, setPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const [notificationsShowed, setNotificationsShowed] = useState('all');
  const [hasMore, setHasMore] = useState(true);
  const loaderRef = useRef(null);
  const [loadingMoreData, setLoadingMoreData] = useState(false);

  const history = useNavigate();

  const getData = () => {
    setLoadingData(true);
    getMyNotifications({
      "userId": token,
      "page": page,
      "pageSize": pageSize,
      "language": i18n.language === "ar" ? "0" : "1",
      "isRead": notificationsShowed === "all" ? undefined : '0',
      // "searchString": ""
    }).then(res => {
      if (res.resCode === 0) {
        setData(res.response.array);
        setTotalCount(res.response.totalCount);
        setUnreadNotificationsCount(res.response.unreadCount);
      }
    }).finally(() => {
      setLoadingData(false);
    });
  };

  const loadMoreItems = () => {
    if (data?.length >= totalCount) {
      setHasMore(false);
      return;
    }

      setLoadingMoreData(true);
      getMyNotifications({
        "userId": token,
        "page": page + 1,
        "pageSize": pageSize,
        "language": i18n.language === "ar" ? "0" : "1",
        "isRead": notificationsShowed === "all" ? undefined : '0'
      }).then(res => {
        if (res.resCode === 0) {
          setData(old => [...old, ...res.response.array]);
          setTotalCount(res.response.totalCount);
          setUnreadNotificationsCount(res.response.unreadCount);
        }
      }).finally(() => {
        setPage((old) => old + 1);
        setLoadingMoreData(false);
      });
  };

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        const target = entries[0];
        if (target.isIntersecting && hasMore) {
          loadMoreItems();
        }
      },
      { root: null, rootMargin: '20px', threshold: 1.0 }
    );

    const currentLoader = loaderRef.current;
    if (currentLoader) observer.observe(currentLoader);

    return () => {
      if (currentLoader) observer.unobserve(currentLoader);
    };
  },// eslint-disable-next-line
    [hasMore, data]);

  useEffect(() => {
    getData();
  },// eslint-disable-next-line
    [token, i18n.language, notificationsShowed]);
  
  return (
    <>
      <SubHeader OKElement={<></>} />
      <div className="row mx-0">
        <div className='custom-card col-12 col-md-10 col-lg-6 mx-auto'>
          <div className='card-header'>
            <h4>{t("Notifications")}</h4>
            <Stack direction="row" gap={1}>
              <Chip label={t("All")} variant={notificationsShowed === "all" ? 'filled' : 'outlined'} onClick={() => {
                setNotificationsShowed('all');
                setPage(1);
              }} />
              <Chip label={t("Unread")} variant={notificationsShowed !== "all" ? 'filled' : 'outlined'} onClick={() => {
                setNotificationsShowed('unread');
                setPage(1);
              }} />
            </Stack>
          </div>
          <div className='card-body' style={{ height: '70vh', overflow: 'auto' }}>
            <div>
              {loadingData ? <Loadingdata /> : data?.length > 0 ? <>
                <List sx={{ pb: 0, width: '100%', height: '90%', overflow: 'auto', bgcolor: 'background.paper' }}>
                  {data?.map((item, index) => (
                    <React.Fragment key={index}>
                      <ListItem
                        key={index}
                        alignItems="center"
                        sx={{ borderRadius: '8px', cursor: 'pointer' }}
                        className={`py-1 ${item.isRead ? '' : 'new'} notification`}
                        onClick={() => {

                          if (!item.isRead) {
                            markAsRead({
                              "userId": token,
                              "id": item.id,
                              "language": i18n.language === 'ar' ? "0" : '1'
                            });
                            setUnreadNotificationsCount(unreadNotificationsCount - 1);
                          }
                          if (item.modelType === "Task" && !item.body.includes("delete"))
                            history(`/admin/tasks?task=${item.modelId}`);
                          else if (item.modelType === "Bargain" && !item.body.includes("delete")) {
                            history(`/admin/deals?deal=${item.modelId}`);
                          }
                        }}
                      >
                        <ListItemAvatar>
                          <Avatar style={{ width: '50px', height: '50px', boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px", border: '1px solid #ddd' }} alt={item.body} src={item?.extra?.senderAvatar}>
                            <PersonOutlineIcon />
                          </Avatar>
                        </ListItemAvatar>
                        <ListItemText
                          primary={item.body}
                          secondary={moment(item.createdAt).add(3, 'hours').fromNow()}
                          sx={{
                            "& .MuiTypography-body1": { fontSize: '12px' },
                            "& .MuiTypography-body2": { fontSize: '10px' },
                          }}
                        />
                        <div>
                          {item.isRead ? <>&nbsp;</> : <span
                            style={{
                              width: '9px',
                              height: '9px',
                              borderRadius: '100%',
                              display: 'block',
                              background: 'var(--main-color-one)'
                            }}></span>}
                        </div>
                                                   
                      </ListItem>
                      {index >= data?.length - 1 ?
                        <></> :
                        <Divider variant="fullWidth" sx={{ maxWidth: 'calc(100% - 10px)', marginInline: 'auto' }} component="li" />
                      }
    
                    </React.Fragment>
                  ))}
                </List>
                {hasMore && (
                  <div ref={loaderRef} style={{ height: '50px', textAlign: 'center' }}>
                    {loadingMoreData ? <CircularProgress size={24} sx={{color:'var(--main-color-one)'}} /> : <></>}
                  </div>
                )}
                   
              
              </> : (
                <NoData msg={t("NoNotificationsFound")} />
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default NotificationsPage;
