import { Dialog, DialogContent, DialogTitle, TextField } from "@mui/material";
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { getProjects, translateText } from "../../api/userApi";
import { getLocalizedText, resolvePersianAndArabicNumbers } from "../../helpers";
import LoadingButton from "../global-components/loading-btn";
import NoData from "../global-components/no-data";
import ProjectBox from "./project-box";
import { useAuth } from "../../helpers/context";
import { Marker } from "../../constants/icons";
import HolidayVillageIcon from '@mui/icons-material/HolidayVillage';
import { InfoOutlined } from "@mui/icons-material";
import { CLASSIC2_TEMPLATES, CLASSIC_TEMPLATES, DARK_TEMPLATES, MODERN2_TEMPLATES, MODERN_TEMPLATES } from "../../constants";
import { Swiper, SwiperSlide } from "swiper/react";
import { Keyboard, Pagination } from "swiper";
import { useTranslation } from "react-i18next";
import T04FeaturedProjects from "../pro/template-04/featured-projects";
import T05FeaturedProjects from "../pro/template-05/featured-projects";
import T06FeaturedProjects from "../pro/template-06/featured-projects";

const publicUrl = process.env.REACT_APP_URL + "/";

function FeaturedProjects(props) {
  // const [FeaturedProperty, setFeaturedProperty] = useState([]);
  const [allProjects, setAllProjects] = useState([]);
  const [Data, setData] = useState({
    ...props.data,
    oldTitle: props.data.title
  });
  const [selectedProjects, setSelectedProjects] = useState([]);
  const [selectedProjectsIDs, setSelectedProjectsIDs] = useState([]);
  const [timeStamp, setTimeStamp] = useState(new Date());
  const { vendor, templateId, autoTranslate } = useAuth();
  const [openModal, setOpenModal] = useState(false);
  const { t, i18n } = useTranslation();
  const [isSubmittingData, setIsSubmittingData] = useState(false);

  useEffect(() => {
    let selected = [], selectedIDs = [];
    if (vendor) {
      getProjects({
        userName: vendor,
        language: i18n.language === 'ar' ? '0' : '1',
      }).then((_response) => {
        if (_response.resCode === 0) {
          setAllProjects(_response.response);
          for (let item of _response.response) {
            if (Data.projectsId.split(',').length > 0 && Data.projectsId.split(',').includes(item.id)) {
              selected.push(item);
              selectedIDs.push(item.id);
            }
          }
          setSelectedProjects(selected);
          setSelectedProjectsIDs(selectedIDs);
        }
      });
    }
    
  }, [Data.projectsId, vendor, i18n]);
  
  const onItemSelected = (project) => {
    let temp = selectedProjects;
    let tempIDs = selectedProjectsIDs;

    let selectedBefore = temp.filter(item => item.id === project.id).length > 0;
    if (selectedBefore) {
      temp = temp.filter(item => item.id !== project.id);
      tempIDs = tempIDs.filter(item => item !== project.id);
    } else {
      if (temp.length < 3) {
        temp.push(project);
        tempIDs.push(project.id);
      }
    }
    setSelectedProjects(temp);
    setSelectedProjectsIDs(tempIDs);
    setData({ ...Data, projectsId: tempIDs.toString() });
    setTimeStamp(new Date() + timeStamp);
  };

  return (
    <div className='featured-area'>
      {props.isEdit ? <>
        <div className="row px-3">
          <div className="col-12">
            <TextField
              label={t("Tagline")}
              sx={{ width: "100%" }}
              InputLabelProps={{
                shrink: true,
              }}
              inputProps={{ maxLength: 500 }}
              value={getLocalizedText(Data.title, i18n.language)}
              size='small'
              multiline
              rows={3}
              onChange={(v) => {
                let arabicPart = getLocalizedText(Data.title, "ar");
                let englishPart = getLocalizedText(Data.title, "en");
                if (i18n.language === "ar")
                  arabicPart = v.currentTarget.value?.length >= 500 ? v.currentTarget.value?.substring(0, 500) : v.currentTarget.value;
                else
                  englishPart = v.currentTarget.value?.length >= 500 ? v.currentTarget.value?.substring(0, 500) : v.currentTarget.value;
                let text = (arabicPart + (englishPart?.length > 0 ? ('~@~' + englishPart) : ""));
                setData({ ...Data, title: resolvePersianAndArabicNumbers(text) });
              }}
            />
            <h6 className='rightalign mb-3 mt-1'>
              {Data.title
                ? parseInt(getLocalizedText(Data.title, i18n.language)?.length) + "/500"
                : "0/500"}
            </h6>
          </div>
          <div className="col-12">
            <div className='custom-card with-switcher'>
              <div className="card-header">
                <div>
                  <h6>{t("SelectedProjects")}</h6>
                </div>
                <div>
                  <button className="outlined-btn px-3" onClick={() => setOpenModal(true)}>{t("AddProjects")}</button>
                </div>
              </div>
      
              <div className="card-body">
                
                <div className='row justify-content-start'>
                  {selectedProjects && selectedProjects.length > 0 ? selectedProjects.map((item, i) => (
                    <div
                      key={i}
                      className='col-xl-3 col-lg-4 col-md-6 col-sm-6'
                      style={{ cursor: "pointer" }}>
                      <ProjectBox disableLink project={item} key={i} />
                    </div>
                  )) :
                    <NoData />
                  }
                </div>
              </div>
            </div>
          </div>
          <div className='col-12'>
            <LoadingButton
              label={t("SaveChanges")}
              classes=" primary-btn m-auto"
              handleClick={async () => {
                setIsSubmittingData(true);
                let oldArabicTitle = getLocalizedText(Data.oldTitle, "ar"),
                  newArabicTitle = getLocalizedText(Data.title, "ar"),
                  newTitle = Data.title;
              
                if (i18n.language === "ar" && autoTranslate === "1") {
                  if (oldArabicTitle !== newArabicTitle && newArabicTitle?.trim() !== "") {
                    let newEnglishTitle = await translateText(newArabicTitle);
                    newEnglishTitle = newEnglishTitle?.length >= 500 ? newEnglishTitle?.substring(0, 500) : newEnglishTitle;
                    newTitle = newArabicTitle + '~@~' + newEnglishTitle;
                  }
                  setData({ ...Data, title: newTitle });
                  props.saveDetails({ data: { ...Data, title: newTitle } });
                  setIsSubmittingData(false);
                } else {
                  props.saveDetails({ data: Data })
                }
              }}
              loading={props.loading || isSubmittingData}
            />
          </div>
          <Dialog
            fullWidth={true}
            maxWidth={"xl"}
            open={openModal}
            className="custom-dialog model"
            onClose={() => setOpenModal(false)}>
            <DialogTitle>
              <div className="dialog-head">
                <div>
                  <h2>{allProjects.length > 0 ? t("EditSelectedProjects") : t("AddFeaturedProjects")}</h2>
                  <p>{t("YouCanSelectProjectsByClickingOnThem")}</p>
                </div>
                <div className="dialog-actions">
                  <button className="px-3 primary-btn" onClick={() => setOpenModal(false)}>
                    {t("Save")}
                  </button>
                  <button className="px-3 outlined-btn" onClick={() => setOpenModal(false)}>
                    {t("Cancel")}
                  </button>
                </div>
              </div>
            </DialogTitle>
            <DialogContent>
              <div className='row justify-content-start'>
                {allProjects && allProjects.length > 0 ? allProjects.map((item, i) => (
                  <div
                    key={i}
                    className='col-xl-3 col-lg-4 col-md-6 col-sm-6'
                    style={{ cursor: "pointer" }}
                    onClick={() => onItemSelected(item)}
                  >
                    <ProjectBox disableLink project={item} key={i}
                      selectedProjectsIDs={selectedProjectsIDs} />
                  </div>
                )) :
                  <NoData msg={t("ThereAreNoProjectsPublishedByYou")} />
                }
              </div>
            </DialogContent>
          </Dialog>
        </div>
      </>
        : CLASSIC_TEMPLATES.includes(+templateId) ? <T04FeaturedProjects data={Data} selectedProjects={selectedProjects} /> :
          CLASSIC2_TEMPLATES.includes(+templateId) ? <T05FeaturedProjects data={Data} selectedProjects={selectedProjects} /> :
            MODERN2_TEMPLATES.includes(+templateId) ? <T06FeaturedProjects data={Data} selectedProjects={selectedProjects} /> :
              <div className="container-v2">
                <div className='col-12 justify-content-center align-items-center flex-column d-flex' style={{ marginBottom: '20px', marginTop: '40px' }}>
                  <span className="section-prefix">{t("OurProjects")}</span>
                  <h2 className='sec-title'>{t("HereIsACollectionOfOurDistinctiveProjects")}</h2>
                  <p className="sec-desc sec-desc2">
                    {getLocalizedText(Data.title, i18n.language, false)}
                  </p>
                </div>
                <div className='col-12 news-details-wrap mt-4 row mx-0 p-0 projects-page justify-content-center'>
            
                  {selectedProjects.length > 0 &&
                    selectedProjects.map((item, i) => (
                      <div
                        key={item.id}
                        className='col-xl-3 col-lg-4 col-md-6 col-sm-6'
                        style={{ cursor: "pointer" }}>
                        <div className={`single-project single-leading-feature ${[...MODERN_TEMPLATES, ...DARK_TEMPLATES].includes(+templateId) ? 'single-project-v2' : ''}`}>
                          <Link to={"/project/" + item.id}>
                            <div className='thumb'>
                              {item.images?.length > 0 ?
                                <Swiper
                                  spaceBetween={0}
                                  slidesPerView={1}
                                  key={i18n.language + 'ii'}
                                  modules={[Keyboard, Pagination]}
                                  keyboard={{ enabled: true }}
                                  pagination={{
                                    clickable: true, el: `.swiper-pagination.pagination-${item.id}`,
                                    dynamicBullets: true,
                                    dynamicMainBullets: 4
                                  }}
                                  speed={2600}>
                                  {item.images?.map((image, index) => (
                                    <SwiperSlide key={index}>
                                      <img src={image} alt={`${getLocalizedText(item.name, i18n.language, false)} ${index}`} />
                                    </SwiperSlide>
                                  ))}
                                </Swiper>
                                :
                                <img
                                  src={item.images.length > 0 ? item.images[0] : publicUrl + "assets/img/defimgs/1.svg"}
                                  alt={getLocalizedText(item.name, i18n.language, false)}
                                  className={item.images.length > 0 ? "" : "contain-img"}
                                />}
                              {item.images?.length > 0 ? <div className={`swiper-pagination pagination-${item.id}`}></div> : null}
                              {[...MODERN_TEMPLATES, ...DARK_TEMPLATES].includes(+templateId) ? <div className='project-status'>
                                {t("ResidentialUnit", { count: item.totalUnits })} / {item.status ? getLocalizedText(item.status, i18n.language, false) : ''}
                              </div> : null}
                            </div>
                            <div className='details details2'>
     
                              <h4 className='title property-name'>
                                {getLocalizedText(item.name, i18n.language, false)}
                              </h4>
                              {[...MODERN_TEMPLATES, ...DARK_TEMPLATES].includes(+templateId) ? <>
                                <h5 className='project-address'>
                                  {item.address}
                                </h5>
                              </> :
                                <div style={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
                                  <span style={{ width: '15px' }}><Marker /></span>
                                  <h5 className='price '>
                                    {item.address}
                                  </h5>
                                </div>
                              }
                              {[...MODERN_TEMPLATES, ...DARK_TEMPLATES].includes(+templateId) ? null :
                                <div style={{ display: 'flex', gap: '10px', alignItems: 'center', marginTop: '4px' }}>
                                  <span style={{ width: '15px' }}><HolidayVillageIcon sx={{ width: '20px', color: 'var(--main-color-one)' }} /></span>
                                  <h5 className='price'>
                                    {t("ResidentialUnit", { count: item.totalUnits })}
                                  </h5>
                                </div>
                              }
                              {item.status && ![...MODERN_TEMPLATES, ...DARK_TEMPLATES].includes(+templateId) && (
                                <div style={{ display: 'flex', gap: '10px', alignItems: 'center', marginTop: '4px' }}>
                                  <span style={{ width: '15px' }}><InfoOutlined sx={{ width: '20px', color: 'var(--main-color-one)' }} /></span>
                                  <h5 className='price '>
                                    {getLocalizedText(item.status, i18n.language, false)}
                                  </h5>
                                </div>
                              )}
                            </div>
                          </Link>
                        </div>
                      </div>
                    ))}
                </div>
              </div>
      }
    </div>
  );
}

export default FeaturedProjects;
