import Close from '@mui/icons-material/Close';
import { IconButton } from '@mui/material';
import React from 'react'
import { Link } from 'react-router-dom';
import { getFontspace, renderFeatureIcon, renderFeatureIconCondition, renderRoomIcon, renderRoomIconCondition } from '../../../helpers';
import { useTranslation } from 'react-i18next';
import { LazyLoadImage } from 'react-lazy-load-image-component';

const publicUrl = process.env.REACT_APP_URL + "/";

const MobileInfoWindow = ({ showSearchBox, setMobileInfowindow, mobileInfowindow }) => {
    const { t } = useTranslation();
    return (
        <div
            className={`${!showSearchBox ? "full-screen" : ""} mobile-info-window`}>
            <IconButton
                sx={{
                    bgcolor: "#fff",
                    cursor: "pointer",
                    position: "absolute",
                    top: "6px",
                    left: "6px",
                    width: 23,
                    height: 23,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    zIndex: 22
                }}
                onClick={() => setMobileInfowindow({ activeMarker: null, showingInfoWindow: false, })}>
                <Close sx={{ color: "#828282", width: 16, height: 16 }} />
            </IconButton>
            {mobileInfowindow?.selectedPlace?.place?.count === 1 ? (mobileInfowindow.selectedPlace &&
                mobileInfowindow.selectedPlace.photos !== undefined ? (
                <Link
                    to={{ pathname: `/property-details/${mobileInfowindow?.selectedPlace?.idToShare}` }}
                    state={{
                        aboutProps: { propertyId: mobileInfowindow?.selectedPlace?.propertyId }
                    }}>
                    
                    <div>
                        {mobileInfowindow?.selectedPlace?.isFeatured === "1" && <div className="featured-ribbon pr-3"><span>{t("FeaturedAD")}</span></div>}
                        <div className={`pxp-marker-details-fig pxp-cover ${mobileInfowindow?.selectedPlace?.photos?.length > 0 ? "" : "default"}`}>
                            <LazyLoadImage
                                style={{ borderRduis: "" }}
                                src={
                                    mobileInfowindow?.selectedPlace?.photos?.length > 0
                                        ? mobileInfowindow?.selectedPlace?.photos?.split(",")[0]
                                        : publicUrl +
                                        "assets/img/defimgs/" +
                                        mobileInfowindow?.selectedPlace?.propertyType +
                                        ".svg"
                                }
                                alt='img' />
                        </div>
                        <div className='px-2'>
                            <h6 style={{ lineHeight: "33px", marginBottom: 0 }}>
                                {mobileInfowindow?.selectedPlace?.propertyTypeName}{" "}
                                {mobileInfowindow?.selectedPlace?.listedFor === "1" ? t("ForRent") : t("ForSale")}
                            </h6>
                        </div>
                        <div>
                            <ul className='info-list map px-2 '>
                                {mobileInfowindow?.selectedPlace?.features?.map(
                                    (feature, i) =>
                                        renderFeatureIconCondition(
                                            mobileInfowindow?.selectedPlace?.propertyType,
                                            feature.key
                                        ) && (
                                            <li key={"li" + i} title={feature.name}>
                                                <span className='feature-value'>
                                                    {feature.key !== '7'
                                                        ? Intl.NumberFormat("en").format(feature.value) + " " + feature.unit
                                                        : getFontspace(feature.value)}
                                                </span>
                                                {renderFeatureIcon(feature.key)}
                                            </li>
                                        )
                                )}

                                {mobileInfowindow?.selectedPlace?.rooms?.map(
                                    (room, i) =>
                                        renderRoomIconCondition(
                                            mobileInfowindow?.selectedPlace?.propertyType,
                                            room.key
                                        ) && (
                                            <li key={"li-" + i} title={room.name}>
                                                <span className='feature-value'>{room.value}</span>
                                                {renderRoomIcon(room.key)}
                                            </li>
                                        )
                                )}
                            </ul>
                        </div>
                        {mobileInfowindow?.selectedPlace?.additionalInfo?.filter(i => i.key === '10')?.[0]?.valueText &&
                            mobileInfowindow?.selectedPlace?.additionalInfo?.filter(i => i.key === '10')?.[0]?.valueText !== 'لا' && (
                                <div className='pxp-marker-details-info px-2'>
                                    <div className='pxp-marker-details-info-price'>
                                        <h5>
                                            {mobileInfowindow?.selectedPlace?.defaultPriceFomratted}{" "}
                                            {t("SAR")}
                                        </h5>
                                    </div>
                                </div>
                            )}

                    </div>
                    
                </Link>) : <></>) :
                <>
                    {/* More than one AD */}
                    <div className="slider-container">
                        <div className="slider">
                            <div className="slider-track" style={{ width: `${mobileInfowindow?.selectedPlace?.count * 100}%` }}>
                                {mobileInfowindow?.selectedPlace?.items?.map((item, i) => (
                                    <div className="slide">
                                        <Link
                                            to={{ pathname: `/property-details/${item?.idToShare}` }}
                                            className='w-100 h-100'
                                            state={{
                                                aboutProps: { propertyId: item?.propertyId }
                                            }}>
                                            <div className="w-100 h-100">
                                                {item.isFeatured === "1" && <div className="featured-ribbon pr-3"><span>{t("FeaturedAD")}</span></div>}
                                                <div className={`pxp-marker-details-fig pxp-cover ${item.photos.length > 0 ? "" : "default"}`}>
                                                    <LazyLoadImage
                                                        src={
                                                            item.photos.length > 0
                                                                ? item.photos.split(",")[0]
                                                                : publicUrl +
                                                                "assets/img/defimgs/" +
                                                                item.propertyType +
                                                                ".svg"
                                                        }
                                                        alt='img' />
                                                </div>
                                                <div className='px-2'>
                                                    <h6 style={{ lineHeight: "33px", marginBottom: 0 }}>
                                                        {item.propertyTypeName}{" "}
                                                        {item.listedFor === "1"
                                                            ? t("ForRent")
                                                            : t("ForSale")}
                                                    </h6>
                                                </div>
                                                <div>
                                                    <ul className='info-list map px-2 '>
                                                        {item.features.map(
                                                            (feature, i) =>
                                                                renderFeatureIconCondition(
                                                                    item.propertyType,
                                                                    feature.key
                                                                ) && (
                                                                    <li key={"li" + i} title={feature.name}>
                                                                        <span className='feature-value'>
                                                                            {feature.key !== '7'
                                                                                ? Intl.NumberFormat("en").format(feature.value) + " " + feature.unit
                                                                                : getFontspace(feature.value)}
                                                                        </span>
                                                                        {renderFeatureIcon(feature.key)}
                                                                    </li>
                                                                )
                                                        )}

                                                        {item.rooms.map(
                                                            (room, i) =>
                                                                renderRoomIconCondition(
                                                                    item.propertyType,
                                                                    room.key
                                                                ) && (
                                                                    <li key={"li-" + i} title={room.name}>
                                                                        <span className='feature-value'>
                                                                            {room.value}
                                                                        </span>
                                                                        {renderRoomIcon(room.key)}
                                                                    </li>
                                                                )
                                                        )}
                                                    </ul>
                                                </div>
                                                {item?.additionalInfo?.filter(i => i.key === '10')?.[0]?.valueText &&
                                                    item?.additionalInfo?.filter(i => i.key === '10')?.[0]?.valueText !== 'لا' && (
                                                        <div className='pxp-marker-details-info px-2'>
                                                            <div className='pxp-marker-details-info-price'>
                                                                <h5>
                                                                    {item.defaultPriceFomratted}{" "}
                                                                    {t("SAR")}
                                                                </h5>
                                                            </div>
                                                        </div>
                                                    )}
                      
                                            </div>
                                        </Link>
                                    </div>
                                ))}
                                
                            </div>
                        </div>
                    </div>
                </>}
        </div>
    );
}

export default MobileInfoWindow;
