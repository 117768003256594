import React, { useEffect, useMemo, useRef, useState } from "react";
import Alert from "@mui/material/Alert";
import SubHeader from "../../../../components/global-components/sub-header";
import {
  Autocomplete,
  Box,
  Divider,
  FormControl,
  FormHelperText,
  InputAdornment,
  MenuItem,
  Switch,
  Tab,
  Tabs,
  TextField
} from "@mui/material";
import { useAuth } from "../../../../helpers/context";
import { useNavigate } from "react-router-dom";
import LoadingButton from "../../../../components/global-components/loading-btn";
import { createAdwithoutAdNumber } from "../../../../api/userApi";
import GoogleMapsContainer from "../../../../components/section-components/registration/GoogleMapsContainer";
import {
  preventString,
  renderPropertyIcon2,
  resolvePersianAndArabicNumbers
} from "../../../../helpers";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { CITIES, DISTRICTS, REGIONS } from "../../../../constants";

const tabStyle = {
  fontSize: "16px",
  "&.Mui-selected": {
    color: "var(--main-color-one)",
  }
};

const publicUrl = process.env.REACT_APP_URL + "/";

const AD_PROPERTY_UTILITIES = [
  { id: "1", value: 'Electricity', label: 'كهرباء' },
  { id: "2", value: 'Waters', label: 'مياه' },
  { id: "3", value: 'Sanitation', label: 'صرف صحي' },
  { id: "5", value: 'FixedPhone', label: 'هاتف' },
  { id: "6", value: 'FibreOptics', label: 'الياف ضوئية' },
  { id: "7", value: 'FloodDrainage', label: 'تصريف الفيضانات' }
  // “code”: “NoServices”,“name”: “لايوجد خدمات”
];

const AD_PROPERTY_AGES = [
  { id: "1", value: 'New', label: 'جديد' },
  { id: "2", value: 'LessThanYear', label: 'اقل من سنة' },
  { id: "3", value: 'OneYear', label: 'سنة' },
  { id: "4", value: 'TwoYears', label: 'سنتين' },
  { id: "5", value: 'ThreeYears', label: 'ثلاث سنوات' },
  { id: "6", value: 'FourYears', label: 'اربع سنوات' },
  { id: "7", value: 'FiveYears', label: 'خمس سنوات' },
  { id: "8", value: 'SixYears', label: 'ست سنوات' },
  { id: "9", value: 'SevenYears', label: 'سبع سنوات' },
  { id: "10", value: 'EightYears', label: 'ثمان سنوات' },
  { id: "11", value: 'NineYears', label: 'تسع سنوات' },
  { id: "12", value: 'TenYears', label: 'عشر سنوات' },
  { id: "13", value: 'MoreThenTenYears', label: 'اكثر من عشر سنوات' },
];

const AD_CHANNELS = [
  { id:'1',value: 'LicensedPlatform', label: 'منصة مرخصة' },
  { id:'2',value: 'BulletinBoard', label: 'لوحة إعلانية' },
  { id:"3",value: 'SocialMediaPlatforms', label: 'منصات التواصل الإجتماعي' },
  { id:"4",value: 'Radio', label: 'الإذاعة' },
  { id:"5",value: 'Other', label: 'أخرى' },
];

const AD_CONSTRAINTS = [
  { key:'isConstrained',value: '0', label: "IsConstrained" },
  { key:'isPawned',value: '0', label: "IsPawned" },
  { key:'isHalted',value: '0', label: 'IsHalted'},
  { key:'isTestment',value: '0', label: "IsTestment" },
  { key:'complianceWithTheSaudiBuildingCode',value: '0', label: 'ComplianceWithTheSaudiBuildingCode' },
];

const AD_PROPERTY_FACES = [
  { id: "1", value: 'Eastern', label: 'شرقية' },
  { id: "2", value: 'Western', label: 'غربية' },
  { id: "3", value: 'Northern', label: 'شمالية' },
  { id: "4", value: 'Southern', label: 'جنوبية' },
  { id: "5", value: 'Northeast', label: 'شمالية شرقية' },
  { id: "6", value: 'Southeast', label: 'جنوبية شرقية' },
  { id: "7", value: 'Southwest', label: 'جنوبية غربية' },
  { id: "8", value: 'NorthWest', label: 'شمالية غربية' },
  { id: "9", value: 'ThreeStreets', label: 'ثلاثة شوارع' },
  { id: "10", value: 'FourStreets', label: 'اربعة شوارع' }
];

const AD_PROPERTY_TYPES = [
  { id: "1", value: 'Land', label: 'أرض' },
  { id: "2", value: 'Floor', label: 'دور' },
  { id: "3", value: 'Apartment', label: 'شقة' },
  { id: "4", value: 'Villa', label: 'فيلا' },
  { id: "5", value: 'Studio', label: 'استوديو' },
  { id: "6", value: 'Room', label: 'غرفة' },
  { id: "7", value: 'RestHouse', label: 'استراحة' },
  { id: "10", value: 'Exhibition', label: 'معرض' },
  { id: "11", value: 'Office', label: 'مكتب' },
  { id: "12", value: 'Warehouses', label: 'مستودع' },
  { id: "25", value: 'Farm', label: 'مزرعة' },
  { id: "26", value: 'Building', label: 'عمارة' }
];

const AD_PROPERTY_USAGE = [
  { id: "1", value: 'Agricultural', label: 'زراعي', visibleFor:['أرض','استراحة','مزرعة'] },
  { id: "2", value: 'Residential', label: 'سكني' , visibleFor:['أرض','دور','شقة','فيلا','استوديو','غرفة','استراحة','عمارة'] },
  { id: "3", value: 'Commercial', label: 'تجاري', visibleFor:['أرض','استراحة','معرض','مكتب','مستودع','عمارة'] },
  { id: "4", value: 'Industrial', label: 'صناعي' , visibleFor:['أرض','مستودع']},
  { id: "5", value: 'Healthy', label: 'صحي' , visibleFor:['أرض'] },
  { id: "6", value: 'Educational', label: 'تعليمي', visibleFor:['أرض'] }
];

function getFormatedPrice(price) {
  let value = price.replace(/[,]+/g, "");
  return value.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,");
}

const AddNew = (props) => {
  const [currentStep, setCurrentStep] = useState(0);
  const [completedSteps, setCompletedSteps] = useState([]);
  const [errors, setErrors] = useState({});
  const { token } = useAuth();
  const history = useNavigate();
  const [loadingLocationButton, setLoadingLocationButton] = useState(false);
  const { user } = props;
  const [ADdata, setADdata] = useState({
    userId: token,
    advertiserName: user?.companyName || "",
    source: 3,
    advertiserId: user?.commercialRecordNumber || "",
    phoneNumber: user?.phone || "",
    brokerageAndMarketingLicenseNumber: user?.licenseNumber,
    adLicenseURL: "",
    titleDeedTypeName: "صك السجل العقاري",
    notes: "",
    locationDescriptionOnMOJDeed: null,
    propertyType: "",
    advertisementType: "بيع",
    propertyUtilities: [],
    channels: [],
    deedNumber: '',
    propertyPrice: "",
    streetWidth: "",
    propertyArea: "",
    numberOfRooms: undefined,
    propertyAge: "",
    propertyFace: "",
    propertyUsages: [],
    obligationsOnTheProperty: "",
    guaranteesAndTheirDuration: "",
    planNumber: "",
    landNumber: "",
    isConstrained: "0",
    isPawned: "0",
    isHalted: "0",
    isTestment: "0",
    complianceWithTheSaudiBuildingCode: "0",
    constraints: [],
    location: {
      region: "",
      regionCode: "",
      city: "",
      cityCode: "",
      district: "",
      districtCode: "",
      street: "",
      postalCode: "",
      buildingNumber: "",
      additionalNumber: "",
      longitude: "46.619290983645406",
      latitude: "24.74772941029266",
    },
    adLicenseNumber: "",
    adSource: "",
    rerConstraints: [],
    rerBorders: [],
    borders: {
      northLimitName: "",
      northLimitDescription: "",
      northLimitLengthChar: "",
      eastLimitName: "",
      eastLimitDescription: "",
      eastLimitLengthChar: "",
      westLimitName: "",
      westLimitDescription: "",
      westLimitLengthChar: "",
      southLimitName: "",
      southLimitDescription: "",
      southLimitLengthChar: "",
    },
    creationDate: moment().locale("en").format("DD/MM/YYYY"),
    endDate: moment().add(10, 'years').locale("en").format("DD/MM/YYYY"), // after 10 years
    landTotalPrice: "",
    landTotalAnnualRent: "",
  });

  console.log(ADdata)
  const { t, i18n } = useTranslation();
  const [subStep, setSubStep] = useState(1);
 
  const channelsRef = useRef();
  const propertyInfoRef = useRef();
  const propertyUsagesRef = useRef();
  const additionalInfoRef = useRef();
  
  const regionsOptions = useMemo(
    () => {
      return REGIONS.map((region) => ({
        id: +region.REGION_ID,
        label: i18n.language === "ar" ? region.REGIONNAME_AR : region.REGIONNAME_EN,
      }));
    }, // eslint-disable-next-line
    [i18n.language]);
  
  const citiesOptions = useMemo(
    () => {
      return CITIES.map((city) => ({
        id: +city.CITY_ID,
        label: i18n.language === "ar" ? city.CITYNAME_AR : city.CITYNAME_EN,
        regionId: +city.REGION_ID,
      }))
    },// eslint-disable-next-line
    [i18n.language]);

  const districtsOptions = useMemo(
    () => {
      return DISTRICTS.map((district) => ({
        id: +district.DISTRICT_ID,
        label: i18n.language === "ar" ? district.DISTRICTNAME_AR : district.DISTRICTNAME_EN,
        regionId: +district.REGION_ID,
        cityId: +district.CITY_ID,
      }));
    }, // eslint-disable-next-line
    [i18n.language]);
  
  useEffect(() => {
    window.scroll({ top: 0, left: 0, behavior: "smooth" });
    document
      .querySelector(".admin-container")
      ?.scrollIntoView({ behavior: "smooth" });
  }, [currentStep, subStep]);

  function handleChange(NewLocation) {
    setADdata({ ...ADdata, location: { ...ADdata.location, address: NewLocation.address, latitude: NewLocation.latitude, longitude: NewLocation.longitude } });
  }

  function handleChangeAddress() { }

  // function Step1SubStep1IsValid() {
  //   const _errors = {};
  //   if (!ADdata.advertiserId)
  //     _errors.advertiserId = t("ThisFieldIsMandatory");
  //     if (!ADdata.advertiserName)
  //     _errors.advertiserName = t("ThisFieldIsMandatory");
  //     if (!ADdata.phoneNumber)
  //     _errors.phoneNumber = t("ThisFieldIsMandatory");
      
  //   setErrors(_errors);
  //   return Object.keys(_errors).length === 0;
  // }

  function Step1SubStep1IsValid() {
    const _errors = {};
    if (!ADdata.propertyType) _errors.propertyType = t("PleaseSelectPropertyType");
    if (!ADdata.advertisementType) _errors.advertisementType = t("ThisFieldIsMandatory");

    setErrors(_errors);
    return Object.keys(_errors).length === 0;
  }

  function Step1SubStep2IsValid() {
    const _errors = {};
    if (ADdata.channels?.length === 0) _errors.channels = t("AtLeastOneAdvertisingChannelMustBeSelected");
    if (!ADdata.propertyPrice) _errors.propertyPrice = t("ThisFieldIsMandatory");
    if (!ADdata.propertyArea) _errors.propertyArea = t("ThisFieldIsMandatory");
    if (ADdata.propertyUsages?.length === 0)
      _errors.propertyUsages = t("TheMethodOfUsingThePropertyMustBeSelected");

    if (_errors.channels) {
      channelsRef.current?.scrollIntoView({ behavior: "smooth" });
    } else if (_errors.propertyPrice || _errors.propertyArea) {
      propertyInfoRef.current?.scrollIntoView({ behavior: "smooth" });
    }

    setErrors(_errors);
    return Object.keys(_errors).length === 0;
  }

  function Step2IsValid() {
    const _errors = {};
    if (!ADdata.location.additionalNumber) _errors.additionalNumber = t("ThisFieldIsMandatory");
    if (!ADdata.location.regionCode) _errors.regionCode = t("ThisFieldIsMandatory");
    if (!ADdata.location.cityCode) _errors.cityCode = t("ThisFieldIsMandatory");
    if (!ADdata.location.districtCode) _errors.districtCode = t("ThisFieldIsMandatory");
    if (!ADdata.location.postalCode) _errors.postalCode = t("ThisFieldIsMandatory");
    if (!ADdata.location.buildingNumber) _errors.buildingNumber = t("ThisFieldIsMandatory");

    setErrors(_errors);
    return Object.keys(_errors).length === 0;
  }

  return (
    <>
      <SubHeader
        withSteps
        steps={
          [
            t("BasicInformation"),
            t("LocateTheProperty"),
            t("AdditionalData")
          ]
        }
        pageTitle={ADdata?.propertyType ? `${t("Add")} ${ADdata?.propertyType} ${ADdata?.advertisementType}` : t("AddRealEstateAD")}
        OKElement={<></>}
        completedSteps={completedSteps}
        currentStep={currentStep}
      />

      <div className='add-new-property-area mg-bottom-100 mt-4 text-center'>
        {/* {currentStep === 0 && (
          <ADConstraints onOK={() => handlecontinueClick(1, [0])} />
        )} */}
        {currentStep === 0 && (
          <div
            className='custom-card ad-constraints'
            style={{ minHeight: "auto", height: "auto" }}>
            <div className='card-header'>
              <h4>{t("BasicInformation")}</h4>
              <h6>
                قمّ بادخال المعلومات التاليّة بدقّة
              </h6>
            </div>
            <div className='card-body justify-content-center  align-items-center'>
              {
                //   subStep === 1 ? (
                //   <>
                //     <div className="row mx-0">
                //       <Divider
                //     textAlign='left'
                //     className='col-12 my-4 custom-divider'
                //     style={{ color: "var(--main-color-one)" }}>
                //     بيانات المعلن
                //   </Divider>
                //       <div className='col-12 col-md-6 col-lg-4 mb-5'>
                //         <FormControl error={Boolean(errors.advertiserName)} className='col-12'>
                //           <TextField
                //             label="اسم المعلن"
                //             InputLabelProps={{ shrink: true }}
                //             required
                //             sx={{ width: "100%" }}
                //             value={ADdata.advertiserName}
                //             size='small'
                //             onChange={(v) => setADdata({ ...ADdata, advertiserName: v.currentTarget.value })}
                //             error={Boolean(errors.advertiserName)}
                //           />
                //           {errors.advertiserName && (
                //             <FormHelperText component={Box}>
                //               <Alert severity='error' className='custom-alert'>
                //                 {errors.advertiserName}
                //               </Alert>
                //             </FormHelperText>
                //           )}
                //         </FormControl>
                //       </div>
                      
                //       <div className='col-12 col-md-6 col-lg-4 mb-5'>
                //         <FormControl error={Boolean(errors.advertiserId)} className='col-12'>
                //           <TextField
                //             label={'رقم السجل التجاري'}
                //             InputLabelProps={{ shrink: true }}
                //             required
                //             sx={{ width: "100%" }}
                //             value={ADdata.advertiserId}
                //             size='small'
                //             onChange={(v) => setADdata({ ...ADdata, advertiserId: preventString(v.currentTarget.value) })}
                //             error={Boolean(errors.advertiserId)}
                //           />
                //           {errors.advertiserId && (
                //             <FormHelperText component={Box}>
                //               <Alert severity='error' className='custom-alert'>
                //                 {errors.advertiserId}
                //               </Alert>
                //             </FormHelperText>
                //           )}
                //         </FormControl>
                //       </div>
                      
                //       <div className='col-12 col-md-6 col-lg-4 mb-5'>
                //         <FormControl error={Boolean(errors.phoneNumber)} className='col-12'>
                //           <TextField
                //             label={'رقم جوال المعلن'}
                //             InputLabelProps={{ shrink: true }}
                //             required
                //             sx={{ width: "100%" }}
                //             value={ADdata.phoneNumber}
                //             size='small'
                //             onChange={(v) => setADdata({ ...ADdata, phoneNumber: preventString(v.currentTarget.value) })}
                //             error={Boolean(errors.phoneNumber)}
                //           />
                //           {errors.phoneNumber && (
                //             <FormHelperText component={Box}>
                //               <Alert severity='error' className='custom-alert'>
                //                 {errors.phoneNumber}
                //               </Alert>
                //             </FormHelperText>
                //           )}
                //         </FormControl>
                //       </div>
                
                //     </div>
                //   </>
                // ) :
                subStep === 1 ? (
                  <>
                    <div
                      className='dialog-head justify-content-center'
                      style={{ borderBottom: "1px solid rgba(0, 0, 0, 0.12)" }}>
                      <Tabs
                        onChange={(event, newValue) => { setADdata({ ...ADdata, advertisementType: newValue }) }}
                        centered
                        value={ADdata.advertisementType}
                        sx={{ width: "auto" }}
                        TabIndicatorProps={{ style: { background: "var(--main-color-one)" } }}>
                        <Tab value={"بيع"} label={t("ForSale")} sx={tabStyle} />
                        <Tab value={"إيجار"} label={t("ForRent")} sx={tabStyle} />
                      </Tabs>
                    </div>
                    <div className='property-types-boxes'>
                      {AD_PROPERTY_TYPES.map((_PropertyType, index) => (
                        <span
                          onClick={() => setADdata({ ...ADdata, propertyType: _PropertyType.label })}
                          className={`property-type-box ${_PropertyType.label === ADdata.propertyType ? "selected" : ""}`}
                          key={index}>
                          {renderPropertyIcon2(_PropertyType.id)}
                          <span>{_PropertyType.label}</span>
                        </span>
                      ))}
                    </div>
                  
                    {errors.propertyType && (
                      <FormHelperText component={Box}>
                        <Alert severity='error' className='custom-alert'>
                          {errors.propertyType}
                        </Alert>
                      </FormHelperText>
                    )}
                  </>
                ) : subStep === 2 && (
                  <div className='row mx-0'>
                    <Divider
                      textAlign='left'
                      className='col-12 my-4 custom-divider '
                      style={{ color: "var(--main-color-one)" }}>
                      خدمات العقار
                    </Divider>
                    <div className='fields-wrapper fields-wrapper-row mx-2 col-12 p-0 mb-5 justify-content-start'>
                      {AD_PROPERTY_UTILITIES.map((item, i) => (
                        <div className='col' key={i}>
                          <Switch
                            value={item.label}
                            checked={
                              ADdata.propertyUtilities.filter(
                                (e) => e === item.label
                              ).length > 0
                            }
                            onChange={(target) => {
                              let value = target.currentTarget.value;
                              if (ADdata.propertyUtilities.includes(value)) {
                                setADdata({
                                  ...ADdata,
                                  propertyUtilities:
                                    ADdata.propertyUtilities.filter(
                                      (element) => element !== value
                                    ),
                                });
                              } else
                                setADdata({
                                  ...ADdata,
                                  propertyUtilities: [
                                    ...ADdata.propertyUtilities,
                                    value,
                                  ],
                                });
                            }}
                          />
                          <span
                            style={{ textAlign: "center", textAlignLast: "center" }}>
                            {item.label}
                          </span>
                        </div>
                      ))}
                    </div>
  
                    <Divider
                      textAlign='left'
                      ref={channelsRef}
                      className='col-12 mb-4 custom-divider '
                      style={{ color: "var(--main-color-one)" }}>
                      قنوات الإعلان
                    </Divider>
                    <div>
                      <div className='fields-wrapper fields-wrapper-row mx-2 col-12 p-0 mb-5 justify-content-start'>
                        {AD_CHANNELS.map((item, i) => (
                          <div className='col' key={i}>
                            <Switch
                              value={item.label}
                              checked={ADdata.channels.filter((e) => e === item.label).length > 0}
                              onChange={(target) => {
                                let value = target.currentTarget.value;
                                if (ADdata.channels.includes(value)) {
                                  setADdata({
                                    ...ADdata,
                                    channels: ADdata.channels.filter(
                                      (element) => element !== value
                                    ),
                                  });
                                } else
                                  setADdata({
                                    ...ADdata,
                                    channels: [...ADdata.channels, value],
                                  });
                              }}
                            />
                            <span style={{ textAlign: "center", textAlignLast: "center" }}>
                              {item.label}
                            </span>
                          </div>
                        ))}
                      </div>
                      {errors.channels && (
                        <FormHelperText component={Box}>
                          <Alert severity='error' className='custom-alert'>
                            {errors.channels}
                          </Alert>
                        </FormHelperText>
                      )}
                    </div>
                  
                    <Divider
                      textAlign='left'
                      ref={propertyInfoRef}
                      className='col-12 my-4 custom-divider '
                      style={{ color: "var(--main-color-one)" }}>
                      مواصفات العقار
                    </Divider>
                 
                    <div className='col-12 col-md-6 col-lg-4 col-xl-3 mb-5'>
                      <FormControl error={Boolean(errors.propertyArea)} className='col-12'>
                        <TextField
                            label='مساحة العقار'
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position='start'>{t("m2")}</InputAdornment>
                              )
                            }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          required
                          sx={{ width: "100%" }}
                          value={ADdata.propertyArea}
                          size='small'
                          onChange={(v) => {
                            setADdata({
                              ...ADdata,
                              propertyArea: getFormatedPrice(preventString(v.currentTarget.value)),
                            });
                          }}
                          error={Boolean(errors.propertyArea)}
                        />
                        {errors.propertyArea && (
                          <FormHelperText component={Box}>
                            <Alert severity='error' className='custom-alert'>
                              {errors.propertyArea}
                            </Alert>
                          </FormHelperText>
                        )}
                      </FormControl>
                      </div>
                      <div className='col-12 col-md-6 col-lg-4 col-xl-3 mb-5'>
                      <FormControl
                        error={Boolean(errors.numberOfRooms)}
                        className='col-12'
                      >
                        <TextField
                          label='عدد الغرف'
                          InputLabelProps={{
                            shrink: true,
                          }}
                          // required={[
                          //   "2",
                          //   "3",
                          //   "4",
                          //   "5",
                          //   "7",
                          //   "11",
                          //   "13",
                          //   "15",
                          //   "26",
                          //   "20",
                          //   "21",
                          // ].includes(ADdata.propertyType)}
                          sx={{ width: "100%" }}
                          value={ADdata.numberOfRooms}
                          size='small'
                          onChange={(v) => {
                            setADdata({
                              ...ADdata,
                              numberOfRooms: preventString(
                                v.currentTarget.value
                              ),
                            });
                          }}
                          error={Boolean(errors.numberOfRooms)}
                        />
                        {errors.numberOfRooms && (
                          <FormHelperText component={Box}>
                            <Alert severity='error' className='custom-alert'>
                              {errors.numberOfRooms}
                            </Alert>
                          </FormHelperText>
                        )}
                      </FormControl>
                      </div>
                      <div className='col-12 col-md-6 col-lg-4 col-xl-3 mb-5'>
                      <FormControl error={Boolean(errors.streetWidth)} className='col-12'>
                        <TextField
                            label='عرض الشارع'
                             InputProps={{
                              startAdornment: (
                                <InputAdornment position='start'>{t("m2")}</InputAdornment>
                              )
                            }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          // required={
                          //   !["2", "3", "5", "6", "11", "13"].includes(
                          //     ADdata.propertyType
                          //   )
                          // }
                          sx={{ width: "100%" }}
                          value={ADdata.streetWidth}
                          size='small'
                          onChange={(v) => {
                            setADdata({
                              ...ADdata,
                              streetWidth: preventString(v.currentTarget.value),
                            });
                          }}
                          error={Boolean(errors.streetWidth)}
                        />
                        {errors.streetWidth && (
                          <FormHelperText component={Box}>
                            <Alert severity='error' className='custom-alert'>
                              {errors.streetWidth}
                            </Alert>
                          </FormHelperText>
                        )}
                      </FormControl>
                    </div>
                      
                    <div className='col-12 col-md-6 col-lg-4 col-xl-3 mb-5 '>
                      <FormControl
                        className='custom-select'
                        error={Boolean(errors.propertyAge)}>
                        <TextField
                          select
                          value={ADdata.propertyAge}
                          sx={{ width: "100%" }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          // required={ADdata.propertyType !== "13"}
                          onChange={(event) => {
                            setADdata({
                              ...ADdata,
                              propertyAge: event.target.value,
                            });
                          }}
                          label='عمر العقار'
                          error={Boolean(errors.propertyAge)}>
                          {AD_PROPERTY_AGES.map((item) => (
                            <MenuItem value={item.label} key={item.value}>
                              {item.label}
                            </MenuItem>
                          ))}
                        </TextField>
                        {errors.propertyAge && (
                          <FormHelperText component={Box}>
                            <Alert severity='error' className='custom-alert'>
                              {errors.propertyAge}
                            </Alert>
                          </FormHelperText>
                        )}
                      </FormControl>
                    </div>
                    <div className='col-12 col-md-6 col-lg-4 col-xl-3 mb-5 '>
                      <FormControl
                        className='custom-select'
                        error={Boolean(errors.propertyFace)}>
                        <TextField
                          InputLabelProps={{
                            shrink: true,
                          }}
                          select
                          value={ADdata.propertyFace}
                          sx={{ width: "100%" }}
                          onChange={(event) => {
                            setADdata({
                              ...ADdata,
                              propertyFace: event.target.value,
                            });
                          }}
                          error={Boolean(errors.propertyFace)}
                          label='واجهة العقار'
                        // required={[
                        //   "1",
                        //   "4",
                        //   "7",
                        //   "10",
                        //   "15",
                        //   "17",
                        //   "25",
                        //   "26",
                        //   "20",
                        //   "21",
                        //     ].includes(ADdata.propertyType)}
                        >
                          {AD_PROPERTY_FACES.map((item) => (
                            <MenuItem value={item.label} key={item.value}>
                              {item.label}
                            </MenuItem>
                          ))}
                        </TextField>
                        {errors.propertyFace && (
                          <FormHelperText component={Box}>
                            <Alert severity='error' className='custom-alert'>
                              {errors.propertyFace}
                            </Alert>
                          </FormHelperText>
                        )}
                      </FormControl>
                    </div>

                    <div className='col-12 col-md-6 col-lg-4 col-xl-3 mb-5'>
                      <FormControl className='col-12'>
                        <TextField
                          label='رقم وثيقة الملكية/ ملكية المنفعة'
                          InputLabelProps={{
                            shrink: true,
                          }}
                          sx={{ width: "100%" }}
                          value={ADdata.deedNumber}
                          size='small'
                          onChange={(v) => {
                            setADdata({
                              ...ADdata,
                              deedNumber: preventString(v.currentTarget.value),
                            });
                          }}
                        />
                      </FormControl>
                    </div>
  
                    <Divider
                      textAlign='left'
                      ref={propertyUsagesRef}
                      className='col-12 my-4 custom-divider '
                      style={{ color: "var(--main-color-one)" }}>
                      استخدامات العقار
                    </Divider>
                    <div>
                      <div className='fields-wrapper fields-wrapper-row mx-2 col-12 p-0 mb-5 justify-content-start'>
                        {AD_PROPERTY_USAGE.filter((i) =>
                          i.visibleFor.includes(ADdata.propertyType)
                        ).map((item, i) => (
                          <div className='col' key={i}>
                            <Switch
                              value={item.label}
                              checked={
                                ADdata.propertyUsages.filter(
                                  (e) => e === item.label
                                ).length > 0
                              }
                              onChange={(target) => {
                                let value = target.currentTarget.value;
                                if (ADdata.propertyUsages.includes(value)) {
                                  setADdata({
                                    ...ADdata,
                                    propertyUsages: ADdata.propertyUsages.filter(
                                      (element) => element !== value
                                    ),
                                  });
                                } else
                                  setADdata({
                                    ...ADdata,
                                    propertyUsages: [
                                      ...ADdata.propertyUsages,
                                      value,
                                    ],
                                  });
                              }}
                            />
                            <span style={{ textAlign: "center", textAlignLast: "center" }}>
                              {item.label}
                            </span>
                          </div>
                        ))}
                      </div>
                      {errors.propertyUsages && (
                        <FormHelperText component={Box}>
                          <Alert severity='error' className='custom-alert'>
                            {errors.propertyUsages}
                          </Alert>
                        </FormHelperText>
                      )}
                    </div>
                 
                    <Divider
                      textAlign='left'
                      ref={additionalInfoRef}
                      className='col-12 my-4 custom-divider '
                      style={{ color: "var(--main-color-one)" }}>
                      معلومات إضافيّة
                    </Divider>
  
                    <div className='col-12 col-md-6 col-lg-4 col-xl-3 mb-5'>
                      <FormControl className='col-12'>
                        <TextField
                          label='الإلتزامات على العقار'
                          InputLabelProps={{ shrink: true }}
                          sx={{ width: "100%" }}
                          value={ADdata.obligationsOnTheProperty}
                          size='small'
                          onChange={(v) => {
                            setADdata({
                              ...ADdata,
                              obligationsOnTheProperty:
                                resolvePersianAndArabicNumbers(
                                  v.currentTarget.value
                                ),
                            });
                          }}
                        />
                      </FormControl>
                    </div>
                    <div className='col-12 col-md-6 col-lg-4 col-xl-3 mb-5'>
                      <FormControl className='col-12'>
                        <TextField
                          label='الضمانات ومدتها'
                          InputLabelProps={{ shrink: true }}
                          sx={{ width: "100%" }}
                          value={ADdata.guaranteesAndTheirDuration}
                          size='small'
                          onChange={(v) => {
                            setADdata({
                              ...ADdata,
                              guaranteesAndTheirDuration:
                                resolvePersianAndArabicNumbers(
                                  v.currentTarget.value
                                ),
                            });
                          }}
                        />
                      </FormControl>
                    </div>
                    <div className='col-12 col-md-6 col-lg-4 col-xl-3 mb-5'>
                      <FormControl className='col-12'>
                        <TextField
                          label='رقم المخطط'
                          InputLabelProps={{
                            shrink: true,
                          }}
                          sx={{ width: "100%" }}
                          value={ADdata.planNumber}
                          size='small'
                          onChange={(v) => {
                            setADdata({
                              ...ADdata,
                              planNumber: resolvePersianAndArabicNumbers(
                                v.currentTarget.value
                              ),
                            });
                          }}
                        />
                      </FormControl>
                    </div>
                      
                    <div className='col-12 col-md-6 col-lg-4 col-xl-3 mb-5'>
                      <FormControl className='col-12'>
                        <TextField
                          label={t("LandNumber")}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          sx={{ width: "100%" }}
                          value={ADdata.landNumber}
                          size='small'
                          onChange={(v) => {
                            setADdata({
                              ...ADdata,
                              landNumber:
                                resolvePersianAndArabicNumbers(
                                  v.currentTarget.value
                                ),
                            });
                          }}
                        />
                      </FormControl>
                    </div>
                      
                    <div className='fields-wrapper fields-wrapper-row mx-2 col-12 p-0 mb-5 justify-content-start'>
                      {AD_CONSTRAINTS.map((item, i) => (
                        <div className='col' key={i}>
                          <Switch
                            value={item.key}
                            checked={ADdata.constraints.filter((e) => e === item.key).length > 0}
                            onChange={(target) => {
                              let value = target.currentTarget.value;
                              if (ADdata.constraints.includes(value)) {
                                setADdata({
                                  ...ADdata,
                                  constraints: ADdata.constraints.filter(
                                    (element) => element !== value
                                  ),
                                });
                              } else
                                setADdata({
                                  ...ADdata,
                                  constraints: [...ADdata.constraints, value],
                                });
                            }}
                          />
                          <span style={{ textAlign: "center", textAlignLast: "center" }}>
                            {t(item.label)}
                          </span>
                        </div>
                      ))}
                    </div>
                      
                    <Divider
                      textAlign='left'
                      className='col-12 my-4 custom-divider '
                      style={{ color: "var(--main-color-one)" }}>
                       السعر
                      </Divider>
                    <div className='col-12 col-md-6 col-lg-4 col-xl-3 mb-5'>
                      <FormControl
                        error={Boolean(errors.propertyPrice)}
                        className='col-12'
                      >
                        <TextField
                            label={ADdata.advertisementType === "بيع" ? ADdata.propertyType !== "أرض" ? t("Price") : t("MeterPrice"): "سعر الإيجار السنوي"}
                          InputLabelProps={{
                            shrink: true,
                            }}
                            required
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position='start'>{t("SAR")}</InputAdornment>
                              )
                            }}
                          sx={{ width: "100%" }}
                          value={ADdata.propertyPrice}
                          size='small'
                          onChange={(v) => {
                            setADdata({
                              ...ADdata,
                              propertyPrice: getFormatedPrice(preventString(v.currentTarget.value)),
                            });
                          }}
                          error={Boolean(errors.propertyPrice)}
                        />
                        {errors.propertyPrice && (
                          <FormHelperText component={Box}>
                            <Alert severity='error' className='custom-alert'>
                              {errors.propertyPrice}
                            </Alert>
                          </FormHelperText>
                        )}
                      </FormControl>
                    </div>
                     
                    
                      
                  </div>
                )}
            </div>
            <div className='card-footer'>
              {subStep !== 1 && <LoadingButton
                classes='outlined-btn px-5'
                handleClick={() => {
                  // if (subStep === 1) {
                  //   setCurrentStep(0);
                  // } else
                  setSubStep((old) => old - 1);
                }}
                label={t("Back")}
                loading={false}
              />}
              <LoadingButton
                classes='primary-btn px-5'
                handleClick={() => {
                        
                  // if (subStep === 1) {
                  //   // if (Step1SubStep1IsValid()) setSubStep(2); 
                  // } else
                  if (subStep === 1) {
                    if (Step1SubStep1IsValid()) setSubStep(2);
                  }
                  else {
                    if (Step1SubStep2IsValid()) {
                      setCurrentStep(1);
                      setCompletedSteps([0]);
                    }
                  }
                }}
                label={t("Next")}
                loading={false}
              />
            </div>
          </div>
        )}
      
        {currentStep === 1 && (
          <div className='custom-card'>
            <div className='card-header'>
              <h4>{t("LocateTheProperty")}</h4>
              <h6>{t("LocateThePropertyAccurately")}</h6>
            </div>
            <div className='card-body'>

              <Divider
                textAlign='left'
                className='col-12 my-4 custom-divider'
                style={{ color: "var(--main-color-one)" }}>
                العنوان الوطني
              </Divider>
              <div className='row text-right'>
                <div className='col-12 col-md-6 col-lg-4 col-xl-3 mb-5 '>
                  <FormControl
                    sx={{ width: "100%" }}
                    error={Boolean(errors.regionCode)}>
                    <Autocomplete
                      disablePortal
                      size='small'
                      required
                      options={regionsOptions}
                      value={
                        regionsOptions.filter(
                          (i) => +i.id === +ADdata?.location?.regionCode
                        )?.[0] || null
                      }
                      sx={{ width: "100%" }}
                      onChange={(event, newValue) => {
                        setADdata({
                          ...ADdata,
                          location: {
                            ...ADdata.location,
                            regionCode: newValue?.id || "",
                            region: newValue?.label || "",
                            cityCode: "",
                            city: "",
                            districtCode: "",
                            district: ""
                          },
                        });
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          sx={{ width: "100%" }}
                          required
                          InputLabelProps={{
                            shrink: true,
                          }}
                          error={Boolean(errors.regionCode)}
                          variant='outlined'
                          label={t("Region")}
                        />
                      )}
                    />
                    {errors.regionCode && (
                      <FormHelperText component={Box}>
                        <Alert severity='error' className='custom-alert'>
                          {errors.regionCode}
                        </Alert>
                      </FormHelperText>
                    )}
                  </FormControl>
                </div>

                <div className='col-12 col-md-6 col-lg-4 col-xl-3 mb-5 '>
                  <FormControl
                    sx={{ width: "100%" }}
                    error={Boolean(errors.cityCode)}>
                    <Autocomplete
                      disablePortal
                      size='small'
                      required
                      disabled={!ADdata?.location?.regionCode}
                      options={citiesOptions.filter(
                        (i) => +i.regionId === +ADdata?.location?.regionCode
                      )}
                      value={
                        citiesOptions.filter(
                          (i) => +i.id === +ADdata?.location?.cityCode
                        )?.[0] || null
                      }
                      sx={{ width: "100%" }}
                      noOptionsText={t("ThereAreNoCitiesAssociatedWithTheSelectedRegion")}
                      onChange={(event, newValue) => {
                        setADdata({
                          ...ADdata,
                          location: {
                            ...ADdata.location,
                            cityCode: newValue?.id || "",
                            city: newValue?.label || "",
                            districtCode: "",
                            district: ""
                          },

                        });
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          sx={{ width: "100%" }}
                          required
                          error={Boolean(errors.cityCode)}
                          variant='outlined'
                          label={t("City")}
                        />
                      )}
                    />
                    {errors.cityCode && (
                      <FormHelperText component={Box}>
                        <Alert severity='error' className='custom-alert'>
                          {errors.cityCode}
                        </Alert>
                      </FormHelperText>
                    )}
                  </FormControl>
                </div>

                <div className='col-12 col-md-6 col-lg-4 col-xl-3 mb-5 '>
                  <FormControl
                    sx={{ width: "100%" }}
                    error={Boolean(errors.districtCode)}>
                    <Autocomplete
                      disablePortal
                      size='small'
                      required
                      disabled={!ADdata?.location?.cityCode}
                      options={districtsOptions.filter(
                        (i) =>
                          +i.cityId === +ADdata?.location?.cityCode &&
                          +i.regionId === +ADdata?.location?.regionCode
                      )}
                      value={
                        districtsOptions.filter(
                          (i) => +i.id === +ADdata?.location?.districtCode
                        )?.[0] || null
                      }
                      sx={{ width: "100%" }}
                      noOptionsText={t("ThereAreNoNeighborhoodsAssociatedWithTheSelectedCity")}
                      onChange={(event, newValue) => {
                        setADdata({
                          ...ADdata,
                          location: {
                            ...ADdata.location,
                            districtCode: newValue?.id || "",
                            district: newValue?.label || ""
                          },
                        });
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          sx={{ width: "100%" }}
                          required
                          InputLabelProps={{
                            shrink: true,
                          }}
                          error={Boolean(errors.districtCode)}
                          variant='outlined'
                          label='الحي'
                        />
                      )}
                    />
                    {errors.districtCode && (
                      <FormHelperText component={Box}>
                        <Alert severity='error' className='custom-alert'>
                          {errors.districtCode}
                        </Alert>
                      </FormHelperText>
                    )}
                  </FormControl>
                </div>

                <div className='col-12 col-md-6 col-lg-4 col-xl-3 mb-5'>
                  <FormControl
                    error={Boolean(errors.postalCode)}
                    className='col-12'
                    dir='rtl'>
                    <TextField
                      label='الرمز البريدي'
                      InputLabelProps={{
                        shrink: true,
                      }}
                      required={ADdata.propertyType !== "1"}
                      sx={{ width: "100%" }}
                      value={ADdata.location?.postalCode}
                      size='small'
                      onChange={(v) => {
                        setADdata({
                          ...ADdata,
                          location: {
                            ...ADdata.location,
                            postalCode: preventString(
                              v.currentTarget.value
                            ),
                          },
                        });
                      }}
                      error={Boolean(errors.postalCode)}
                    />
                    {errors.postalCode && (
                      <FormHelperText component={Box}>
                        <Alert severity='error' className='custom-alert'>
                          {errors.postalCode}
                        </Alert>
                      </FormHelperText>
                    )}
                  </FormControl>
                </div>

                <div className='col-12 col-md-6 col-lg-4 col-xl-3 mb-5 '>
                  <FormControl className='col-12' dir='rtl'>
                    <TextField
                      label='اسم الشارع'
                      sx={{ width: "100%" }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      value={ADdata.location?.street}
                      size='small'
                      onChange={(v) => {
                        setADdata({
                          ...ADdata,
                          location: {
                            ...ADdata.location,
                            street: resolvePersianAndArabicNumbers(
                              v.currentTarget.value
                            ),
                          },
                        });
                      }}
                    />
                  </FormControl>
                </div>

                <div className='col-12 col-md-6 col-lg-4 col-xl-3 mb-5 '>
                  <FormControl
                    error={Boolean(errors.buildingNumber)}
                    className='col-12'
                    dir='rtl'>
                    <TextField
                      label='رقم المبنى'
                      InputLabelProps={{
                        shrink: true,
                      }}
                      required={ADdata.propertyType !== "1"}
                      sx={{ width: "100%" }}
                      value={ADdata.location?.buildingNumber}
                      size='small'
                      onChange={(v) => {
                        setADdata({
                          ...ADdata,
                          location: {
                            ...ADdata.location,
                            buildingNumber: preventString(
                              v.currentTarget.value
                            ),
                          },
                        });
                      }}
                      error={Boolean(errors.buildingNumber)}
                    />
                    {errors.buildingNumber && (
                      <FormHelperText component={Box}>
                        <Alert severity='error' className='custom-alert'>
                          {errors.buildingNumber}
                        </Alert>
                      </FormHelperText>
                    )}
                  </FormControl>
                </div>

                <div className='col-12 col-md-6 col-lg-4 col-xl-3 mb-5 '>
                  <FormControl
                    error={Boolean(errors.additionalNumber)}
                    className='col-12'
                    dir='rtl'>
                    <TextField
                      label='رقم إضافي'
                      InputLabelProps={{
                        shrink: true,
                      }}
                      required={ADdata.propertyType !== "1"}
                      sx={{ width: "100%" }}
                      value={ADdata.location?.additionalNumber}
                      size='small'
                      onChange={(v) => {
                        setADdata({
                          ...ADdata,
                          location: {
                            ...ADdata.location,
                            additionalNumber: preventString(
                              v.currentTarget.value
                            ),
                          },
                        });
                      }}
                      error={Boolean(errors.additionalNumber)}
                    />
                    {errors.additionalNumber && (
                      <FormHelperText component={Box}>
                        <Alert severity='error' className='custom-alert'>
                          {errors.additionalNumber}
                        </Alert>
                      </FormHelperText>
                    )}
                  </FormControl>
                </div>
              </div>

              <Divider
                textAlign='left'
                className='col-12 my-4 custom-divider'
                style={{ color: "var(--main-color-one)" }}>
                الموقع الجغرافي
              </Divider>
              <div
                style={{
                  height: "400px",
                  width: "100%",
                  position: "relative",
                  marginTop: "16px",
                }}
                className='map-full-height'>
                <GoogleMapsContainer
                  lat={ADdata.location.latitude}
                  lng={ADdata.location.longitude}
                  onChange={handleChange}
                  onZoomChange={()=>{}}
                  user={ADdata.location}
                />
                <img
                  alt='pin'
                  src={publicUrl + "assets/img/icons/BluePin.svg"}
                  className='addPropPinAdmin'
                />
                <input
                  id='address'
                  name='address'
                  className='form-control py-3 map-input'
                  onChange={handleChangeAddress}
                  value={ADdata.location.address}
                />
              </div>

              <Alert
                severity='warning'
                style={{ width: "fit-content", marginTop: 20 }}>
                &nbsp;&nbsp; {t("PropertyLocationModificationInTheFutureWillNotBePossible")}
              </Alert>
             
            </div>
            <div className='card-footer'>
              <LoadingButton
                classes='outlined-btn px-5'
                handleClick={() => {
                  setCurrentStep(0);
                  setCompletedSteps([0]);
                }}
                label={t("Back")}
                loading={false}
              />
              <LoadingButton
                classes='primary-btn px-5'
                handleClick={() => {
                  // call create Ep
                  if (Step2IsValid()) {
                    setLoadingLocationButton(true);
                    createAdwithoutAdNumber({
                      ...ADdata,
                      numberOfRooms: ADdata.numberOfRooms ? +ADdata.numberOfRooms : 0,
                      language: i18n.language === "ar" ? '0' : '1',
                      streetWidth: ADdata.streetWidth ? +ADdata.streetWidth : 1,
                      propertyUtilities: ADdata.propertyUtilities?.length === 0 ? ["لايوجد خدمات"] : ADdata.propertyUtilities,
                      isConstrained: ADdata.constraints.includes("isConstrained") ? '1' : '0',
                      isPawned: ADdata.constraints.includes("isPawned") ? '1' : '0',
                      isHalted: ADdata.constraints.includes("isHalted") ? '1' : '0',
                      isTestment: ADdata.constraints.includes("isTestment") ? '1' : '0',
                      complianceWithTheSaudiBuildingCode: ADdata.constraints.includes("complianceWithTheSaudiBuildingCode") ? '1' : '0',
                      adLicenseNumber: '1',
                      deedNumber: ADdata.deedNumber || 0,
                      propertyAge: ADdata.propertyAge || "لا",
                      landTotalAnnualRent: ADdata.landTotalAnnualRent || 0,
                      planNumber: ADdata.planNumber || 0,
                      propertyFace: ADdata.propertyFace || "لا",
                      landTotalPrice: ADdata.landTotalPrice || ADdata.propertyPrice?.replace(/[,]+/g, ""),
                      landNumber: ADdata.landNumber || 0,
                      obligationsOnTheProperty: ADdata.obligationsOnTheProperty || "لا",
                      guaranteesAndTheirDuration: ADdata.guaranteesAndTheirDuration || "لا",
                      adLicenseURL: ADdata.adLicenseURL || "لا",
                      adSource: ADdata.adSource || "لا",
                      notes: ADdata.notes || "لا",
                      locationDescriptionOnMOJDeed: ADdata.locationDescriptionOnMOJDeed || "لا",
                      propertyArea:ADdata?.propertyArea?.replace(/[,]+/g, ""),
                      propertyPrice:ADdata?.propertyPrice?.replace(/[,]+/g, "")
                    }).then((res) => {
                      if (res.resCode === 0) {
                        let path = "/admin/unlicensed-ads/add-step3/" + res.response.propertyArr.propertyId;
                        history(path);
                      } else {
                        Swal.fire({
                          title: t("AnErrorOccurred"),
                          text: res.resStr,
                          icon: "error",
                          confirmButtonText: t("OK2"),
                        });
                      }
                    }).finally(() => setLoadingLocationButton(false));
                  }
                }}
                label={t("Next")}
                loading={loadingLocationButton}
              />
            </div>
          </div>
        )}

      </div>
    </>
  );
}

export default AddNew;
