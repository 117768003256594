import React, { useCallback, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { searchPropertiesWithFilter, searchPropertiesWithFilterWL } from "../../api/propertyApi";
import PropertyBox from "./property-box";
import Autocomplete from "react-google-autocomplete";
import { Map, InfoWindow } from "google-maps-react";
import { Select, MenuItem, useMediaQuery, DialogTitle, DialogContent, Dialog, IconButton, CircularProgress } from "@mui/material";
import { getFontspace, mStyles, renderFeatureIcon, renderFeatureIconCondition, renderRoomIcon, renderRoomIconCondition } from "../../helpers";
import {Close, ExpandLess, ExpandMore} from "@mui/icons-material";
import NoData from "../global-components/no-data";
import LoadingData from "../global-components/loading-data";
import { useAuth } from "../../helpers/context";
import MobileInfoWindow from "./search/mobile-info-window";
import MapListToggle from "./search/map-list-toggle";
import PropertyDialog from "./search/property-dialog";
import MarkersList from "./search/marker-list";
import MapListSwitcher from "./search/map-switcher";
import AdvancedSearch from "./search/advanced-search";
import { DARK_TEMPLATES, MODERN_TEMPLATES } from "../../constants";
import { useTranslation } from "react-i18next";

const publicUrl = process.env.REACT_APP_URL + "/";

const mapStyles = {
  width: "100%",
  height: "100%",
  position: "relative",
  overflow: "hidden",
  scrolling: "no",
};

function _mapLoaded(mapProps, map) {
  map.setOptions({
    styles: mStyles,
  });
}
let googleMap = React.createRef();

const SearchMap = (props) => {
  const history = useNavigate();
  let zoomLevel = props.zoom ? Number(props.zoom) : 14;
  const [showMap, setShowMap] = useState(true);
  const [showSearchBox, setShowSearchBox] = useState(true);
  const [openPropertyModal, setOpenPropertyModal] = useState(false);
  const [resultMapToggle, setResultMapToggle] = useState(true);
  const isLargeScreen = useMediaQuery((theme) => theme.breakpoints.up("sm"));
  let timer = useRef();
  const autoCompleteRef = useRef(null);
  const { UserId, vendor, templateId } = useAuth();
  const [Infowindow, setInfowindow] = useState({
    activeMarker: {},
    selectedPlace: {},
    showingInfoWindow: false,
  });
  const [mobileInfowindow, setMobileInfowindow] = useState({
    selectedPlace: {},
    showingInfoWindow: false,
  });
  const [showOptionsList, setShowOptionsList] = useState(false);
  const [sort, setSort] = useState(props.sort);
  const [loadingProperties, setLoadingProperties] = useState(true);
  const [properties, setProperties] = useState([]);
  const [dots, setDots] = useState([]);
  // const [projects, setProjects] = useState([]);
  const [itemClickedId, setitemClickedId] = useState("");
  const [totalCount, settotalCount] = useState({
    total: -1,
    visiblePage: 5,
  });
  const { t, i18n } = useTranslation();

  const [openADsDialog, setOpenADsDialog] = useState({ data: [], open: false });
  const [searchAddress, setSearchAddress] = useState('');
  const [search, setSearch] = useState({
    amenities: "",
    zoomLevel: props.zoom,
    category: '',
    defaultPriceType: "All",
    features: "",
    frontispiece: "",
    isPhotoRequired: "0",
    isPropertyByOwner: "0",
    latitudeTopLeft: "",
    latitudeTopRight: "",
    listedFor: props.listedFor,
    longitudeTopLeft: "",
    longitudeTopRight: "",
    latitudeBottomLeft: "",
    latitudeBottomRight: "",
    longitudeBottomLeft: "",
    longitudeBottomRight: "",
    page: props.page,
    pageSize: "50",
    priceRange: "0,999999999999999",
    propertyType: props.propertyType === "all" ? "-1" : props.propertyType,
    rooms: "",
    userId: UserId ? UserId : "",
    sortBy: sort !== "defaultPriceL" ? sort : "defaultPrice",
    sortOrder: sort !== "defaultPriceL" ? "desc" : "asc",
    platform: "3",
    centerLat: props.lat,
    centerLong: props.long,
    placeholder: "",
  });
  const [hasMore, setHasMore] = useState(true);
  const loaderRef = useRef(null);
  const [loadingMoreData, setLoadingMoreData] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(1);

  const loadMoreItems = () => {
    if (properties?.length >= totalCount.total) {
      setHasMore(false);
      return;
    }

    setLoadingMoreData(true);
    if (vendor === "amakkn") {
      searchPropertiesWithFilter({ ...search, page: +currentIndex + 1, propertyType: search.propertyType === "-1" ? "" : search.propertyType, language: i18n.language === "ar" ? "0" : "1" })
        .then((_DefLocation) => {
          if (_DefLocation.resCode === 0) {
            setProperties(old => [...old, ..._DefLocation.response.propertyArray]);
            setDots((old) => [...old, ..._DefLocation.response.dotsArray])
            settotalCount({
              ...totalCount,
              total: Number(_DefLocation.response.totalCount)
            });
          }
        })
        .finally((e) => {
          setCurrentIndex((old) => +old + 1);
          setLoadingMoreData(false);
        });
      
    } else {
      searchPropertiesWithFilterWL({
        ...search,
        page: +currentIndex + 1,
        propertyType: search.propertyType === "-1" ? "" : search.propertyType,
        language: i18n.language === "ar" ? "0" : "1",
        userName: vendor
      })
        .then((_DefLocation) => {
          if (_DefLocation.resCode === 0) {
            setProperties(old => [...old, ..._DefLocation.response.propertyArray]);
            setDots((old) => [...old, ..._DefLocation.response.dotsArray])
            settotalCount({
              ...totalCount,
              total: Number(_DefLocation.response.totalCount)
            });
          }
        })
        .finally((e) => {
          setCurrentIndex((old) => +old + 1);
          setLoadingMoreData(false);
        });
    } 
  };

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        const target = entries[0];
        if (target.isIntersecting && hasMore && properties.length < totalCount.total) {
          loadMoreItems();
        }
      },
      { root: null, rootMargin: '20px', threshold: 1.0 }
    );

    const currentLoader = loaderRef.current;
    if (currentLoader) observer.observe(currentLoader);

    return () => {
      if (currentLoader) observer.unobserve(currentLoader);
    };
  },// eslint-disable-next-line
    [hasMore, properties]);

    useEffect(() => {
      if (openPropertyModal) {
        setTimeout(() => {
          let scrollIndicator = document?.querySelector('.scroll-indicator');
          if (scrollIndicator) {
            setTimeout(() => {
              scrollIndicator.style.display = 'none';
            }, 1800);
          }
        }, 500);
       
      }
    }, [openPropertyModal]);
  
  useEffect(() => {
    window.scroll({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  function handleSortChange({ target }) {
    let SortBy = target.value;
    let SortOrder = "desc";
    setSort(SortBy);
    updateURL(
      search.listedFor,
      search.propertyType,
      search.centerLat,
      search.centerLong,
      SortBy,
      search.zoomLevel,
      "1"
    );
    if (SortBy === "defaultPriceL") {
      SortBy = "defaultPrice";
      SortOrder = "asc";
    }
    setSearch({
      ...search,
      sortBy: SortBy,
      sortOrder: SortOrder,
    });
    // setLoadingProperties(true);
  }
 

  // function handlePageChanged(e, newPage) {
  //   // setProperties([]);
  //   // setLoadingProperties(true);
  //   // setProjects([]);
  //   setSearch({
  //     ...search,
  //     page: newPage.toString(),
  //   });
  //   updateURL(
  //     search.listedFor,
  //     search.propertyType,
  //     search.centerLat,
  //     search.centerLong,
  //     sort,
  //     search.zoomLevel,
  //     newPage
  //   );
  //   window.scroll({ top: 0, left: 0, behavior: "smooth" });
  // }

  function changeListedFor(target) {
    // setProperties([]);
    // setLoadingProperties(true);
    // setProjects([]);
    setSearch({
      ...search,
      listedFor: target.toString(),
      page: "1",
    });
    updateURL(
      target,
      search.propertyType,
      search.centerLat,
      search.centerLong,
      sort,
      search.zoomLevel,
      "1"
    );
  }

  function handleLocationChange(props) {
    googleMap.map.setCenter(
      new window.google.maps.LatLng(
        props.geometry.location.lat(),
        props.geometry.location.lng()
      )
    );
    updateURL(
      search.listedFor,
      search.propertyType,
      props.geometry.location.lat(),
      props.geometry.location.lng(),
      sort,
      search.zoomLevel,
      "1"
    );
  }
  function BoundsChanged(map) {
    if (googleMap.map.getBounds())
      handleMapChange(
        googleMap.map.getBounds().getNorthEast().lat(),
        googleMap.map.getBounds().getNorthEast().lng(),
        googleMap.map.getBounds().getSouthWest().lat(),
        googleMap.map.getBounds().getSouthWest().lng(),
        googleMap.map.zoom,
        googleMap.map.getCenter().lat(),
        googleMap.map.getCenter().lng()
      );
  }

  function handleMapChange(neLat, neLng, swLat, swLng, zoom, centerLat, centerLng) {
    if (neLat) {
      // setProperties([]);
      // setLoadingProperties(true);
      // setProjects([]);
      setSearch({
        ...search,
        latitudeTopLeft: neLat.toString(),
        longitudeTopLeft: swLng.toString(),
        latitudeTopRight: neLat.toString(),
        longitudeTopRight: neLng.toString(),
        latitudeBottomLeft: swLat.toString(),
        longitudeBottomLeft: swLng.toString(),
        latitudeBottomRight: swLat.toString(),
        longitudeBottomRight: neLng.toString(),
        centerLat: centerLat.toString(),
        centerLong: centerLng.toString(),
        zoomLevel: zoom.toString(),
        page: "1",
      });
      updateURL(search.listedFor, search.propertyType, centerLat, centerLng, sort, zoom, "1");
    }
  }

  function updateURL(listedFor, PropertType, centerLat, cneterLong, sort, zoom, page) {
    window.history.pushState(
      {},
      null,
      `/${window.location.pathname.split('/')[1]}/` +
      listedFor +
      "/" +
      (PropertType === "" || PropertType === "-1" ? "all" : PropertType) +
      "/" +
      centerLat +
      "/" +
      cneterLong +
      "/" +
      sort +
      "/" +
      zoom +
      "/" +
      page
    );
  }


  function onMarkerMouseover(props, marker, e) {
    setInfowindow({
      activeMarker: marker,
      selectedPlace: props.data,
      showingInfoWindow: isLargeScreen,
      allow: false,
    });
  }

  function onMarkerMouseClick(props, marker, e) {
    if (props?.data?.items?.length > 0) {
      if (isLargeScreen && search.zoomLevel >= 11) {
        setOpenADsDialog({ data: props?.data?.items, open: true });
      } else if (!isLargeScreen) {
        setMobileInfowindow({
          selectedPlace: props.data,
          showingInfoWindow: search.zoomLevel >= 12 || window.location.href.includes('properties'),
        });
      }
     
    } else {
      if (isLargeScreen && search.zoomLevel >= 11)
        history({
          pathname: "/property-details/" + props.data.idToShare,
          aboutProps: { propertyId: props.data.propertyId },
        });
      else if (!isLargeScreen)
        setMobileInfowindow({
          selectedPlace: props.data,
          showingInfoWindow: search.zoomLevel >= 12 || window.location.href.includes('properties'),
        });
    }
  
  }

  function onMapClick() {
    setMobileInfowindow({
      selectedPlace: null,
      showingInfoWindow: false,
    });
  }
  // function onProjectMarkerMouseClick(props, marker, e) {
  //   history({
  //     pathname: "/project/" + props.data.id,
  //     aboutProps: { propertyId: props.data.propertyId },
  //   });
  // }
  function onInfoWindowClose() {
    setInfowindow({
      activeMarker: null,
      showingInfoWindow: false,
    });
  }

  useEffect(() => {
    if (vendor && search.latitudeTopLeft) {
      setLoadingProperties(true);
      setCurrentIndex(1);
      setProperties([]);
      window.scroll({ top: 0, left: 0, behavior: "smooth" });
      if (vendor === "amakkn") {
        searchPropertiesWithFilter({ ...search, propertyType: search.propertyType === "-1" ? "" : search.propertyType, language: i18n.language === "ar" ? "0" : "1" })
          .then((_DefLocation) => {
            if (_DefLocation.resCode === 0) {
              setProperties(_DefLocation.response.propertyArray);
              setDots(_DefLocation.response.dotsArray);
  
              settotalCount({
                ...totalCount,
                total: Number(_DefLocation.response.totalCount),
              });
            }
          })
          .finally((e) => setLoadingProperties(false));
      } else {
        searchPropertiesWithFilterWL({
          ...search,
          propertyType: search.propertyType === "-1" ? "" : search.propertyType,
          language: i18n.language === "ar" ? "0" : "1",
          userName: vendor
        })
          .then((_DefLocation) => {
            if (_DefLocation.resCode === 0) {
              setProperties(_DefLocation.response.propertyArray);
              setDots(_DefLocation.response.dotsArray);
              // setProjects(_DefLocation.response.projectsArray);
              settotalCount({
                ...totalCount,
                total: Number(_DefLocation.response.totalCount),
              });
            }
          })
          .finally((e) => setLoadingProperties(false));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search, vendor, i18n.language]);

  window.onhashchange = function () {
    if (window.location.href.includes("search-map")) onCloseBtn();
  };

  function onItemClick(item) {
    setShowOptionsList(false);
    setitemClickedId(item);
    setOpenPropertyModal(true);
  }

  function onCloseBtn() {
    setitemClickedId("");
    updateURL(
      search.listedFor,
      search.propertyType,
      search.centerLat,
      search.centerLong,
      sort,
      search.zoomLevel,
      search.page
    );
    document.body.style.overflow = "auto";
  }

  const geoAddress = (lat, lng) => {
    let latLng = new window.google.maps.LatLng(lat, lng);
    let gecod = new window.google.maps.Geocoder();
    gecod.geocode(
      {
        location: latLng,
        region: "SA",
      },
      (results, status) => {
        if (status === "OK") {
          let neighborhood = "";
          results[0].address_components.map((ad, i) => {
            if (ad.types.includes("locality")) neighborhood = ad.long_name;
            return ad.long_name;
          });
          results[0].address_components.map((ad, i) => {
            if (ad.types.includes("sublocality")) neighborhood = ad.long_name;
            return ad.long_name;
          });
          setSearchAddress(neighborhood);
          if (autoCompleteRef.current)
            autoCompleteRef.current.value = neighborhood;
          return neighborhood;
        }
      }
    );
  };

  const renderPropertiesResult = useCallback(() => {
    return (
      <div className='tab-pane fade show active' id='tabs_1'>
        <div className='row m-0' style={{ paddingBottom: '140px' }}>
          {!loadingProperties ? (
            totalCount?.total > 0 ? (
              properties.map((item, i) => (
                <div
                  key={i}
                  // data-aos='fade-up'
                  className={`col-sm-6 ${showMap ? `${[...MODERN_TEMPLATES, ...DARK_TEMPLATES].includes(+templateId) ? 'col-xl-6' : 'col-xl-4'} col-lg-6 col-md-6` : `${[...MODERN_TEMPLATES, ...DARK_TEMPLATES].includes(+templateId) ? '' : 'col-xl-2'} col-lg-3 col-md-4 col-12`}`}
                  style={{ cursor: "pointer" }}>
                  <PropertyBox
                    key={i}
                    propertey={item}
                    disableLink
                    onItemClick={onItemClick}
                  />
                </div>
              ))
            ) : (
              <div className='mx-auto w-100'>
                <NoData msg={t("NoRealEstateADsWereFound")} />
              </div>
            )
          ) : (
            <LoadingData />
          )}

        </div>
      </div>
    );
  }, // eslint-disable-next-line
    [loadingProperties,properties]);

  return (
    <div className={`search-page-area ${!showSearchBox ? "no-padding" : ""} ${showMap ? "small-padding" : ""} `}>
      {/* search map start */}
      {((showSearchBox && !isLargeScreen) || isLargeScreen) && (
        <div className={`searchBox ${!showMap ? "full-width" : ""}`}>
          <div className='row m-0 px-10 pb-2'>
            <div>
              <ul className='nav nav-tabs rld-banner-tab2'>
                <li className='nav-item'>
                  <button
                    className={`nav-link ${search.listedFor === "1" ? "active" : ""}`}
                    onClick={() => changeListedFor(1)}
                    style={{ padding: '0 0.8rem' }}
                    data-toggle='tab'>
                    {t("ForRent")}
                  </button>
                </li>

                <li className='nav-item'>
                  <button
                    className={`nav-link ${search.listedFor === "2" ? "active" : ""}`}
                    onClick={() => changeListedFor(2)}
                    style={{ padding: '0 0.8rem', borderRadius: 0 }}
                    data-toggle='tab'>
                    {t("ForSale")}
                  </button>
                </li>
                <li className='nav-item'>
                  <button
                    className={`nav-link ${search.listedFor === "3" ? "active" : ""}`}
                    onClick={() => changeListedFor(3)}
                    style={{ padding: '0 0.8rem' }}
                    data-toggle='tab'>
                    {t("All")}
                  </button>
                </li>
              </ul>
            </div>

            <div className='searchBox-input p-0 order-sm-0'>
              <div className='rld-single-input left-icon'>
                <Autocomplete
                  ref={autoCompleteRef}
                  language={i18n.language === "ar" ? 'ar' : 'en'}
                  placeholder={t("EnterTheAddressToSearch")}
                  onPlaceSelected={(place) => handleLocationChange(place)}
                  style={{ fontSize: "15px" }}
                  options={{
                    types: ["(regions)"],
                    componentRestrictions: { country: "SA" },
                  }}
                  defaultValue={searchAddress}
                  onClick={() => autoCompleteRef?.current.select()}
                />
              </div>
            </div>
            <AdvancedSearch
              search={search}
              setSearch={setSearch}
              sort={sort}
              // setProperties={setProperties}
              // setLoadingProperties={setLoadingProperties}
              updateURL={updateURL}
              setShowOptionsList={setShowOptionsList}
              showOptionsList={showOptionsList}
            />
            <MapListSwitcher showMap={showMap} setShowMap={setShowMap} />
          </div>
        </div>
      )}

      {((showMap && isLargeScreen) || (!isLargeScreen && resultMapToggle)) && (
        <div
          className={`search-page-search-wrap ${!showSearchBox ? "full-height" : ""
            }`}>
          <div className='search-page-map'>
            <div id='gmap' className={showSearchBox ? "more-height" : ""}>
              <Map
                ref={(map) => {
                  googleMap = map;
                }}
                options={{}}
                streetViewControl={false}
                fullscreenControl={isLargeScreen}
                scrollwheel={true}
                onIdle={BoundsChanged}
                google={window.google}
                zoom={zoomLevel}
                onCenterChanged={() => {
                  if (timer.current)
                    clearTimeout(timer.current);
                  timer.current = setTimeout(() => {
                    if (googleMap?.map?.getCenter()?.lat()) {
                      geoAddress(
                        googleMap.map.getCenter().lat(),
                        googleMap.map.getCenter().lng()
                      );
                    }
                  }, 2000);
                }
                 
                }
                minZoom={10}
                style={mapStyles}
                onReady={(mapProps, map) => _mapLoaded(mapProps, map)}
                initialCenter={{
                  lat: search.centerLat,
                  lng: search.centerLong,
                }}
                onMouseout={onInfoWindowClose}
                onClick={onMapClick}>
                {properties.length > 0 && (
                  <MarkersList
                    google={window.google}
                    places={window.location.href.includes('properties') ? properties : search.zoomLevel <= 11 ? dots : properties}
                    zoom={search.zoomLevel}
                    project={false}
                    onMouseover={search.zoomLevel <= 11 ? null : onMarkerMouseover}
                    onMouseout={search.zoomLevel <= 11 ? null : onInfoWindowClose}
                    onClick={onMarkerMouseClick}
                  />
                )}
                {/* {projects.length && (
                  <MarkersList
                    google={window.google}
                    places={projects}
                    project={true}
                    onClick={onProjectMarkerMouseClick}
                  />
                )} */}

                <InfoWindow
                  marker={Infowindow.activeMarker}
                  visible={Infowindow.showingInfoWindow}
                  disableAutoPan={true}
                  maxWidth={200}
                  // options={{ pixelOffset: {    Size: window.google.maps.Size(100, 100),} }}
                  // pixelOffset={new window.google.maps.Size(0, 33)}
                  zIndex={null}
                  boxClass={"poi-box"}
                  boxStyle={{
                    background: "#fff",
                    opacity: "1",
                    "box-shadow": "0 1px 2px 0 rgba(0, 0, 0, 0.13)",
                    "text-align": "center",
                    "border-radius": "10px"
                  }}
                  // closeBoxMargin={"28px 26px 0px 0px"}
                  // infoBoxClearance={new window.google.maps.Size(1, 1)}
                  pane={"floatPane"}
                  enableEventPropagation={false}>
                  {Infowindow?.selectedPlace?.place?.count === 1 ? (Infowindow.selectedPlace &&
                    Infowindow.selectedPlace.photos !== undefined ? (
                    <div>
                      {Infowindow.selectedPlace.isFeatured === "1" && <div className="featured-ribbon pr-3"><span>{t("FeaturedAD")}</span></div>}
                      <div className={`pxp-marker-details-fig pxp-cover ${Infowindow.selectedPlace.photos.length > 0 ? "" : "default"}`}>
                        <img
                          src={
                            Infowindow.selectedPlace.photos.length > 0
                              ? Infowindow.selectedPlace.photos.split(",")[0]
                              : publicUrl +
                              "assets/img/defimgs/" +
                              Infowindow.selectedPlace.propertyType +
                              ".svg"
                          }
                          alt='img'
                        />
                      </div>
                      <div className='px-2'>
                        <h6 style={{ lineHeight: "33px", marginBottom: 0 }}>
                          {Infowindow.selectedPlace.propertyTypeName}{" "}
                          {Infowindow.selectedPlace.listedFor === "1"
                            ? t("ForRent")
                            : t("ForSale")}
                        </h6>
                      </div>
                      <div>
                        <ul className='info-list map px-2 '>
                          {Infowindow.selectedPlace.features.map(
                            (feature, i) =>
                              renderFeatureIconCondition(
                                Infowindow.selectedPlace.propertyType,
                                feature.key
                              ) && (
                                <li key={"li" + i} title={feature.name}>
                                  <span className='feature-value'>
                                    {feature.key !== '7'
                                      ? Intl.NumberFormat("en").format(feature.value) + " " + feature.unit
                                      : getFontspace(feature.value)}
                                  </span>
                                  {renderFeatureIcon(feature.key)}
                                </li>
                              )
                          )}

                          {Infowindow.selectedPlace.rooms.map(
                            (room, i) =>
                              renderRoomIconCondition(
                                Infowindow.selectedPlace.propertyType,
                                room.key
                              ) && (
                                <li key={"li-" + i} title={room.name}>
                                  <span className='feature-value'>
                                    {room.value}
                                  </span>
                                  {renderRoomIcon(room.key)}
                                </li>
                              )
                          )}
                        </ul>
                      </div>
                      {Infowindow?.selectedPlace?.additionalInfo?.filter(i => i.key === '10')?.[0]?.valueText &&
                        Infowindow?.selectedPlace?.additionalInfo?.filter(i => i.key === '10')?.[0]?.valueText !== 'لا' && (
                          <div className='pxp-marker-details-info px-2'>
                            <div className='pxp-marker-details-info-price'>
                              <h5>
                                {Infowindow.selectedPlace.defaultPriceFomratted}{" "}
                                {t("SAR")}
                              </h5>
                            </div>
                          </div>
                        )}
                      
                    </div>
                  ) : (<></>)) :
                    (
                      <>
                        {/* More than one AD */}
                        <div className="slider-container">
                          <div className="slider">
                            <div className="slider-track" style={{ width: `${Infowindow?.selectedPlace?.count * 100}%` }}>
                              {Infowindow?.selectedPlace?.items?.map((item, i) => (
                                <div className="slide">
                                  <div className="w-100 h-100">
                                    {item.isFeatured === "1" && <div className="featured-ribbon pr-3"><span>{t("FeaturedAD")}</span></div>}
                                    <div className={`pxp-marker-details-fig pxp-cover ${item.photos.length > 0 ? "" : "default"}`}>
                                      <img
                                        src={
                                          item.photos.length > 0
                                            ? item.photos.split(",")[0]
                                            : publicUrl +
                                            "assets/img/defimgs/" +
                                            item.propertyType +
                                            ".svg"
                                        }
                                        alt='img'
                                      />
                                    </div>
                                    <div className='px-2'>
                                      <h6 style={{ lineHeight: "33px", marginBottom: 0 }}>
                                        {item.propertyTypeName}{" "}
                                        {item.listedFor === "1"
                                          ? t("ForRent")
                                          : t("ForSale")}
                                      </h6>
                                    </div>
                                    <div>
                                      <ul className='info-list map px-2 '>
                                        {item.features.map(
                                          (feature, i) =>
                                            renderFeatureIconCondition(
                                              item.propertyType,
                                              feature.key
                                            ) && (
                                              <li key={"li" + i} title={feature.name}>
                                                <span className='feature-value'>
                                                  {feature.key !== '7'
                                                    ? Intl.NumberFormat("en").format(feature.value) + " " + feature.unit
                                                    : getFontspace(feature.value)}
                                                </span>
                                                {renderFeatureIcon(feature.key)}
                                              </li>
                                            )
                                        )}

                                        {item.rooms.map(
                                          (room, i) =>
                                            renderRoomIconCondition(
                                              item.propertyType,
                                              room.key
                                            ) && (
                                              <li key={"li-" + i} title={room.name}>
                                                <span className='feature-value'>
                                                  {room.value}
                                                </span>
                                                {renderRoomIcon(room.key)}
                                              </li>
                                            )
                                        )}
                                      </ul>
                                    </div>
                                    {item?.additionalInfo?.filter(i => i.key === '10')?.[0]?.valueText &&
                                      item?.additionalInfo?.filter(i => i.key === '10')?.[0]?.valueText !== 'لا' && (
                                        <div className='pxp-marker-details-info px-2'>
                                          <div className='pxp-marker-details-info-price'>
                                            <h5>
                                              {item.defaultPriceFomratted}{" "}
                                              {t("SAR")}
                                            </h5>
                                          </div>
                                        </div>
                                      )}
                      
                                  </div>
                                </div>
                              ))}
                            </div>
                          </div>
                        </div>
                      </>
                    )
                  }
                </InfoWindow>
              </Map>
            </div>
          </div>
        </div>
      )}

      {((!isLargeScreen && !resultMapToggle) || isLargeScreen) && (
        <div className={`search-page-right-wrap ${showMap ? "" : "witout-map"}`}>
          <div className={`search-page-right-side`}>
            <div className='search-content'>
              <div
                className={!showMap ? "fixed-result" : ""}
                style={{ marginBottom: "15px" }}>
                {!loadingProperties && totalCount.total >= 0 && (
                  <div className='row justify-content-between align-items-center m-0 p-2 flex-row-reverse'>
                    <div style={{ width: isLargeScreen ? "250px" : '190px' }}>
                      <Select
                        id='sort'
                        className='sortDDL w-100'
                        name='sort'
                        sx={{
                          border: "none",
                          outline: "none",
                          // paddingLeft: "25px"
                        }}
                        onChange={handleSortChange}
                        value={sort}>
                        <MenuItem value='default' sx={{ direction: [...MODERN_TEMPLATES, ...DARK_TEMPLATES].includes(+templateId) ? "ltr" : 'rtl' }}>
                          {t("OrderDefault")}
                        </MenuItem>
                        <MenuItem value='updatedAt' sx={{ direction: [...MODERN_TEMPLATES, ...DARK_TEMPLATES].includes(+templateId) ? "ltr" : 'rtl' }}>
                          {t("OrderMostRecentModification")}
                        </MenuItem>
                        <MenuItem value='createdAt' sx={{ direction: [...MODERN_TEMPLATES, ...DARK_TEMPLATES].includes(+templateId) ? "ltr" : 'rtl' }}>
                          {t("OrderMostRecentlyAdded")}
                        </MenuItem>
                        <MenuItem value='views' sx={{ direction: [...MODERN_TEMPLATES, ...DARK_TEMPLATES].includes(+templateId) ? "ltr" : 'rtl' }}>
                          {t("OrderMostViewed")}
                        </MenuItem>
                        <MenuItem
                          value='defaultPrice'
                          sx={{ direction: [...MODERN_TEMPLATES, ...DARK_TEMPLATES].includes(+templateId) ? "ltr" : 'rtl' }}>
                          {t("OrderHighestPrice")}
                        </MenuItem>
                        <MenuItem
                          value='defaultPriceL'
                          sx={{ direction: [...MODERN_TEMPLATES, ...DARK_TEMPLATES].includes(+templateId) ? "ltr" : 'rtl' }}>
                          {t("OrderLowestPrice")}
                        </MenuItem>
                      </Select>
                    </div>
                    <div className='col-5 col-sm-6 p-0'>
                      {Number(totalCount.total) ? (
                        Number(totalCount.total) > 50 ? (
                          <h5 className='title searchRes'>
                            {Number(search.page) * 50 > Number(totalCount.total)
                              ? totalCount.total
                              : Number(search.page) * 50}
                            -{1 + 50 * (Number(search.page) - 1)}
                            {` ${t("from")} `}
                            {totalCount.total} {t("RealEstateAD1")}
                          </h5>
                        ) : (
                          <h5 className='title searchRes'>
                            {totalCount.total} {t("RealEstateAD1")}
                          </h5>
                        )
                      ) : (
                        <h5 className='title searchRes'>
                          {t("NoRealEstateADsWereFound")}
                        </h5>
                      )}
                    </div>
                  </div>
                )}
              </div>

              {renderPropertiesResult()}
            </div>
          </div>

          {((isLargeScreen && !loadingProperties && totalCount.total > 50) ||
            (!isLargeScreen &&
              !resultMapToggle &&
              !loadingProperties &&
              totalCount.total > 50)) && (
              <div className='col-12 justify-content-center d-flex mb-4'>
                {/* <Pagination
                  sx={{ "& .MuiPagination-ul": { flexDirection: 'row' } }}
                  count={parseInt(Math.ceil(totalCount.total / 50))}
                  page={Number(search.page)}
                  onChange={handlePageChanged}
                  color='primary'
                  siblingCount={0}
                /> */}
               {hasMore && (
                  <div ref={loaderRef} style={{ height: '50px', textAlign: 'center' }}>
                    {loadingMoreData ? <CircularProgress size={24} sx={{color:'var(--main-color-one)'}} /> : <></>}
                  </div>
                )}
              </div>
            )}
        </div>
      )}

      {itemClickedId && (
        <PropertyDialog
          openPropertyModal={openPropertyModal}
          setOpenPropertyModal={setOpenPropertyModal}
          itemClickedId={itemClickedId}
          setitemClickedId={setitemClickedId}
        />
      )}

      {!isLargeScreen && (
        <MapListToggle
          setMobileInfowindow={setMobileInfowindow}
          setResultMapToggle={setResultMapToggle}
          resultMapToggle={resultMapToggle}
          setShowSearchBox={setShowSearchBox}
          setHideNavbar={props.setHideNavbar} />
      )}

      {!isLargeScreen && resultMapToggle && !showOptionsList && (
        <button
          className='primary-btn py-1 px-2 no-opacity'
          style={{
            position: "fixed",
            top: showSearchBox ? "171px" : "0",
            left: "50%",
            zIndex: "150",
            transform: "translateX(-50%)",
          }}
          onClick={() => {
            setShowSearchBox(!showSearchBox);
            if (props.setHideNavbar) props.setHideNavbar(showSearchBox);
          }}>
          {showSearchBox ? <ExpandLess /> : <ExpandMore />}
        </button>
      )}
      {mobileInfowindow.showingInfoWindow && (
        <MobileInfoWindow showSearchBox={showSearchBox} setMobileInfowindow={setMobileInfowindow} mobileInfowindow={mobileInfowindow} />
      )}

      <Dialog
        onClose={() => setOpenADsDialog({ data: [], open: false })}
        maxWidth={"md"}
        className='custom-dialog'
        style={{ zIndex: 9999999 }}
        sx={{
          "& .MuiPaper-root": {
            margin: 0,
            width: "calc(100% - 20px)",
            zIndex: 9999999,
          },
        }}
        open={openADsDialog.open}>
        <DialogTitle>
          <div className='dialog-head mb-2 pt-3'>
            <div className='dialog-actions close-only'>
              <IconButton
                aria-label='close'
                onClick={() => {
                  setOpenADsDialog({ data: [], open: false });
                }}
                sx={{ color: (theme) => theme.palette.grey[500] }}>
                <Close />
              </IconButton>
            </div>
          </div>
        </DialogTitle>
        <DialogContent>
          <div className='row m-0 justify-content-center'>
            {openADsDialog.data.map((item, i) => (
              <div
                key={i}
                className={`col-sm-6 ${showMap ? `${[...MODERN_TEMPLATES, ...DARK_TEMPLATES].includes(+templateId) ? 'col-xl-6' : 'col-xl-4'} col-lg-6 col-md-6` : `${[...MODERN_TEMPLATES, ...DARK_TEMPLATES].includes(+templateId) ? '' : 'col-xl-2'} col-lg-3 col-md-4 col-12`}`}
                style={{ cursor: "pointer" }}>
                <PropertyBox
                  key={i}
                  propertey={item}
                  disableLink
                  onItemClick={onItemClick}
                />
              </div>
            ))}
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default SearchMap;
