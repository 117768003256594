import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import LoadingButton from "../../../components/global-components/loading-btn";
import { useAuth } from "../../../helpers/context";
import { useTranslation } from "react-i18next";
import { getMyProperties, getPropertyTypesForCategory } from "../../../api/propertyApi";
import { toast } from "react-toastify";
import LoadingData from "../../../components/global-components/loading-data";
import PropertyCardForAdmin from "../../shared/properties/components/property-card-for-admin";
import NoData from "../../../components/global-components/no-data";
import { FormControl, Grid, InputLabel, MenuItem, OutlinedInput, Pagination, Select } from "@mui/material";
import { addPropertiesToProject, getProjects } from "../../../api/userApi";
import ProjectCardForAdmin from "./components/project-card-for-admin";

const pageSize = 12;

const ResidentialUnitsPopup = ({ projectId, open, setPopup, linkedOld, onOK }) => {
  const { token, vendor } = useAuth();
  const [loadingButton, setLoadingButton] = React.useState(false);
  const [loadingProperties, setLoadingProperties] = React.useState(true);
  const [properties, setProperties] = React.useState([]);
  const [totalCount, setTotalCount] = React.useState(0);
  const { t, i18n } = useTranslation();
  const [page, setPage] = React.useState(1);
  const [selectedProperties, setSelectedProperties] = React.useState([]);
  const [projects, setProjects] = React.useState([]);
  const [step, setStep] = React.useState(1);
  const [selectedProject, setSelectedProject] = React.useState(0);
  const [loadingProjects, setLoadingProjects] = React.useState(true);
  const [propertyType, setPropertyType] = React.useState('-1');
  const [propertyTypes, setPropertyTypes] = React.useState([]);

  React.useEffect(() => {
    getPropertyTypesForCategory({
      category: '1,2',
      language: i18n.language === 'ar' ? '0' : '1',
    }).then((res) => setPropertyTypes(res.response));
  }, [i18n]);

  React.useEffect(() => {
    if (open) {
      setLoadingProperties(true);
      getMyProperties({
        userId: token,
        page: page,
        status: "1",
        pageSize: pageSize,
        isAssigned: "All",
        language: i18n.language === 'ar' ? '0' : '1',
        propertyType: propertyType === '-1' ? "" : propertyType
      })
        .then((property) => {
          if (property.resCode === 0) {
            setProperties(property.response.propertyArray);
            setSelectedProperties(linkedOld);
            setLoadingProperties(false);
            setTotalCount(property.response.totalCount);
          } else {
            toast.error(property.resStr);
            setLoadingProperties(false);
          }
        });
    }
  }, // eslint-disable-next-line
    [token, page, i18n, open, propertyType]);

  const handleSave = () => {
    if (selectedProperties?.length > 0) {
      if (step === 1 && projectId === 0) {
          setStep(2);
      } else {
        if (step === 2 && selectedProject === 0) {
          toast.error(t("PleaseSelectProject"));
        } else {
          setLoadingButton(true);
          addPropertiesToProject({
            "language": i18n.language === "ar" ? '0' : '1',
            "userId": token,
            "projectId": projectId || selectedProject,
            "properties": selectedProperties
          }).then((res) => {
            if (res.resCode === 0) {
              setPopup(false);
              setLoadingProperties(true);
              onOK?.();
              setStep(1);
              setSelectedProperties([]);
              setSelectedProject(0);
              setPropertyType("-1");
              toast.success(t("DoneSuccessfully"));
            }
          }).finally(() => setLoadingButton(false));
        }
      }
    } else {
      toast.error(t("PleaseSelectRealEstateADAtLeast"));
    }
  };

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  React.useEffect(() => {
    if (step === 2) {
      setLoadingProjects(true);
      getProjects({
        userName: vendor,
        userId: token,
        language: i18n.language === 'ar' ? '0' : '1',
      }).then((_response) => {
        if (_response.resCode === 0)
          setProjects(_response.response);
      }).finally(() => setLoadingProjects(false));
    }
  }, // eslint-disable-next-line
    [step]);

  return (
    <Dialog
      fullWidth={true}
      maxWidth={"xl"}
      open={open}
      className="custom-dialog model"
      onClose={() => {
        setPopup(!open);
        setLoadingProperties(true);
        setStep(1);
        setSelectedProject(0);
        setSelectedProperties([]);
        setPropertyType("-1");
      }}>
      <DialogTitle>
        <div className="dialog-head flex-column flex-lg-row">
          <div>
            <h2>{projectId === 0 ? (step === 1 ? t("ChooseADs") : t("ChooseProject")) : t("AddResidentialUnits")}</h2>
            <p>{projectId === 0 ? (step === 1 ? t("YouCanChooseOneADOrMore") : t("YouMustChooseAProjectToLinkItWithChoosenADs")) : t("YouCanAddAndDeleteResidentialUnitsLinkedWithThisProject")}</p>
          </div>
          <div className="dialog-actions flex-row">
            <LoadingButton label={step===1 && projectId ===0 ? t("Continue"):t("Save")} classes="px-3 primary-btn" loading={loadingButton} handleClick={handleSave} />
            {step === 2 && (
              <button className="px-3 outlined-btn" onClick={() => {
                setStep(1);
              }}>
                {t("Back")}
              </button>
            )}
            <button className="px-3 outlined-btn" onClick={() => {
              setPopup(!open);
              setLoadingProperties(true);
              setStep(1);
              setSelectedProperties([]);
              setPropertyType("-1");
              setSelectedProject(0);
            }}>
              {t("Cancel")}
            </button>
          </div>
        </div>
          
      </DialogTitle>
      <DialogContent>
        {step === 1 && (
          <div className="row mx-0 mt-2 align-items-center mb-4 mb-lg-3 " style={{ gap: '10px' }}>
            <div className="col-12 col-md-5 col-lg-3 p-0" style={{ maxWidth: '220px' }}>
              <FormControl
                className="custom-select">
                <InputLabel htmlFor='propertyType' required>{t("PropertyType")}</InputLabel>
                <Select
                  value={propertyType}
                  required
                  sx={{ width: "100%" }}
                  onChange={(event) => setPropertyType(event.target.value)}
                  input={<OutlinedInput
                    notched
                    label={t("PropertyType")}
                    id="type"
                  />}
                  label={t("PropertyType")}
                  inputProps={{
                    name: "propertyType",
                    id: "propertyType",
                  }}>
                  <MenuItem value={'-1'} key={'-1'}>{t("All")}</MenuItem>
                  {propertyTypes.map((item) => <MenuItem value={item.key} key={item.key}>{item.name}</MenuItem>)}
                </Select>
              </FormControl>
            </div>
            <div className="col-12 col-md-7 col-lg-6 p-0">
              {selectedProperties?.length - (linkedOld?.length || 0) > 0 && <span>
                {t("RealEstateADsSelected", { num: selectedProperties?.length - (linkedOld?.length || 0) })}
              </span>}
            </div>
          </div>)}
        
        <div className='custom-card' style={{ border: 'none' }}>
          {step === 1 ? (
            <Grid container spacing={3}>
              {loadingProperties ? <Grid xs={12} item className="mb-3">
                <LoadingData />
              </Grid> : properties?.filter(item=> item.relatedProjectId === "" || item.relatedProjectId === "None" )?.length > 0 ? properties.map((item, i) => (!item?.relatedProjectId || item.relatedProjectId === "None" ) && (
                <Grid xs={12} md={6} lg={4} xl={2} item className="mb-3" key={i}>
                  <PropertyCardForAdmin
                    item={item}
                    hideActions
                    disableLink
                    multipleSelect
                    selectedProperties={selectedProperties}
                    setSelectedProperties={setSelectedProperties}
                  />
                </Grid>
              )) : <NoData msg={t("ThereAreNoADsPublishedByYou")} />}
            </Grid>
          ) : (
            <Grid container spacing={3}>
            
              {loadingProjects ? <Grid xs={12} item className="mb-3">
                <LoadingData />
              </Grid> : projects?.length > 0 ? projects.map((item, i) => (
                <Grid xs={12} md={6} lg={4} xl={3} item className="mb-3" key={i}>
               <ProjectCardForAdmin
                      item={item}
                      hideActions
                    disableLink
                    selectedProject={selectedProject}
                      setSelectedProject={setSelectedProject}
                    />
                </Grid>
              )) : <NoData msg={t("ThereAreNoProjectsPublishedByYou")} />}
            </Grid>

          )}
        
            
        </div>
        {totalCount > pageSize && (
          <Pagination
            sx={{ '& .MuiPagination-ul': { flexDirection: 'row', marginTop: '15px' } }}
            count={parseInt(Math.ceil(totalCount / pageSize))}
            page={page}
            onChange={handlePageChange}
            color="primary" />
        )}
      </DialogContent>
    </Dialog>
  );
}

export default ResidentialUnitsPopup;
