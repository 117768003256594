
import React, { useEffect, useState } from 'react';
import { Grid, Typography, Card, Box, CardHeader, CardContent, CardActions, Tooltip } from '@mui/material';
import { NORMAL_PLANS_AVAILABILITY, TRAIL_PLANS, plansFeatures } from '../../../../constants';
import { useAuth } from '../../../../helpers/context';
import { PageSection } from '../../../../components/section-components/white-label/page-section';
import LoadingButton from '../../../../components/global-components/loading-btn';
import { GreenCheck, RedClose } from '../../../../constants/icons';
import LoginPopup from '../../../../components/user-components/login-popup';
import VerifyPopup from '../../../../components/user-components/verify-popup';
import InfoIcon from '@mui/icons-material/Info';
import { toast } from 'react-toastify';
import { schedulePlan, upgradePlan } from '../../../../api/userApi';
import Swal from "sweetalert2/dist/sweetalert2.js";
import { useNavigate } from 'react-router-dom';
import { openDeleteModal } from '../../../../helpers';
import { useTranslation } from 'react-i18next';
// import ChangeDomainDialog from './change-domain-dialog';
// import { confirmAlert } from 'react-confirm-alert';

const PlansBox = ({ isWLUser, fromWL, onOK, upgradePlanFunc, WLPlans, accountType, data, currentPlanID, setSelectedPlan, currentPlan, isFromWebview, setSelectedWLPlan, isUserVerified, setOpenDrawer, setStep, setIsTrial }) => {
    const [plansDurationType, setPlansDurationType] = useState(30);
    const { setToken, setUserId, token } = useAuth();
    const [openVerifyModal, setOpenVerifyModal] = useState(false);
    const [loadingUpgradePlan, setLoadingUpgradePlan] = useState(false);
    const history = useNavigate();
    const { t } = useTranslation();
    
    const renderPlanFeatures = (item, mobile) => {
        return (
            <>
                {mobile && (
                    <>
                        <hr />
                        <p style={{ fontSize: 18, marginBottom: 15, fontWeight: 600 }}>{t("DetailedFeaturesOfThePlan")}</p>
                    </>
                )}
                <p className="pricing-card-feature">
                    <span className='icon'><GreenCheck green /></span>
                    <span>{t("AbilityToAddADs", { number: item?.allowedPublishedProperties })}</span>
                    <span>
                        <Tooltip title={<div>{t("YouCanAddUnlimitedNumberOfRealEstateADsButYouCanPublish")} {" " + item?.allowedPublishedProperties + " "} {t("RealEstateAD1") + " "+ t("AtOnce")}</div>}>
                            <InfoIcon sx={{ cursor: 'pointer', position: 'relative', top: '2px', fontSize: '16px', color: 'var(--main-color-one)' }} />
                        </Tooltip>
                    </span>
                </p>
                <p className="pricing-card-feature">
                    <span className='icon'>{item?.allowedAgents !== '0' ? <GreenCheck green /> : <RedClose />}</span>
                    <span>{item?.allowedAgents !== '0' ? t("AbilityToAddAgents1", { number: item?.allowedAgents }) : t("AbilityToAddAgents")} </span>
                </p>
                <p className="pricing-card-feature">
                    <span className='icon'>{item?.hasSupportPriority === '1' ? <GreenCheck green /> : <RedClose />}</span>
                    <span>{t("TechnicalSupportPriority")}</span>
                </p>
                <p className="pricing-card-feature">
                    <span className='icon'>{item?.canAddLogo === '1' ? <GreenCheck green /> : <RedClose />}</span>
                    <span>{t("AbilityToAddTheCompanyLogoToTheADImages")}</span>
                </p>
                <p className="pricing-card-feature">
                    <span className='icon'>{item?.canAddNumber === '1' ? <GreenCheck green /> : <RedClose />}</span>
                    <span>{t("AbilityToAddTheUnifiedContactNumber")}</span>
                </p>
                <p className="pricing-card-feature">
                    <span className='icon'>{item?.canAddOnMap === '1' ? <GreenCheck green /> : <RedClose />}</span>
                    <span>{t("AbilityToAddTheLocationToTheMapOnTheCompanyPage")}</span>
                </p>
                <p className="pricing-card-feature">
                    <span className='icon'>{item?.canAddVideo === '1' ? <GreenCheck green /> : <RedClose />}</span>
                    <span>{t("AbilityToAddVideosToTheRealEstateAD")}</span>
                </p>
                {/* <p className="pricing-card-feature">
                    <span className='icon'>{item?.canAddExtraAds === '1' ? <GreenCheck green /> : <RedClose />}</span>
                    <span>{t("AbilityToAddAdditionalADsFor20SARPerAD")}</span>
                </p> */}
                <p className="pricing-card-feature">
                    <span className='icon'>{item?.canExportAdPhoto === '1' ? <GreenCheck green /> : <RedClose />}</span>
                    <span>{t("PrintableAndShareableImageOfRealEstateListingDetails")}</span>
                </p>
                <p className="pricing-card-feature">
                    <span className='icon'>{item?.permanentUpgradeToNumberOfAds > '0' ? <GreenCheck green /> : <RedClose />}</span>
                    <span>
                        {+item?.permanentUpgradeToNumberOfAds > 0 ? (
                            `${t("AbilityToFeature")} ${+item?.permanentUpgradeToNumberOfAds > 2 ? `${item?.permanentUpgradeToNumberOfAds} ${t("RealEstateAds")}` : t("OnlyOneRealEstateAD")}`
                        ) : t("AbilityToFeatureRealEstateADs")
                        }
                    </span>
                </p>
                <hr />
                <p style={mobile ? { fontSize: 18, marginBottom: 15, fontWeight: 600 } : {}}>{t("SaaSService")}</p>
                {TRAIL_PLANS.includes(+item.id) ? (<>
                    {plansFeatures?.[+item.id === 9 ? 7 : +item.id === 10 ? 6 : +item.id === 11 ? 9 : 8]?.map((f, i) => (
                        <p key={i} className="pricing-card-feature">
                            <span className='icon'>{f.available === '1' ? <GreenCheck green /> : <RedClose />}</span>
                            <span>{f.name === "CRM2"? t(f.name,{num:Intl.NumberFormat('en').format(item.planCustomers)}) :t(f.name)}</span>
                        </p>
                    ))}
                </>) : (<>
                    <p className="pricing-card-feature">
                        <span className='icon'>{<RedClose />}</span>
                        <span>{t("NotAvailable")}</span>
                    </p>
                </>)}
            </>
        )
    }

    useEffect(() => {
        if (data?.filter(d => (accountType === 0 ? [...NORMAL_PLANS_AVAILABILITY["1"],
        ...NORMAL_PLANS_AVAILABILITY['2']].includes(d.id) : NORMAL_PLANS_AVAILABILITY[accountType].includes(d.id)) && +d.duration === 30)?.length === 0) {
            setPlansDurationType(365)
        } else {
            setPlansDurationType(30)
        }
    }, [data, accountType]);

    const checkData = () => {
        return data?.filter(d => (accountType === 0 ? [...NORMAL_PLANS_AVAILABILITY["1"],
        ...NORMAL_PLANS_AVAILABILITY['2']].includes(d.id) : NORMAL_PLANS_AVAILABILITY[accountType].includes(d.id)) && +d.duration === 365)?.length === 0 ||
            data?.filter(d => (accountType === 0 ? [...NORMAL_PLANS_AVAILABILITY["1"],
            ...NORMAL_PLANS_AVAILABILITY['2']].includes(d.id) : NORMAL_PLANS_AVAILABILITY[accountType].includes(d.id)) && +d.duration === 30)?.length === 0;
    }

    
    // const openConfirmPopup = () => {
    //     confirmAlert({
    //         customUI: ({ onClose }) => {
    //             return (
    //                 <div
    //                     className='custom-ui'
    //                     style={{ maxHeight: "80vh", overflow: "auto", direction: "rtl" }}>
    //                     <>
    //                         <h3>الموافقة على اتفاقية استخدام اماكن برو</h3>
    //                         <p className='my-4'>
    //                             شكرًا لاختيارك <b>أماكن برو</b>. من خلال الاشتراك في هذه
    //                             الخدمة، أنت <b>توافق</b> على الالتزام بالشروط والأحكام الآتية:
    //                         </p>
    //                         <ul className='mt-3 pb-3'>
    //                             <li className='mb-3'>
    //                                 <b>التعريف بالخدمة</b>: اماكن برو هي خدمة مقدمة من{" "}
    //                                 <b>منصة أماكن</b> تتيح لك إنشاء وإدارة موقع عقاري بالنطاق
    //                                 المخصص (<b>{domainNameWithExt}</b>) للتسويق عن اعمالك وعرض
    //                                 اعلاناتك العقاريّة المرخصة.
    //                             </li>
    //                             <li className='mb-3'>
    //                                 <b>ملكية المواقع والنطاقات</b>: جميع المواقع والنطاقات التي
    //                                 تنشئ من خلال اماكن برو مملوكة لمنصة أماكن وتتيح هذه
    //                                 الاتفاقية للمستخدم الانتفاع منها ما دام مشتركاُ الا في حال
    //                                 الاخلال بشروط هذه الاتفاقية او اتفاقية استخدام منصة أماكن.
    //                             </li>
    //                             <li className='mb-3'>
    //                                 <b>اشتراطات الهيئة العامة للعقار</b>
    //                                 <ul className='mb-4 mt-1'>
    //                                     <li>
    //                                         هذه الخدمة مصرحة وخاضعة لرقابة الهيئة العامة للعقار.
    //                                     </li>
    //                                     <li>
    //                                         تعديل المحتوى و إضافة الإعلانات العقاريّة المرخصة على
    //                                         الموقع (<b>{domainNameWithExt}</b>) يكون متاح حصراً لـ (
    //                                         <b>{user.name}</b>) المسجلة بالرقم الوطني الموحد\هوية
    //                                         وطنية (
    //                                         <b>
    //                                             {user.accountType === "2"
    //                                                 ? user.commercialRecordNumber
    //                                                 : user.nationalid}
    //                                         </b>
    //                                         )
    //                                     </li>
    //                                     <li>
    //                                         إضافة الإعلانات العقاريّة على الموقع تشترط وجود ترخيص
    //                                         إعلان عقاري فعال مربوط بالرقم الوطني الموحد\الهوية
    //                                         الوطنية (
    //                                         <b>
    //                                             {user.accountType === "2"
    //                                                 ? user.commercialRecordNumber
    //                                                 : user.nationalid}
    //                                         </b>
    //                                         ) وصادر عن طريق منصة الهيئة العامة للعقار.
    //                                     </li>
    //                                     <li>
    //                                         تسجيل النطاق لدى المركز السعودي للأعمال خلال 14 يوم.
    //                                     </li>
    //                                     <li>
    //                                         الامتثال لأي تشريعات قد تطرأ مستقبلاً من الجهات ذات
    //                                         العلاقة.
    //                                     </li>
    //                                 </ul>
    //                             </li>
    //                             <li className='mb-3'>
    //                                 <b>الرسوم والتجديد</b>
    //                                 <ul className='mb-4 mt-1'>
    //                                     <li>
    //                                         تُطبق رسوم اشتراك شهرية أو سنوية وفقًا للخطة المختارة.
    //                                     </li>
    //                                     <li>
    //                                         تجدد الاشتراكات تلقائيًا في نهاية كل فترة اشتراك او في
    //                                         حال انتهاء الفترة التجريبية ما لم يقوم المستخدم بإلغاء
    //                                         الاشتراك.
    //                                     </li>
    //                                     <li>
    //                                         يمكن إلغاء اشتراك "اماكن برو" في أي وقت، ولكن الرسوم
    //                                         المدفوعة مقدمًا غير قابلة للاسترداد.
    //                                     </li>
    //                                 </ul>
    //                             </li>
    //                             <li className='mb-3'>
    //                                 <b>التعديلات على الخدمة والشروط</b>
    //                                 <ul className='mb-4 mt-1'>
    //                                     <li>
    //                                         تحتفظ "أماكن" بالحق في تعديل أو إنهاء خدمة "اماكن برو"
    //                                         أو تغيير الرسوم دون إشعار مسبق.
    //                                     </li>
    //                                     <li>
    //                                         أي تغييرات في شروط الخدمة تصبح سارية المفعول بمجرد نشرها
    //                                         في هذه الصفحة.
    //                                     </li>
    //                                 </ul>
    //                             </li>
    //                         </ul>
    //                         <hr className='mt-5 pt-4 mb-0 pb-0' />
    //                         <p
    //                             className='p-0 m-0'
    //                             style={{ color: "#d00404", fontWeight: "bold" }}>
    //                             باستخدامك لخدمة "اماكن برو"، أنت تقر وتوافق على الشروط
    //                             والأحكام المذكورة أعلاه و
    //                             <a
    //                                 href='https://www.amakkn.com/Terms'
    //                                 rel='noreferrer'
    //                                 target='_blank'
    //                                 style={{ textDecoration: "underline", marginRight: "4px" }}>
    //                                 اتفاقية استخدام منصة اماكن
    //                             </a>
    //                             .
    //                             <p
    //                                 className='p-0 mt-3 mb-4'
    //                                 style={{ color: "#d00404", fontWeight: "bold" }}>
    //                                 و بضغطك على زر استمرار فأنت تؤكد على تجريب خدمة أماكن برو باستخدام النطاق (
    //                                 {domainNameWithExt}).
    //                                 <br />
    //                                 {`وتقر بأنّ جميع الإعلانات العقاريّة التي سوف تقوم بإضافتها تحمل رقم ترخيص تابع للسجل التجاري رقم /${user?.commercialRecordNumber}/`}
    //                             </p>
    //                         </p>
    //                         <p style={{ fontWeight: "bold" }}>
    //                             نقدر ثقتك بأماكن ونعدك بتقديم خدمة استثنائية تلبي احتياجاتك
    //                             العقاريّة.
    //                         </p>
    //                     </>
                
    
    //                     <div className='btns-wrapper'>
    //                         <LoadingButton
    //                             loading={loadingUpgradePlan}
    //                             classes='primary-btn px-4'
    //                             handleClick={() => {
                    
    //                                 registerAmakknDomain(onClose);
    //                             }}
    //                             label={t("Continue")}
    //                         />
    
    //                         <button
    //                             className='outlined-btn px-3'
    //                             onClick={() => {
    //                                 onClose();
    //                             }}>
    //                             {t("Close")}
    //                         </button>
    //                     </div>
    //                 </div>
    //             );
    //         },
    //         overlayClassName: "custom-ui-width",
    //     });
    // };

    return (
        <>
            {checkData() ? <></> :
                (
                    <ul className='nav nav-tabs rld-banner-tab2 m-auto plan-card no-bg' style={{ height: '45px', width: '200px' }}>
                        <li className='nav-item position-relative' style={{ flex: 1 }}>
                            <button
                                className={`w-100 h-100 nav-link text-center ${plansDurationType === 365 ? "active" : ""}`}
                                onClick={() => setPlansDurationType(365)}
                                data-toggle='tab'>
                                {t("Yearly")}
                            </button>
                       
                        </li>
                        <li className='nav-item' style={{ flex: 1 }}>
                            <button
                                className={`w-100 h-100 nav-link text-center ${plansDurationType === 30 ? "active" : ""}`}
                                onClick={() => setPlansDurationType(30)}
                                data-toggle='tab'>
                                {t("Monthly")}
                            </button>
                        </li>
                    </ul>
                )}
           
            <Box pt={5} textAlign="center">
                <Grid container spacing={3} sx={{
                    width: '100%',
                    justifyContent: 'center',
                    padding: 0,
                    marginTop: checkData() ? 5 : 0
                }}>
                    
                    {data?.filter(d => (accountType === 0 ? [...NORMAL_PLANS_AVAILABILITY["1"], ...NORMAL_PLANS_AVAILABILITY['2']].includes(d.id) : NORMAL_PLANS_AVAILABILITY[accountType].includes(d.id)) && d.duration === plansDurationType)?.map(item => (currentPlanID !== 9 || (currentPlanID === 9 && +item.id !== 10)) && (currentPlanID !== 11 || (currentPlanID === 11 && +item.id !== 12)) && (currentPlanID !== 5 || (currentPlanID === 5 && +item.id !== 6)) && ((currentPlanID || 0) !== +item.id) && (
                        <Grid item xs={12} md={5} lg={data?.filter(d => (accountType === 0 ? [...NORMAL_PLANS_AVAILABILITY["1"], ...NORMAL_PLANS_AVAILABILITY['2']].includes(d.id) : NORMAL_PLANS_AVAILABILITY[accountType].includes(d.id)) && d.duration === plansDurationType)?.length > 3 ? 3 : 4} key={item.id} style={{ padding: '0 6px 15px' }} data-aos={fromWL ? null : "fade-up"} data-aos-duration="1500">
                            <Card className="pricing-card">
                                <span className='circle circle-1'></span>
                                <span className='circle circle-2'></span>
                                    
                                <CardHeader className='pricing-card-header' sx={{ '& span': { textAlign: 'center !important' } }}
                                    style={{ fontWeight: 700 }} title={item.name} />
                                <CardContent sx={{ minHeight: 180 }}>
                                    <Box px={1} className="text-center">
                                        <p className="text-center" style={{ padding: '4px 10px', fontSize: '17px', fontWeight: 400, minHeight: '60px' }}>
                                            {[2, 3].includes(+item.id) ? t("SuitableForRealEstateBrokers") :
                                                [5, 6].includes(+item.id) ? t("SuitableForSmallRealEstateCompanies") :
                                                    [9, 10].includes(+item.id) ? t("SuitableForMediumRealEstateCompanies") :
                                                        [11, 12].includes(+item.id) ? t("SuitableForLargeRealEstateCompanies") : ''
                                            }
                                        </p>
                                        {item?.price !== '0' ?
                                            <Typography sx={{ textAlign: 'center !important', fontSize: '1.8rem', fontWeight: 700 }} variant="h3" component="h2" gutterBottom={true}>
                                                {Intl.NumberFormat("en").format(+item?.duration !== 365 ? item?.price : (item?.price / 12).toFixed(+item?.duration !== 365 ? 2 : 0))} {t("SAR")} &nbsp;
                                                <Typography variant="h6" color="textSecondary" component="span"
                                                    style={{ margin: "0 -15px", fontSize: '14px', fontWeight: 400 }}>
                                                    {+item?.duration === 30 ? t("PerMonth") : +item?.duration === 365 ?
                                                        (<>
                                                            {t("PerMonth")}
                                                            <br />
                                                            ({Intl.NumberFormat("en").format(parseInt(item?.price)) + t("SARPaidAnnually")})
                                                        </>)
                                                        
                                                        : `${item?.duration}${t("PerDay")}`}
                                                </Typography>
                                            </Typography> :
                                            <Typography sx={{ textAlign: 'center' }} variant="h3" component="h2" gutterBottom={true}>
                                                {t("Free")} &nbsp;
                                            </Typography>
                                        }
                                         
                                    </Box>
                                    {!fromWL &&
                                        <div className='col-12 d-block d-md-none'>
                                            <Box px={1}>
                                                {renderPlanFeatures(item, true)}
                                            </Box>
                                        </div>
                                    }
                                </CardContent>
                                <CardActions>
                                    <div className='w-100'>
                                        {token ? isUserVerified !== '3' ? (isFromWebview ? null :
                                            <VerifyPopup
                                                openVerifyModal={openVerifyModal}
                                                setOpenVerifyModal={setOpenVerifyModal}
                                                desc={t("YouMustVerifyYourAccountInOrderToContinue")}
                                                trigger={
                                                    <LoadingButton
                                                        loading={false}
                                                        classes="btn primary-btn same-width small-raduis w-100 justify-content-center"
                                                        handleClick={() => setOpenVerifyModal(true)}
                                                        label={isWLUser ? t("UpgradeNow") : (+currentPlanID === 10 && +item.id === 9) || (+currentPlanID === 12 && +item.id === 11) || (+currentPlanID === 6 && +item.id === 5) || (+currentPlanID === 3 && +item.id === 2) ? t("UpgradeNow") :
                                                            (+currentPlanID > +item.id) ? t("BackToThisPlan") :
                                                                TRAIL_PLANS.includes(+item.id) && ![9, 10].includes(+currentPlanID) ? fromWL ? t("UpgradeNow") : t("StartNowForFree") : t("UpgradeNow")}
                                                    />
                                                }
                                            />) :
                                            <LoadingButton
                                                OKdisabled={loadingUpgradePlan}
                                                loading={false}
                                                handleClick={() => {
                                                    setSelectedPlan?.(item);
                                                    if (isWLUser || (+currentPlanID === 10 && +item.id === 9) || (+currentPlanID === 12 && +item.id === 11) || (+currentPlanID === 6 && +item.id === 5) || (+currentPlanID === 3 && +item.id === 2)) {
                                                        // upgrade EP
                                                        if (currentPlan?.hasDowngrade === "1") {
                                                            toast.error(t("YourAccountIsScheduledToRollBackToPlan", { name: data?.filter(i => +i.id === +currentPlan?.downgradePlanId)?.[0]?.name }))
                                                        } else {
                                                            setLoadingUpgradePlan(true);
                                                            upgradePlan({
                                                                userId: token,
                                                                planId: item.id,
                                                                domain: "-1"
                                                            }).then(res => {
                                                                if (res.resCode === 0) {
                                                                    if (+res.response.result !== 1) {
                                                                        if (+res.response.result === 0)
                                                                            toast.error(res.response.message);
                                                                        if (fromWL) {
                                                                            upgradePlanFunc(item);
                                                                        } else {
                                                                            setIsTrial(false);
                                                                            setOpenDrawer(true);
                                                                        }
                                                                    } else {
                                                                        Swal.fire({
                                                                            title: t("SubscriptionCompletedSuccessfully"),
                                                                            text: t("SubscriptionUpgradedSuccessfully"),
                                                                            icon: "success",
                                                                            confirmButtonText: t("Close"),
                                                                        });
                                                                        if (!fromWL)
                                                                            history('/my-subscription');
                                                                        else
                                                                            onOK?.();
                                                                    }
                                                                } else {
                                                                    toast.error(res.resStr);
                                                                    if (fromWL) {
                                                                        upgradePlanFunc(item);
                                                                    } else {
                                                                        setIsTrial(false);
                                                                        setOpenDrawer(true);
                                                                    }
                                                                }
                                                            }).catch(() => {
                                                                if (fromWL) {
                                                                    upgradePlanFunc(item);
                                                                } else {
                                                                    setIsTrial(false);
                                                                    setOpenDrawer(true);
                                                                }
                                                            })
                                                                .finally(() => setLoadingUpgradePlan(false));
                                                        }
                                                       
                                                    } else if ((isWLUser || !isWLUser) && +currentPlanID > +item.id) {
                                                        //downgrade
                                                        openDeleteModal(t("ConfirmReturnToThisPlan"), t("AreYouSureYouWantToGoBackToThisPlan"), () => {
                                                            schedulePlan({ "userId": token, "planId": item.id }).then(
                                                                res => {
                                                                    if (res.resCode === 0) {
                                                                        Swal.fire({
                                                                            title: t("TheOperationHasBeenScheduledSuccessfully"),
                                                                            text: t("YourRollbackToPlan", { name: item.name }),
                                                                            icon: "success",
                                                                            confirmButtonText: t("Close"),
                                                                        });
                                                                        if (!fromWL)
                                                                            history('/my-subscription');
                                                                        else
                                                                            onOK?.();
                                                                    } else {
                                                                        toast.error(res.resStr);
                                                                    }
                                                                }
                                                            );
                                                        }, t("Confirm"));
                                                    } else {
                                                        if (currentPlan?.hasDowngrade === "1") {
                                                            toast.error(t("YourAccountIsScheduledToRollBackToPlan", { name: data?.filter(i => +i.id === +currentPlan?.downgradePlanId)?.[0]?.name }))
                                                        } else {
                                                            if (fromWL) {
                                                                setLoadingUpgradePlan(true);
                                                                upgradePlan({
                                                                    userId: token,
                                                                    planId: item.id,
                                                                    domain: "-1"
                                                                }).then(res => {
                                                                    if (res.resCode === 0) {
                                                                        if (+res.response.result !== 1) {
                                                                            if (+res.response.result === 0)
                                                                                toast.error(res.response.message);
                                                                            upgradePlanFunc(item);
                                                                        } else {
                                                                            Swal.fire({
                                                                                title: t("SubscriptionCompletedSuccessfully"),
                                                                                text: t("SubscriptionUpgradedSuccessfully"),
                                                                                icon: "success",
                                                                                confirmButtonText: t("Close"),
                                                                            });
                                                                            onOK?.();
                                                                        }
                                                                    } else {
                                                                        toast.error(res.resStr);
                                                                        upgradePlanFunc(item);
                                                                    }
                                                                }).catch(() => {
                                                                    upgradePlanFunc(item);
                                                                })
                                                                    .finally(() => setLoadingUpgradePlan(false));
                                                                
                                                            } else {
                                                                if ([2, 3, 5, 6].includes(+item.id)) {
                                                                    setOpenDrawer(true);
                                                                } else if ([9, 10, 11, 12].includes(+item.id)) {
                                                                    if (+item.id === 9) setSelectedWLPlan(WLPlans?.filter(p => +p.id === 7)?.[0]);
                                                                    else if (+item.id === 10) setSelectedWLPlan(WLPlans?.filter(p => +p.id === 6)?.[0]);
                                                                    else if (+item.id === 11) setSelectedWLPlan(WLPlans?.filter(p => +p.id === 9)?.[0]);
                                                                    else if (+item.id === 12) setSelectedWLPlan(WLPlans?.filter(p => +p.id === 8)?.[0]);
            
                                                                    if ([4, 5, 6].includes(+currentPlanID)) {
                                                                        setStep(2);
                                                                        setIsTrial(true);
                                                                        // openConfirmPopup();

                                                                        // getData();

                                                                    } else if ([9, 10].includes(+currentPlanID)) {
                                                                        setIsTrial(false);
                                                                        setOpenDrawer(true);
                                                                    }
                                                                }
                                                            }
                                                        
                                                        }
                                                    }
                                                }}
                                                classes={`btn primary-btn same-width small-raduis w-100 justify-content-center ${!isWLUser && TRAIL_PLANS.includes(+item.id) ? 'pulse-btn':''}`}
                                                label={isWLUser ? t("UpgradeNow") : (+currentPlanID === 10 && +item.id === 9) || (+currentPlanID === 12 && +item.id === 11) || (+currentPlanID === 6 && +item.id === 5) || (+currentPlanID === 3 && +item.id === 2) ? t("UpgradeNow") :
                                                    (+currentPlanID > +item.id) ? t("BackToThisPlan") :
                                                        TRAIL_PLANS.includes(+item.id) && ![9, 10].includes(+currentPlanID) ? fromWL ? t("UpgradeNow") : t("StartNowForFree") : t("UpgradeNow")} />
                                            
                                            : (isFromWebview ? null :
                                                <LoginPopup
                                                    setToken={setToken}
                                                    setUserId={setUserId}
                                                    children={
                                                        <button className="btn primary-btn same-width small-raduis w-100 justify-content-center">
                                                            {TRAIL_PLANS.includes(+item.id) ? fromWL ? t("UpgradeNow") : t("StartNowForFree") : t("SubscribeNow")}
                                                        </button>
                                                    }
                                                    path={`/plans-and-prices`}
                                                />)
                                        }
                                    </div>
                                </CardActions>
                            </Card>
                        </Grid>
                    ))}
                </Grid>
            </Box>
            {!fromWL &&
                <div className='col-12 d-none d-md-block'>

                    <PageSection
                        preTitle={t("PlanFeatures")}
                        title={t("DetailedFeaturesOfThePlans")}
                        description=''
                    >
                        <Box pt={5} textAlign="center">
                            <Grid container spacing={3} sx={{
                                width: '100%',
                                justifyContent: 'center',
                                padding: 0
                            }}>
                    
                                {data?.filter(d => (accountType === 0 ? [...NORMAL_PLANS_AVAILABILITY["1"], ...NORMAL_PLANS_AVAILABILITY['2']].includes(d.id) : NORMAL_PLANS_AVAILABILITY[accountType].includes(d.id)) && d.duration === plansDurationType)?.map(item => (currentPlanID !== 9 || (currentPlanID === 9 && +item.id !== 10)) && (currentPlanID !== 11 || (currentPlanID === 11 && +item.id !== 12)) && (currentPlanID !== 5 || (currentPlanID === 5 && +item.id !== 6)) && ((currentPlanID || 0) !== +item.id) && (
                                    <Grid item xs={12} md={5} lg={data?.filter(d => (accountType === 0 ? [...NORMAL_PLANS_AVAILABILITY["1"], ...NORMAL_PLANS_AVAILABILITY['2']].includes(d.id) : NORMAL_PLANS_AVAILABILITY[accountType].includes(d.id)) && d.duration === plansDurationType)?.length > 3 ? 3 : 4} key={item.id} style={{ padding: '0 6px 15px' }} data-aos={fromWL ? null : "fade-up"} data-aos-duration="1500">
                                        <Card className="pricing-card">
                                            <CardHeader className='pricing-card-header' title={item.name}></CardHeader>
                                            <CardContent>
                                                <Box px={1}>
                                                    {renderPlanFeatures(item, false)}
                                                </Box>
                                            </CardContent>
                                        </Card>
                                    </Grid>
                                ))}
                            </Grid>
                        </Box>
                    </PageSection>
                </div>
            }
        </>
    );
}

export default function AmakknPlansCards({ user, isWLUser, upgradePlanFunc, onOK, fromWL, WLPlans, data, currentPlanID, setSelectedWLPlan, setSelectedPlan, selectedPlan, AccountType, isFromWebview, isUserVerified, setOpenDrawer, setStep, setIsTrial, currentPlan }) {
    const { token } = useAuth();
    const { t } = useTranslation();
    // const [domainNameWithExt, setDomainNameWithExt] = useState("");
    // const [domain, setDomain] = useState("");
    // const [isOpenChangeDomainDialog, setIsOpenChangeDomainDialog] = useState(false);

    // const openChangeDomainDialog = (onClose) => {
    //     setIsOpenChangeDomainDialog(true);
    //     onClose?.();
    // }
    
    // const getData = async () => {
    //     const domainData = getItemWithExpiry('domainData');
    //     if (domainData) {
    //         setDomain(domainData?.domainNameWithoutExtension);
    //         setDomainNameWithExt(domainData?.domainNameWithExt);
    //         openConfirmTrailPopup(domainData?.domainNameWithExt, user, registerAmakknDomain, openChangeDomainDialog,domainData?.domainNameWithoutExtension);

    //     } else {
    //         let data = await startFreeTrail(token);
    //         if (!data.error) {
    //             if (data.domainNameWithoutExtension && data.domainNameWithExt) {
    //                 setDomain(data?.domainNameWithoutExtension);
    //                 setDomainNameWithExt(data?.domainNameWithExt);
    //                 setItemWithExpiry('domainData', {
    //                     domainNameWithoutExtension: data?.domainNameWithoutExtension,
    //                     domainNameWithExt: data?.domainNameWithExt
    //                 }, 3600000 / 2);
    //                 openConfirmTrailPopup(data?.domainNameWithExt, user, registerAmakknDomain, openChangeDomainDialog,data?.domainNameWithoutExtension);
    
    //             }
    //         }
           
    //     }
    // }

    // function registerAmakknDomain(callback, domainNameWithoutExt) {
    //     let body = {
    //         language: i18n.language === "ar" ? "0" : "1",
    //         userName: domainNameWithoutExt || domain,
    //         domainNameWithExtension: '',
    //         userId: token,
    //         planId: currentPlanID,
    //     };
        
    //     registerUserForWL({
    //         ...body,
    //         isTrialVersion: "0",
    //     }).then((_items) => {
    //         if (_items.resCode === 0) {

    //             acticatePlanTry({
    //                 userId: token,
    //                 language: i18n.language === "ar" ? "0" : "1",
    //             }).then((res) => {
    //                 if (res.resCode === 0) {
    //                     callback();
    //                     let path = "https://" + (domainNameWithoutExt || domain) + ".amakkn.com/minasati-step2/" + token;
    //                     window.location.replace(path);
    //                 } else {
    //                     toast.error(res.resStr);

    //                 }
    //             });
    //         } else {
    //             toast.error(_items.resStr);
    //         }
    //     });
    // }
    
    return data && data.length > 0 ? (
        <section>
            {!token ? (
                <>
                    <PageSection
                        withBG
                        withoutContainer
                        preTitle={t("PlansAndPackages")}
                        title={t("OptionsThatSuitYourBusiness")}
                        description={t("YouCanSubscribeToOneOfTheFollowingPlans")}
                    >
                        <PlansBox WLPlans={WLPlans} setSelectedWLPlan={setSelectedWLPlan} setIsTrial={setIsTrial} setStep={setStep} setOpenDrawer={setOpenDrawer} isUserVerified={isUserVerified} accountType={0} data={data} isFromWebview={isFromWebview} selectedPlan={selectedPlan} setSelectedPlan={setSelectedPlan} currentPlanID={currentPlanID} />
                    </PageSection>
                </>
            ) : AccountType && <PageSection
                fromWL
                preTitle={t("PlansAndPackages")}
                title={t("OptionsThatSuitYourBusiness")}
                description={t("YouCanUpgradeYourCurrentPlanAndEnjoyMoreFeatures")}
            >
                <PlansBox  isWLUser={isWLUser} upgradePlanFunc={upgradePlanFunc} onOK={onOK} fromWL={fromWL} WLPlans={WLPlans} currentPlan={currentPlan} setSelectedWLPlan={setSelectedWLPlan} setIsTrial={setIsTrial} setStep={setStep} setOpenDrawer={setOpenDrawer} isUserVerified={isUserVerified} accountType={AccountType} data={data} isFromWebview={isFromWebview} selectedPlan={selectedPlan} setSelectedPlan={setSelectedPlan} currentPlanID={currentPlanID} />
            </PageSection>
            }

            {/* <ChangeDomainDialog
                setDomainNameWithExt={setDomainNameWithExt}
                setDomainWithoutExt={setDomain}
                open={isOpenChangeDomainDialog}
                setOpen={setIsOpenChangeDomainDialog}
                oldDomain={domain}
                onOK={(withExt,withoutExt) => openConfirmTrailPopup(withExt || domainNameWithExt, user, registerAmakknDomain, openChangeDomainDialog,withoutExt)}
            /> */}
        </section>
    ) : <></>;
}