import React, { useEffect, useState } from "react";
import {
  Alert,
  AlertTitle,
  Box,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Pagination,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
  useMediaQuery,
} from "@mui/material";
import moment from "moment";
import { Close } from "@mui/icons-material";
import {
  changeOrderStatus,
  checkPayment,
  getUserPayments,
  saveOrder,
} from "../../../../api/generalAPI";
import { useAuth } from "../../../../helpers/context";
import {
  cancelUserPlan,
  cancelWLPlan,
  getNormalPlans,
  renew,
  setUserPlan,
} from "../../../../api/userApi";
import { toast } from "react-toastify";
import { openDeleteModal } from "../../../../helpers";
import NoData from "../../../../components/global-components/no-data";
import LoadingData from "../../../../components/global-components/loading-data";
import InfoIcon from "@mui/icons-material/Info";
import CloseIcon from "@mui/icons-material/Close";
import { useSearchParams } from "react-router-dom";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { GreenCheck, RedClose } from "../../../../constants/icons";
import { TRAIL_PLANS, plansFeatures } from "../../../../constants";
import { useTranslation } from "react-i18next";

const pageSize = 10;
const publicUrl = process.env.REACT_APP_URL + "/";

const MySubscription = (props) => {
  const {
    agentsLeft,
    name,
    allowedAgents,
    allowedPublishedProperties,
    propertiesLeft,
    nextPayment,
    endDate,
    planId,
    orderId,
    wlPlanId,
    isTrialVersion,
    lastPaymentFailed,
    canExportAdPhoto,
    // canAddExtraAds,
    permanentUpgradeToNumberOfAds,
    canAddNumber,
    hasSupportPriority,
    canAddLogo,
    duration,
    canAddVideo,
    canAddOnMap,
    featuredPropertiesLeft,
    planCustomers
  } = props?.data;
  const { user } = props;
  const [openPaymentsHistoryModal, setOpenPaymentsHistoryModal] =
    useState(false);
  const [loadingPaymentsHistory, setLoadingPaymentsHistory] = useState(false);
  const [payments, setPayments] = useState([]);
  const [paymentsTotalCount, setPaymentsTotalCount] = useState(0);
  const { token } = useAuth();
  const [page, setPage] = useState(1);
  const [openPaymentsDetailsModal, setOpenPaymentsDetailsModal] = useState({
    visible: false,
    content: "",
  });
  const [plans, setPlans] = useState([]);
  const [openMoyasarForm, setOpenMoyasarForm] = useState(false);
  const [searchParams] = useSearchParams();
  const [waitingResponse, setWaitingResponse] = useState(false);
  const { t, i18n } = useTranslation();
  const isLargeScreen = useMediaQuery((theme) => theme.breakpoints.up("sm"));

  useEffect(
    () => {
      window.scroll({ top: 0, left: 0, behavior: "smooth" });
      getUserPaymentsData("1");
      getNormalPlans({ language: i18n.language === "ar" ? "0" : "1" }).then(
        (res) => {
          setPlans(res.response);
        }
      );
    }, // eslint-disable-next-line
    []
  );

  const getUserPaymentsData = (page) => {
    setLoadingPaymentsHistory(true);
    getUserPayments({ userId: token, page: page, pageSize: pageSize })
      .then((res) => {
        if (res.resCode === 0) {
          setPayments(res.response.array);
          setPaymentsTotalCount(res.response.totalCount);
        }
        setLoadingPaymentsHistory(false);
      })
      .catch(() => {
        setLoadingPaymentsHistory(false);
      });
  };

  useEffect(
    () => {
      if (openMoyasarForm)
        setTimeout(() => {
          window?.Moyasar?.init({
            element: ".mysr-form",
            language: "ar",
            amount: nextPayment,
            currency: "SAR",
            description: `${t(
              "ManuallyRenewYourPlanSubscription"
            )} ${planId} | ${user?.name} | ${user?.phone} | ${user?.userId}`,
            publishable_api_key: process.env.REACT_APP_MOYASAR_KEY,
            // "pk_live_YyU1CG3AbLfLgAeMb4NetZzyFhYCFFPqVVBVe2wJ", //'pk_test_JiU5dzWs6Y2J1dz2BbLZdeEDVjDTws7tdh9vjxVZ',
            callback_url: wlPlanId
              ? `${publicUrl}my-subscription?planId=${planId || 0}&wlPlanId=${
                  wlPlanId || 0
                }`
              : `${publicUrl}my-subscription?planId=${planId || 0}`,
            methods: ["creditcard"],
            // apple_pay: {
            //   country: "SA",
            //   // label: `${t("ManuallyRenewYourPlanSubscription")}`,
            //   label: "Amakkn",
            //   display_name: "amakkn.com",
            //   domain_name: "amakkn.com",
            //   validate_merchant_url:
            //     "https://api.moyasar.com/v1/applepay/initiate",
            // },
            credit_card: {
              save_card: true,
            },
            on_completed: function (payment) {
            
              return new Promise(function (resolve, reject) {
                saveOrder({
                  paymentId: payment?.id,
                  paymentStatus: payment?.status,
                  paymentAmount: payment?.amount,
                  // "wlPlan": wlPlanId || undefined,
                  paymentAmountBeforeDiscount: +nextPayment ? +nextPayment : 0,
                  discountAmount: "-1",
                  promoCodeId: "-1",
                  paymentMessage: " ",
                  paymentToken: payment?.source?.token || "-1",
                  userId: token,
                  domain: "-1",
                  status: "InProgress",
                  language: i18n.language === "ar" ? "0" : "1",
                  isTrialVersion: isTrialVersion,
                  userPlanId: planId || undefined,
                })
                  .then((res) => {
                    resolve({ payment });
                  })
                  .catch((e) => {
                    toast.error(t("PaymentNotCompleted"));
                    reject();
                  });
              });
            },
          });
        }, 200);
    }, // eslint-disable-next-line
    [openMoyasarForm]
  );

  useEffect(
    () => {
      if (searchParams.get("id") && searchParams.get("planId")) {
        setWaitingResponse(true);
        let planId = searchParams.get("planId");
        // let wlPlanId = searchParams.get('wlPlanId');
        let paymentId = searchParams.get("id");

        checkPayment({ paymentId }).then((res) => {
          let data = res.response;
          if (res.resCode === 0) {
            changeOrderStatus({
              language: i18n.language === "ar" ? "0" : "1",
              paymentId: paymentId,
              paymentStatus: data.status,
              userId: token,
              status: data.status === "paid" ? "done" : "failed",
            });
            if (data.status === "paid") {
              setUserPlan({
                userId: token,
                planId: planId,
                isTrialVersion: "0",
              }).then((res) => {
                if (res.resCode === 0) {
                  setWaitingResponse(false);
                  // history(`/success-registration/${planName}/${wlPlanName}`);
                  Swal.fire({
                    title: t("RenewedSuccessfully"),
                    text: t("SubscriptionRenewedSuccessfully"),
                    icon: "success",
                    confirmButtonText: t("Close"),
                  });
                  props?.getUserPlanFunc();
                  // if (searchParams.get('wlPlanId')) {
                  //   subscribe({
                  //     userId: token,
                  //     planId: wlPlanId,
                  //     startDate: moment().locale("en").format("YYYY-MM-D"),
                  //     endDate: "",
                  //     // endDate: moment()
                  //     //   .locale("en")
                  //     //   .add(isTrial ?
                  //     //     (WLPlans.filter(i => +i.id === +wlPlanId)?.[0]?.trialDuration ? +WLPlans.filter(i => +i.id === +wlPlanId)?.[0]?.trialDuration :
                  //     //       +WLPlans.filter(i => +i.id === +wlPlanId)?.[0]?.duration) :
                  //     //     +WLPlans.filter(i => +i.id === +wlPlanId)?.[0]?.duration, "day")
                  //     //   .format("YYYY-MM-D"),
                  //     isTrialVersion: '0'
                  //   }).then((result) => {
                  //     if (result.resCode === 0) {
                  //       setWaitingResponse(false);
                  //       // history(`/success-registration/${planName}/${wlPlanName}`);
                  //       Swal.fire({
                  //         title: "تم التجديد بنجاح",
                  //         text: `تم تجديد الاشتراك بنجاح`,
                  //         icon: "success",
                  //         confirmButtonText: t("Close"),
                  //       });
                  //     } else {
                  //       setWaitingResponse(false);
                  //       toast.error(result.resStr);
                  //     }
                  //     props?.getUserPlanFunc();
                  //   }).catch(e => { setWaitingResponse(false); });
                  // } else {
                  //   // history(`/success-registration/${planName}`);
                  //   setWaitingResponse(false);
                  //   Swal.fire({
                  //     title: "تم التجديد بنجاح",
                  //     text: `تم تجديد الاشتراك بنجاح`,
                  //     icon: "success",
                  //     confirmButtonText: t("Close"),
                  //   });
                  //   props?.getUserPlanFunc();
                  // }
                } else {
                  toast.error(res.resStr);
                  setWaitingResponse(false);
                }
              });
            } else {
              setWaitingResponse(false);
              Swal.fire({
                title: t("NotSubscribed"),
                text: data.source.message,
                icon: "error",
                confirmButtonText: t("Close"),
              });
            }
          } else {
            setWaitingResponse(false);
            toast.error(res.resStr);
          }
        });
      }
    },
    // eslint-disable-next-line
    [searchParams]
  );

  const renderAlert = () => {
    return (
      <Alert
        severity={moment(endDate).diff(moment()) <= 0 ? "error" : "info"}
        className='info-alert mb-3'
        sx={{ "& .MuiAlert-message": { width: "100%" } }}>
        <AlertTitle
          sx={{
            width: "100%",
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "space-between",
          }}>
          <span>
            {t("NextPayment")} <br />
            <b>
              {nextPayment
                ? (parseFloat(nextPayment) / 100).toFixed(2) + ` ${t("SAR")}`
                : null}
            </b>
          </span>
          {!props?.userId && (
            <div
              className='d-flex my-2 mt-md-0 flex-column flex-lg-row'
              style={{ gap: 10 }}>
              <Tooltip
                title={
                  props?.NormalPlanCanceled === "1"
                    ? t("PlanAutoRenewalHasBeenCancelled")
                    : t("CancelAutoRenewalOfThePlan")
                }
                placement='top'>
                <button
                  className='primary-btn delete-btn'
                  disabled={props?.NormalPlanCanceled === "1"}
                  onClick={() => {
                    if (props?.NormalPlanCanceled === "0") {
                      openDeleteModal(
                        t("ConfirmCancellation"),
                        t(
                          "AreYouSureYouWantToCancelYourAutoRenewalSubscription"
                        ),
                        () => {
                          cancelUserPlan({
                            language: i18n.language === "ar" ? "0" : "1",
                            userId: token,
                          }).then((res) => {
                            if (res.resCode === 0) {
                              if ([9, 10, 11, 12].includes(+planId)) {
                                cancelWLPlan({
                                  language: i18n.language === "ar" ? "0" : "1",
                                  userId: token,
                                }).then((res) => {
                                  if (res.resCode === 0) {
                                    props?.setNormalPlanCanceled?.("1");
                                    toast.success(
                                      t("PlanAutoRenewalHasBeenCancelled")
                                    );
                                  } else toast.error(res.resStr);
                                });
                              } else {
                                props?.setNormalPlanCanceled?.("1");
                                toast.success(
                                  t("PlanAutoRenewalHasBeenCancelled")
                                );
                              }
                            } else toast.error(res.resStr);
                          });
                        },
                        t("Confirm")
                      );
                    }
                  }}
                  style={{ fontSize: "13px" }}>
                  {t("CancelAutoRenewal")}
                </button>
              </Tooltip>
              {lastPaymentFailed === "1" && (
                <button
                  className='primary-btn'
                  onClick={() => {
                    openDeleteModal(
                      t("ConfirmRenewal"),
                      t("AreYouSureYouWantToRenewYourSubscription"),
                      async () => {
                        await renew({
                          language: i18n.language === "ar" ? "0" : "1",
                          userId: token,
                          orderId: orderId,
                        }).then((res) => {
                          if (res.resCode === 0) {
                            toast.success(
                              t("YourSubscriptionHasBbeenSuccessfullyRenewed")
                            );
                          } else {
                            toast.error(res.resStr);
                            setOpenMoyasarForm(true);
                          }
                        });
                      },
                      t("Confirm")
                    );
                  }}
                  style={{ fontSize: "13px" }}>
                  {t("RenewNow")}
                </button>
              )}
            </div>
          )}
        </AlertTitle>
        {moment(endDate).diff(moment()) <= 0 ? (
          <div className='mt-3'>
            {t("PleaseNoteThatYourSubscriptionHasExpiredOnTheDate")}{" "}
            <strong>
              {moment(endDate).locale("en").format("YYYY-MM-DD HH:mm")}.
            </strong>{" "}
            <br /> {t("PleaseContactTechnicalSupportToRenewYourSubscription")}
          </div>
        ) : (
          <div className='mt-3'>
            {isTrialVersion === "1"
              ? t("PleaseNoteThatTheTrialPeriodWillExpireOnTheDate")
              : t("PleaseNoteThatYourSubscriptionWillExpireOnTheDate")}{" "}
            <strong>
              {moment(endDate).locale("en").format("YYYY-MM-DD HH:mm")}.
            </strong>{" "}
            <br />
            {props?.NormalPlanCanceled === "0" &&
              t("YourSubscriptionWillBeAutomaticallyRenewedOnThisDate")}
          </div>
        )}
        {props?.userId && (
          <div className='d-flex my-2 mt-md-2 flex-column' style={{ gap: 10 }}>
            <Tooltip
              title={
                props?.NormalPlanCanceled === "1"
                  ? t("PlanAutoRenewalHasBeenCancelled")
                  : t("CancelAutoRenewalOfThePlan")
              }
              placement='top'>
              <button
                className='primary-btn delete-btn'
                disabled={props?.NormalPlanCanceled === "1"}
                onClick={() => {
                  if (props?.NormalPlanCanceled === "0") {
                    openDeleteModal(
                      t("ConfirmCancellation"),
                      t("AreYouSureYouWantToCancelYourAutoRenewalSubscription"),
                      () => {
                        cancelUserPlan({
                          language: i18n.language === "ar" ? "0" : "1",
                          userId: token,
                        }).then((res) => {
                          if (res.resCode === 0) {
                            if ([9, 10, 11, 12].includes(+planId)) {
                              cancelWLPlan({
                                language: i18n.language === "ar" ? "0" : "1",
                                userId: token,
                              }).then((res) => {
                                if (res.resCode === 0) {
                                  props?.setNormalPlanCanceled?.("1");
                                  toast.success(
                                    t("PlanAutoRenewalHasBeenCancelled")
                                  );
                                } else toast.error(res.resStr);
                              });
                            } else {
                              props?.setNormalPlanCanceled?.("1");
                              toast.success(
                                t("PlanAutoRenewalHasBeenCancelled")
                              );
                            }
                          } else toast.error(res.resStr);
                        });
                      },
                      t("Confirm")
                    );
                  }
                }}
                style={{ fontSize: "13px" }}>
                {t("CancelAutoRenewal")}
              </button>
            </Tooltip>
            {lastPaymentFailed === "1" && (
              <button
                className='primary-btn'
                onClick={() => {
                  openDeleteModal(
                    t("ConfirmRenewal"),
                    t("AreYouSureYouWantToRenewYourSubscription"),
                    async () => {
                      await renew({
                        language: i18n.language === "ar" ? "0" : "1",
                        userId: token,
                        orderId: orderId,
                      }).then((res) => {
                        if (res.resCode === 0) {
                          toast.success(
                            t("YourSubscriptionHasBbeenSuccessfullyRenewed")
                          );
                        } else {
                          toast.error(res.resStr);
                          setOpenMoyasarForm(true);
                        }
                      });
                    },
                    t("Confirm")
                  );
                }}
                style={{ fontSize: "13px" }}>
                {t("RenewNow")}
              </button>
            )}
          </div>
        )}
      </Alert>
    );
  };

  return (
    <div
      style={{
        height: "100vh",
        overflowY: "auto",
        padding: "20px 10% 70px",
      }}>
      <div className='container'>
        <h4 className='page-heading'>
          {!props?.userId ? t("MySubscriptionPage") : ""}
        </h4>
        <div style={{ gap: 20 }} className='d-flex flex-column'>
          <div className='custom-card without-header'>
            <div className='card-body'>
              <div
                className='d-flex align-items-center justify-content-between mb-2'
                style={{ flexWrap: "wrap" }}>
                <h4>{t("CurrentPlan")}</h4>
                {![1, 4].includes(+planId) && paymentsTotalCount > 0 && (
                  <button
                    className='primary-btn'
                    onClick={() => {
                      setOpenPaymentsHistoryModal(true);
                    }}
                    style={{ fontSize: "13px" }}>
                    {t("ViewPaymentHistory")}
                  </button>
                )}
              </div>
              <p style={{ fontSize: 18 }}>
                {name}{" "}
                {![1, 4].includes(+planId)
                  ? +duration === 365
                    ? t("Annual")
                    : t("Monthly1")
                  : ""}
                {isTrialVersion === "1" ? t("TrialMode") : ""}
              </p>
              {!props?.userId && ![1, 4].includes(+planId) && renderAlert()}

              <div
                className='d-flex align-items-center justify-content-between statistics'
                style={{ gap: 18 }}>
                <div
                  className='mb-3 p-2 text-center'
                  style={{
                    border: "2px solid #E0E0E0",
                    borderRadius: 8,
                    minWidth: 170,
                  }}>
                  <Box sx={{ position: "relative", display: "inline-flex" }}>
                    <CircularProgress
                      size={95}
                      thickness={4}
                      sx={{
                        color:
                          +propertiesLeft === 0
                            ? "#d00404"
                            : +propertiesLeft >= +allowedPublishedProperties / 2
                            ? "#4dc247"
                            : "#faa603",
                      }}
                      variant='determinate'
                      value={
                        (100 / +allowedPublishedProperties) *
                        (+allowedPublishedProperties - +propertiesLeft)
                      }
                    />
                    <Box
                      sx={{
                        top: 0,
                        left: 0,
                        bottom: 0,
                        right: 0,
                        position: "absolute",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}>
                      <Typography
                        variant='caption'
                        component='div'
                        className='text-center'
                        color='text.secondary'>
                        {t("PublishedAD01")}
                        <br />
                        {t("PublishedAD02")}
                        <br />
                        {`${
                          +allowedPublishedProperties - +propertiesLeft
                        }/${+allowedPublishedProperties}`}
                      </Typography>
                    </Box>
                  </Box>
                  <p style={{ margin: 0, textAlign: "center" }}>{`${t(
                    "Remaining"
                  )}: ${propertiesLeft} ${t("AD")}`}</p>
                </div>
                {+allowedAgents !== 0 && (
                  <div
                    className='mb-3 p-2 text-center'
                    style={{
                      border: "2px solid #E0E0E0",
                      borderRadius: 8,
                      minWidth: 170,
                    }}>
                    <Box sx={{ position: "relative", display: "inline-flex" }}>
                      <CircularProgress
                        size={95}
                        thickness={4}
                        sx={{
                          color:
                            +agentsLeft === 0
                              ? "#d00404"
                              : +agentsLeft >= +allowedAgents / 2
                              ? "#4dc247"
                              : "#faa603",
                        }}
                        variant='determinate'
                        value={
                          (100 / +allowedAgents) *
                          (+allowedAgents - +agentsLeft)
                        }
                      />
                      <Box
                        sx={{
                          top: 0,
                          left: 0,
                          bottom: 0,
                          right: 0,
                          position: "absolute",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}>
                        <Typography
                          variant='caption'
                          component='div'
                          className='text-center'
                          color='text.secondary'>
                          {t("Agent")}
                          <br />
                          {`${+allowedAgents - +agentsLeft}/${+allowedAgents}`}
                        </Typography>
                      </Box>
                    </Box>

                    <p style={{ margin: 0, textAlign: "center" }}>{`${t(
                      "Remaining"
                    )}: ${agentsLeft} ${t("Account")}`}</p>
                  </div>
                )}
                {+permanentUpgradeToNumberOfAds !== 0 && (
                  <div
                    className='mb-3 p-2 text-center'
                    style={{
                      border: "2px solid #E0E0E0",
                      borderRadius: 8,
                      minWidth: 170,
                    }}>
                    <Box sx={{ position: "relative", display: "inline-flex" }}>
                      <CircularProgress
                        size={95}
                        thickness={4}
                        sx={{
                          color:
                            +featuredPropertiesLeft === 0
                              ? "#d00404"
                              : +featuredPropertiesLeft >=
                                +permanentUpgradeToNumberOfAds / 2
                              ? "#4dc247"
                              : "#faa603",
                        }}
                        variant='determinate'
                        value={
                          (100 / +permanentUpgradeToNumberOfAds) *
                          (+permanentUpgradeToNumberOfAds -
                            +featuredPropertiesLeft)
                        }
                      />
                      <Box
                        sx={{
                          top: 0,
                          left: 0,
                          bottom: 0,
                          right: 0,
                          position: "absolute",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}>
                        <Typography
                          variant='caption'
                          component='div'
                          className='text-center'
                          color='text.secondary'>
                          {t("FeaturedAD01")}
                          <br />
                          {t("FeaturedAD02")}
                          <br />
                          {`${
                            +permanentUpgradeToNumberOfAds -
                            +featuredPropertiesLeft
                          }/${+permanentUpgradeToNumberOfAds}`}
                        </Typography>
                      </Box>
                    </Box>

                    <p style={{ margin: 0, textAlign: "center" }}>{`${t(
                      "Remaining"
                    )}: ${featuredPropertiesLeft} ${t("AD")}`}</p>
                  </div>
                )}
              </div>

              <hr />
              <p style={{ fontSize: 18, marginBottom: 15, fontWeight: 600 }}>
                {t("DetailedFeaturesOfThePlan")}
              </p>
              <p className='pricing-card-feature'>
                <span className='icon'>
                  <GreenCheck green />
                </span>
                <span>
                  {" "}
                  {t("AbilityToAddADs", { number: allowedPublishedProperties })}
                </span>
                <span>
                  <Tooltip
                    title={
                      <div>
                        {t(
                          "YouCanAddUnlimitedNumberOfRealEstateADsButYouCanPublish"
                        )}{" "}
                        {" " + allowedPublishedProperties + " "}{" "}
                        {t("RealEstateAD1") + " "+t("AtOnce")}
                      </div>
                    }>
                    <InfoIcon
                      sx={{
                        cursor: "pointer",
                        position: "relative",
                        top: "2px",
                        fontSize: "16px",
                        color: "var(--main-color-one)",
                      }}
                    />
                  </Tooltip>
                </span>
              </p>
              <p className='pricing-card-feature'>
                <span className='icon'>
                  {allowedAgents !== "0" ? <GreenCheck green /> : <RedClose />}
                </span>
                <span>
                  {allowedAgents !== "0"
                    ? t("AbilityToAddAgents1", { number: allowedAgents })
                    : t("AbilityToAddAgents")}{" "}
                </span>
              </p>
              <p className='pricing-card-feature'>
                <span className='icon'>
                  {hasSupportPriority === "1" ? (
                    <GreenCheck green />
                  ) : (
                    <RedClose />
                  )}
                </span>
                <span>{t("TechnicalSupportPriority")}</span>
              </p>
              <p className='pricing-card-feature'>
                <span className='icon'>
                  {canAddLogo === "1" ? <GreenCheck green /> : <RedClose />}
                </span>
                <span>{t("AbilityToAddTheCompanyLogoToTheADImages")}</span>
              </p>
              <p className='pricing-card-feature'>
                <span className='icon'>
                  {canAddNumber === "1" ? <GreenCheck green /> : <RedClose />}
                </span>
                <span>{t("AbilityToAddTheUnifiedContactNumber")}</span>
              </p>
              <p className='pricing-card-feature'>
                <span className='icon'>
                  {canAddOnMap === "1" ? <GreenCheck green /> : <RedClose />}
                </span>
                <span>
                  {t("AbilityToAddTheLocationToTheMapOnTheCompanyPage")}
                </span>
              </p>
              <p className='pricing-card-feature'>
                <span className='icon'>
                  {canAddVideo === "1" ? <GreenCheck green /> : <RedClose />}
                </span>
                <span>{t("AbilityToAddVideosToTheRealEstateAD")}</span>
              </p>
              {/* <p className='pricing-card-feature'>
                <span className='icon'>
                  {canAddExtraAds === "1" ? <GreenCheck green /> : <RedClose />}
                </span>
                <span>{t("AbilityToAddAdditionalADsFor20SARPerAD")}</span>
              </p> */}
              <p className='pricing-card-feature'>
                <span className='icon'>
                  {canExportAdPhoto === "1" ? (
                    <GreenCheck green />
                  ) : (
                    <RedClose />
                  )}
                </span>
                <span>
                  {t("PrintableAndShareableImageOfRealEstateListingDetails")}
                </span>
              </p>
              <p className='pricing-card-feature'>
                <span className='icon'>
                  {permanentUpgradeToNumberOfAds > "0" ? (
                    <GreenCheck green />
                  ) : (
                    <RedClose />
                  )}
                </span>
                <span>
                  {+permanentUpgradeToNumberOfAds > 0
                    ? `${t("AbilityToFeature")} ${
                        +permanentUpgradeToNumberOfAds > 2
                          ? `${permanentUpgradeToNumberOfAds} ${t(
                              "RealEstateAds"
                            )}`
                          : t("OnlyOneRealEstateAD")
                      }`
                    : t("AbilityToFeatureRealEstateADs")}
                </span>
              </p>
              <hr />
              <p style={{ fontSize: 18, marginBottom: 15, fontWeight: 600 }}>
                {t("AmakknProServiceFeatures")}
              </p>
              {TRAIL_PLANS.includes(+planId) ? (
                <>
                  {plansFeatures?.[
                    +planId === 9
                      ? 7
                      : +planId === 10
                      ? 6
                      : +planId === 11
                      ? 9
                      : 8
                  ]?.map((f, i) => (
                    <p key={i} className='pricing-card-feature'>
                      <span className='icon'>
                        {f.available === "1" ? (
                          <GreenCheck green />
                        ) : (
                          <RedClose />
                        )}
                      </span>
                      <span>{f.name === "CRM2" ? t(f.name, { num: Intl.NumberFormat('en').format(planCustomers) }) : t(f.name)}</span>

                    </p>
                  ))}
                </>
              ) : (
                <>
                  <p className='pricing-card-feature'>
                    <span className='icon'>{<RedClose />}</span>
                    <span>{t("NotAvailable")}</span>
                  </p>
                </>
              )}
              {props?.userId && ![1, 4].includes(+planId) && renderAlert()}
            </div>
          </div>
        </div>
      </div>
      <Dialog
        onClose={() => {
          setOpenPaymentsHistoryModal(false);
        }}
        maxWidth={"lg"}
        className={`custom-dialog ${
          searchParams.get("dark") === "true" ? "dark" : ""
        }`}
        style={{ zIndex: 9999999 }}
        sx={{
          "& .MuiPaper-root": {
            margin: 0,
            width: "calc(100% - 20px)",
            zIndex: 9999999,
          },
        }}
        open={openPaymentsHistoryModal}>
        <DialogTitle>
          <div className='dialog-head'>
            <div>
              <h2>{t("PaymentHistory")}</h2>
            </div>
            <div className='dialog-actions'>
              <span
                onClick={() => setOpenPaymentsHistoryModal(false)}
                style={{ cursor: "pointer" }}>
                <Close />
              </span>
            </div>
          </div>
        </DialogTitle>
        <DialogContent>
          {isLargeScreen ? (
            <div
              style={{
                display: "flex",
                height: "400px",
                width: "100%",
                flexDirection: "column",
              }}>
              <TableContainer
                className='table-container responsive-table'
                component={Paper}>
                <Table sx={{ minWidth: "100%" }}>
                  <TableHead>
                    <TableRow>
                      <TableCell align='right'>{t("PaymentID")}</TableCell>
                      <TableCell align='right'>{t("PaymentStatus")}</TableCell>
                      <TableCell align='right'>{t("PaymentAmount")}</TableCell>
                      <TableCell align='right'>{t("PaymentDate")}</TableCell>
                      <TableCell align='right'>
                        {t("AdditionalDetails")}
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {loadingPaymentsHistory ? (
                      <TableRow
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}>
                        <TableCell colSpan={5}>
                          <LoadingData />
                        </TableCell>
                      </TableRow>
                    ) : payments?.length > 0 ? (
                      payments.map((row) => (
                        <TableRow
                          key={row.paymentId}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                            "& td, & th": { pt: 0.7, pb: 0.7 },
                          }}>
                          <TableCell align='right' component='th' scope='row'>
                            {row.paymentId?.slice(-10)}
                          </TableCell>
                          <TableCell
                            align='right'
                            style={{
                              color:
                                row.paymentStatus === "paid"
                                  ? "green"
                                  : row.paymentStatus === "failed"
                                  ? "#d00404"
                                  : "orange",
                            }}>
                            {row.paymentStatus === "paid"
                              ? t("Paid")
                              : row.paymentStatus === "failed"
                              ? t("Failed")
                              : t("InProgress1")}
                          </TableCell>
                          <TableCell align='right'>
                            {(+row.paymentAmout / 100).toFixed(2) +
                              ` ${t("SAR")}`}
                          </TableCell>
                          <TableCell align='right'>{row.createdAt}</TableCell>
                          <TableCell align='right'>
                            <button
                              onClick={() =>
                                setOpenPaymentsDetailsModal({
                                  visible: true,
                                  content: (
                                    <>
                                      {row.domain !== "-1" && (
                                        <p>
                                          <b>{t("ReserveTheDomain")}: </b>
                                          {row.domain}
                                        </p>
                                      )}
                                      {row.normal_plan !== "" && (
                                        <p>
                                          <b>{t("SubscribeToThePlan")}: </b>
                                          {
                                            plans.filter(
                                              (i) => +i.id === +row.normal_plan
                                            )?.[0]?.name
                                          }
                                        </p>
                                      )}
                                      {/* {row.plan?.plan?.name !== '' && <p><b>الاشتراك في خطة أماكن برو: </b>{row.plan?.plan?.name}</p>} */}
                                    </>
                                  ),
                                })
                              }
                              className='custom-btn custom-btn2 btn-info-bg btn-white-bg text-info'>
                              <InfoIcon />
                            </button>
                          </TableCell>
                        </TableRow>
                      ))
                    ) : (
                      <TableRow
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}>
                        <TableCell colSpan={5}>
                          <NoData msg={t("ThereAreNoPreviousPaymentsForYou")} />
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          ) : (
            <>
              {loadingPaymentsHistory ? (
                <LoadingData />
              ) : payments?.length > 0 ? (
                payments.map((row, index) => (
                  <Card className='pricing-card mb-4' key={index}>
                    <span className='circle circle-1'></span>
                    <span className='circle circle-2'></span>
                    <CardHeader className='pricing-card-header'></CardHeader>
                    <CardContent sx={{ minHeight: 210 }}>
                      <Box px={1}>
                        <p>
                          <b>{t("PaymentID")}:</b> {row.paymentId?.slice(-10)}
                        </p>
                        <p>
                          <b>{t("PaymentStatus")}:</b>{" "}
                          <span
                            style={{
                              color:
                                row.paymentStatus === "paid"
                                  ? "green"
                                  : row.paymentStatus === "failed"
                                  ? "#d00404"
                                  : "orange",
                            }}>
                            {row.paymentStatus === "paid"
                              ? t("Paid")
                              : row.paymentStatus === "failed"
                              ? t("Failed")
                              : t("InProgress1")}
                          </span>
                        </p>
                        <p>
                          <b>{t("PaymentAmount")}:</b>{" "}
                          {(+row.paymentAmout / 100).toFixed(2) +
                            ` ${t("SAR")}`}
                        </p>
                        <p>
                          <b>{t("PaymentDate")}:</b> {row.createdAt}
                        </p>
                        <p>
                          <b>{t("AdditionalDetails")}:</b>
                          {row.domain !== "-1" && (
                            <span>
                              &nbsp;{t("ReserveTheDomain")} /{row.domain}/&nbsp;
                              -
                            </span>
                          )}
                          {row.normal_plan !== "" && (
                            <span>
                              &nbsp;{t("SubscribeToThePlan")} /
                              {
                                plans.filter(
                                  (i) => +i.id === +row.normal_plan
                                )?.[0]?.name
                              }
                              /
                            </span>
                          )}
                        </p>
                      </Box>
                    </CardContent>
                  </Card>
                ))
              ) : (
                <NoData msg={t("ThereAreNoPreviousPaymentsForYou")} />
              )}
            </>
          )}

          {paymentsTotalCount > pageSize && (
            <Pagination
              sx={{
                "& .MuiPagination-ul": {
                  flexDirection: "row",
                  marginTop: "25px",
                },
              }}
              count={parseInt(Math.ceil(paymentsTotalCount / pageSize))}
              page={page}
              onChange={(event, value) => {
                setPage(+value);
                getUserPaymentsData(value);
              }}
              color='primary'
            />
          )}
        </DialogContent>
      </Dialog>

      <Dialog
        onClose={() =>
          setOpenPaymentsDetailsModal({ visible: false, content: "" })
        }
        maxWidth={"sm"}
        className='custom-dialog'
        style={{ zIndex: 9999999 }}
        sx={{
          "& .MuiPaper-root": {
            margin: 0,
            width: "calc(100% - 20px)",
            zIndex: 9999999,
          },
        }}
        open={openPaymentsDetailsModal.visible}>
        <DialogTitle>
          <div className='dialog-head'>
            <div>
              <h2>{t("AdditionalDetails")}</h2>
            </div>
            <div className='dialog-actions'>
              <span
                onClick={() =>
                  setOpenPaymentsDetailsModal({ visible: false, content: "" })
                }
                style={{ cursor: "pointer" }}>
                <Close />
              </span>
            </div>
          </div>
        </DialogTitle>
        <DialogContent>
          <div
            style={{
              display: "flex",
              height: "150px",
              width: "100%",
              flexDirection: "column",
            }}>
            {openPaymentsDetailsModal.content}
          </div>
        </DialogContent>
      </Dialog>

      <Dialog
        onClose={() => {
          setOpenMoyasarForm(false);
        }}
        maxWidth={"sm"}
        className='custom-dialog'
        style={{ zIndex: 9999999 }}
        sx={{
          "& .MuiPaper-root": {
            margin: 0,
            width: "calc(100% - 20px)",
            zIndex: 9999999,
          },
        }}
        open={openMoyasarForm}>
        <DialogTitle>
          <div className='dialog-head mb-4'>
            <div className='dialog-actions close-only'>
              <IconButton
                aria-label='close'
                onClick={() => {
                  setOpenMoyasarForm(false);
                }}
                sx={{
                  color: (theme) => theme.palette.grey[500],
                }}>
                <CloseIcon />
              </IconButton>
            </div>
          </div>
        </DialogTitle>
        <DialogContent>
          <Alert
            severity='info'
            style={{
              maxWidth: "340px",
              margin: "0 auto 20px",
            }}
            sx={{
              "& .MuiAlert-icon": {
                marginInlineStart: 0,
                marginInlineEnd: "5px",
              },
              "& .MuiAlert-message": {
                padding: "2px 0",
              },
            }}>
            {t("YourSubscriptionRenewalProcessWasUnsuccessful")}
          </Alert>
          <div className='mysr-form'></div>
        </DialogContent>
      </Dialog>
      {waitingResponse && (
        <div className='progress-area'>
          <h3>{t("PleaseWait")}</h3>
          <CircularProgress size={100} sx={{ color: "#fff" }} />
        </div>
      )}
    </div>
  );
};

export default MySubscription;
