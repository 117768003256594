import React from "react";
import { Link } from "react-router-dom";
import { Check } from "../../constants/icons";
import { useTranslation } from "react-i18next";
import { getLocalizedText } from "../../helpers";

const publicUrl = process.env.REACT_APP_URL + "/";

const ProjectBox = ({ selectedProjectsIDs, project, disableLink }) => {
  const { i18n } = useTranslation();
  let path = "/project/";
  const item = project;
  const selected = selectedProjectsIDs && selectedProjectsIDs.filter(i => i === item.id).length > 0;
  return (
    <div className='single-project single-leading-feature'>
      <div className='slf-overlay' />

      {selected && <div className="selected-wrapper">
        <Check />
      </div>}
  
      {disableLink ? (
        <div className='thumb'>
          <img
            src={item.images.length > 0 ? item.images[0] : publicUrl + "assets/img/defimgs/1.svg"}
            alt={getLocalizedText(item.name, i18n.language, false)}
            className={item.images.length > 0 ? "" : "contain-img"}
          />
        </div>
      ) : (
        <Link to={path + item.id}>
          <div className='thumb'>
            <img
              src={item.images.length > 0 ? item.images[0] : publicUrl + "assets/img/defimgs/1.svg"}
              alt={getLocalizedText(item.name, i18n.language, false)}
              className={item.images.length > 0 ? "" : "contain-img"}
            />
          </div>
        </Link>
      )}
      <div className='details'>
        {disableLink ? (
          <>
            <h4 className='title property-name'>
              {getLocalizedText(item.name, i18n.language, false)}
            </h4>
            <h5 className='price'>{item.address}</h5>
          </>
        ) : (
          <Link to={path + item.id}>
            <h4 className='title property-name'>
              {getLocalizedText(item.name, i18n.language, false)}
            </h4>
            <h5 className='price'>{item.address}</h5>
          </Link>
        )}
    
      </div>
     
    </div>
  );
};

export default ProjectBox;
