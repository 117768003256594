import TextField from "@mui/material/TextField";
import React from "react";
import { useState } from "react";
import LoadingButton from "../global-components/loading-btn";
import { MODERN_TEMPLATES } from "../../constants";
import { useAuth } from "../../helpers/context";
import { getVideoId } from "../../helpers";
import YoutubeDialog from "../youtube-dialog";
import { useTranslation } from "react-i18next";
import { Alert, Box, FormHelperText } from "@mui/material";

const YoutubeUrlRegx = /^(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/watch\?v=([a-zA-Z0-9_]+)|youtu\.be\/([a-zA-Z\d_]+))(?:&.*)?$/;

const YoutubeSection = (props) => {
  const [Data, setData] = useState(props.data);
  const { templateId } = useAuth();
  const { t } = useTranslation();

  return (
    <div className={`youtube-area ${MODERN_TEMPLATES.includes(+templateId) ? 'h-100' : ''}`}>
      <div className={`row ${MODERN_TEMPLATES.includes(+templateId) ? 'h-100' : ''} m-0`}>
        <div className={`${props?.withoutAboutData ? 'col-lg-8 mx-auto' : ''} col-12 ${props.isEdit ? '' : 'p-0 m-0 text-center'}`} style={{ height: MODERN_TEMPLATES.includes(+templateId) ? '100%' : 'auto' }}>
          {props.isEdit ? (
            <>
              <TextField
                label={t("YouTubeVideoLink")}
                variant='outlined'
                InputLabelProps={{ shrink: true }}
                placeholder="https://www.youtube.com/embed/T2jKJF4BZOY"
                fullWidth
                className='mt-4'
                value={Data.link}
                onChange={(v) => {
                  setData({ ...Data, link: v.currentTarget.value });
                }}
                error={Data.link && !YoutubeUrlRegx.test(Data.link)}
              />
              {Data.link && !YoutubeUrlRegx.test(Data.link) && (
                <FormHelperText component={Box}>
                  <Alert severity="error" className="custom-alert">
                    {t('EnterAValidURL')}
                  </Alert>
                </FormHelperText>
              )}

              {Data.link && getVideoId(Data.link) && <iframe
                src={`https://www.youtube.com/embed/${getVideoId(Data.link)}`}
                title="youtube"
                style={{
                  width: '100%',
                  height: '450px',
                  marginTop: '20px'
                }} >
              </iframe>}
              <div className='mt-3 '>
                <LoadingButton
                  classes="primary-btn px-3 m-auto"
              
                  handleClick={(e) => {
                    if ((Data.link && YoutubeUrlRegx.test(Data.link)) || !Data.link)
                      props.saveDetails({ data: Data })
                  }}
                  label={t("SaveChanges")}
                  loading={props.loading}
                />
              </div>
            </>
          ) : MODERN_TEMPLATES.includes(+templateId) ?
            <div className="video-bg" style={{ right: props?.withoutAboutData ? 0 : '', minHeight: props?.withoutAboutData ? '375px' : '125px', background: 'url(/assets/img/banner/broker/2.png) no-repeat center', backgroundSize: 'cover' }}>
              <YoutubeDialog
                position={1}
                youtubeLink={Data.link}
              />
            </div>
            :
            <iframe
              src={`https://www.youtube.com/embed/${getVideoId(Data.link)}`}
              title="youtube"
              className="youtube-iframe"
            >
            </iframe>
          }
        </div>
      </div>
    </div>
  );
}

export default YoutubeSection;
