import React from "react";
import { useTranslation } from "react-i18next";
import { removeZeroFromNumberIfExists, resolveInstagramLink, resolveSnapChatLink, resolveTwitterLink, resolveWhatsAppLink } from "../../../helpers";
import { Instagram, WhatsApp } from "@mui/icons-material";
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import SwiperCore, { Autoplay, Keyboard } from "swiper";
import { Swiper, SwiperSlide } from 'swiper/react';
import { SnapChat, Twitter } from "../../../constants/icons";
import { useMediaQuery } from "@mui/material";

SwiperCore.use([Autoplay]);
const publicUrl = process.env.REACT_APP_URL + "/";

const T04OurTeam = ({ data, team }) => {
  const { t, i18n } = useTranslation();
  const isLargeScreen = useMediaQuery((theme) => theme.breakpoints.up("sm"));

  const renderTeamMember = (item, i) => {
    return (
      <div className="team-box" data-aos="fade-up" data-aos-duration="1500" data-aos-delay={300*i}>
        <div className="team-box-item shine-animate-item">
          <div className="team-box-thumb shine-animate">
            <img src={item.icon || `${publicUrl}assets/img/banner/2b.png`} alt={item.firstName} style={{ objectPosition: item.icon ? '50% 50%' : '0 0' }} />
          </div>
          <div>
            <h4 className="title">{item.firstName}</h4>
            <span>{item.lastName}</span>
            <div className="team-box-social">
              <ul className="list-wrap">
                {item.whatsapp &&
                  <li>
                    <a target={isLargeScreen ? '_blank' : "_self"} rel="noreferrer" href={resolveWhatsAppLink(isLargeScreen,removeZeroFromNumberIfExists(item.whatsapp))}><WhatsApp /></a>
                  </li>
                }
                <li>
                  <a target={isLargeScreen ? '_blank' : "_self"} rel="noreferrer" className="no-color" href={`tel:${removeZeroFromNumberIfExists(item.phoneNumber)}`}><LocalPhoneIcon /></a>
                </li>

                {item.snapchat && <li>
                  <a href={resolveSnapChatLink(isLargeScreen,item.snapchat)} rel="noreferrer" target={isLargeScreen ? '_blank' : "_self"}><SnapChat /></a>
                </li>
                }

                {item.twitter && <li>
                  <a href={resolveTwitterLink(isLargeScreen,item.twitter)} rel="noreferrer" target={isLargeScreen ? '_blank' : "_self"}><Twitter medium /></a>
                </li>
                }

                {item.instagram && <li>
                  <a href={resolveInstagramLink(isLargeScreen, item.instagram)} rel="noreferrer" target={isLargeScreen ? '_blank' : "_self"}><Instagram /></a>
                </li>
                }
              </ul>
            </div>
          </div>
        </div>
      </div>
    );
  }
    
  return (
    <div className="col-12 justify-content-center align-items-center flex-column d-flex" >
      <div className="row mx-0 w-100">
        <div className="col-12 col-lg-5 d-flex flex-column">
          <span className="section-prefix tag" data-aos="fade-left" data-aos-duration="1500">{t("Team")}</span>
          <h2 className='sec-title text-start' data-aos="fade-up" data-aos-duration="1500">{t("WePresentToYouOurDistinguishedTeam")}</h2>
        </div>
        <div className={`col-12 col-lg-7 ${data.title ? 'border-lg-start' : ''}`} data-aos="fade-right" data-aos-duration="1500">
          <p className="sec-desc text-start">{data.title}</p>
        </div>
      </div>
         
      {team.length > 3 ? (
        <Swiper
          className={`team-slider mt-4  p-0 template-3__team-boxes`}
          spaceBetween={40}
          key={i18n.language + 'ii'}
          slidesPerView={team.length >= 3 ? 3 : team.length}
          autoplay={{
            delay: 2000,
            pauseOnMouseEnter: false,
            disableOnInteraction: false,
          }}
          modules={[Keyboard]}
          keyboard={{ enabled: true }}
          loop={true}
          speed={2400}
          breakpoints={{
            320: {
              slidesPerView: 1,
              spaceBetween: 20,
            },
            575: {
              slidesPerView: 1,
              spaceBetween: 20,
            },
            600: {
              slidesPerView: 2,
              spaceBetween: 20,
            }, 1100: {
              slidesPerView: 3,
              spaceBetween: 20,
            },
          }}
        >
          {team.map((item, i) => (
            <SwiperSlide key={i} >
              {renderTeamMember(item, i)}
            </SwiperSlide>
          ))}
        </Swiper>
      ) : (
        <div className='col-12 team-boxes'>
          {team.map((item, i) => renderTeamMember(item, i))}
        </div>
      )}
      <div>
      </div>
    </div>
  );
}

export default T04OurTeam;
