import { Box, ClickAwayListener, FormControl, Grow, MenuItem, Paper, Popper, Select } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAuth } from "../../../../../helpers/context";
import LoadingData from "../../../../../components/global-components/loading-data";
import NoData from "../../../../../components/global-components/no-data";
import LoadingButton from "../../../../../components/global-components/loading-btn";
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import { getBargainsCount } from "../../../../../api/reports-api";
import { toast } from "react-toastify";
import { getAgentsForCompany, getUserProile } from "../../../../../api/userApi";
import TuneIcon from '@mui/icons-material/Tune';
import * as XlsxPopulate from "xlsx-populate/browser/xlsx-populate";
import { Bar, BarChart, CartesianGrid, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";

const DealsPerStageReport = () => {
    const [data, setData] = useState([]);
    const [loadingData, setLoadingData] = useState(true);
    const [isExportingData, setIsExportingData] = useState(false);
    const { token, AccountType, UserType, UserId } = useAuth();
    const { t, i18n } = useTranslation();
    const [agents, setAgents] = useState([]);
    const [userName, setUserName] = useState("");
    const [companyName, setCompanyName] = useState("");
    const [openPopper, setOpenPopper] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const [filters, setFilters] = useState({
        added_by: "all"
    });
    
    const getData = () => {
        setLoadingData(true);
        getBargainsCount({
            userId: token,
            language: i18n.language === 'ar' ? '0' : '1',
            added_by: filters.added_by === "all" ? undefined : filters.added_by
        }).then((res) => {
            if (res.resCode === 0) {
                setData([
                    {
                        name: t('NewDeal'),
                        value: res.response?.[0] || 0
                    },
                    {
                        name: t('Negotiations'),
                        value: res.response?.[1] || 0
                    },
                    {
                        name: t('AdvancedNegotiations'),
                        value: res.response?.[2] || 0
                    },
                    {
                        name: t('DealClosing'),
                        value: res.response?.[3] || 0
                    },
                    {
                        name: t('DealLosing'),
                        value: res.response?.[4] || 0
                    }
                ]);
         
            } else {
                toast.error(res.resStr);
            }
        }).finally(() => setLoadingData(false));
    };

    console.log(data)

    useEffect(() => {
        if (token && AccountType === "2" && UserType !== "5") {
            getUserProile({ userId: token, language: i18n.language === 'ar' ? '0' : '1' }).then(user => {
                if (user.resCode === 0) {
                    setUserName(user.response.userProfile.name);
                    setCompanyName(user.response.userProfile.companyName);
                }
            });
            getAgentsForCompany({ superUserId: token, language: i18n.language === 'ar' ? '0' : '1' }).then((users) => {
                setAgents(users.response.userArray);
            });
        }
      
    },// eslint-disable-next-line
        [token, AccountType, i18n.language]);
  
    const exportToExcel = (data) => {
        setIsExportingData(true);
        return new Promise(function (resolve, reject) {
            var req = new XMLHttpRequest();
            var url = 'https://amakkn-ios.s3.us-east-1.amazonaws.com/DEALS_PER_STAGE.xlsx';
            req.open("GET", url, true);
            req.responseType = "arraybuffer";
            req.onreadystatechange = function () {
                if (req.readyState === 4) {
                    if (req.status === 200) {
                        resolve(XlsxPopulate.fromDataAsync(req.response));
                    } else {
                        reject("Received a " + req.status + " HTTP code.");
                    }
                }
            };
            req.send();
        })
            .then(function (workbook) {
     
                workbook.sheet(0).cell('A2').value(`${t("DealsPerStage")}`);
                workbook.sheet(0).cell('A7').value(`${data?.[0]?.value}`);
                workbook.sheet(0).cell('B7').value(`${data?.[1]?.value}`);
                workbook.sheet(0).cell('C7').value(`${data?.[2]?.value}`);
                workbook.sheet(0).cell('D7').value(`${data?.[3]?.value}`);
                workbook.sheet(0).cell('E7').value(`${data?.[4]?.value}`);
       
                return workbook.outputAsync();
            })
            .then(function (blob) {
                var url = window.URL.createObjectURL(blob);
                var a = document.createElement("a");
                document.body.appendChild(a);
                a.href = url;
                a.download = `${t("DealsPerStage")}.xlsx`;
                setIsExportingData(false);
                a.click();
                window.URL.revokeObjectURL(url);
                document.body.removeChild(a);
                toast.success(t("TheFileWasExportedSuccessfully"));
            })
            .catch(function (err) {
                console.log(err);
                toast.error(t("TheFileWasNotExported"));
                setIsExportingData(false);
            });
    };

    const hasFilter = () => filters.added_by !== "all";

    const renderFilterationBox = () => {
        return (
            <Box sx={{ backgroundColor: '#fff', width: '300px', display: 'flex', flexDirection: 'column', px: 2, pt: 2 }}>
                <div className="filters-box" style={{ height: 90 }}>
         
  
                    {AccountType === '2' && agents?.length > 0 && UserType !== "5" && (
                        <>
                            <h6 className="mt-2">{t("AddedBy")}</h6>
                            <div className="col-12 mb-3 p-0">
                                <FormControl className="custom-select">
                                    <Select
                                        value={filters.added_by}
                                        sx={{ width: "100%" }}
                                        inputProps={{
                                            name: "agentId",
                                            id: "agentId",
                                        }}
                                        onChange={(event) => {
                                            setFilters({
                                                ...filters,
                                                added_by: event.target.value,
                                            });
                                        }}
                                    >
                                        <MenuItem value='all' key="all">{t("All")}</MenuItem>
                                        <MenuItem value={UserId} key="mother-account">{userName === companyName ? t("MainAccount") : userName}</MenuItem>
                                        {agents?.map(agent => <MenuItem value={agent.userId} key={agent.userId}>{agent.name}</MenuItem>)}
                                    </Select>
                                </FormControl>
                            </div>
                        </>
                    )}
         
                </div>
                <div className="col-12 mb-3 p-0 mt-3 mx-0 row justify-content-center" style={{ gap: 15 }}>
         
                    <button
                        className={`btn action-btn primary-btn`}
                        onClick={() => {
                            setFilters({
                                status: 'all',
                                region: '',
                                city: '',
                                neighbourhood: [],
                                added_by: "all",
                                updatedAt: 'all'
                            });
                        }
                        }
                    >{t("Reset")}</button>
                </div>
            </Box>
        );
    }
    
    useEffect(() => {
        getData();
    },// eslint-disable-next-line
        [token, filters, UserType, i18n.language]);
  
    return (
        <>
            <div className="custom-card with-switcher">
                <div className="card-header flex-column flex-sm-row">
                    <div>
                        <h6>{t("DealsPerStage")}</h6>
                    </div>
                    <div className="d-flex align-items-center" style={{ gap: '5px' }}>
                        {data &&
                            <button className={`btn ${hasFilter() ? 'primary-btn' : 'outlined-btn'}`} style={{ width: '20%', minWidth: 120, maxWidth: 140 }} onClick={(e) => {
                                setOpenPopper(!openPopper);
                                setAnchorEl(anchorEl ? null : e.target);
                            }}>
                                {t("Filter")} <TuneIcon />
                            </button>}
                        {data && <LoadingButton
                            loading={isExportingData}
                            classes="primary-btn btn export-btn px-3 py-3"
                            style={{ width: 'fit-content' }}
                            handleClick={() => exportToExcel(data)}
                            label={t("ExportToExcel")}
                            icon={<InsertDriveFileIcon />}
                        />}
      
                    </div>
                </div>
      
                <div className="card-body">
        
                    <Popper
                        sx={{
                            zIndex: 100,
                            offset: '55px 0 0 5px',
                        }}
                        open={openPopper}
                        role={undefined}
                        transition
                        anchorEl={anchorEl}
                        disablePortal={false}
                    >
                        {({ TransitionProps, placement }) => (
                            <Grow
                                {...TransitionProps}
                                style={{
                                    transformOrigin:
                                        placement === 'bottom' ? 'center top' : 'center bottom',
                                }}
                            >
                                <Paper>
                                    <ClickAwayListener onClickAway={(event) => {
                                        if (event.target.nodeName === 'BODY' && event.type === 'click') {
                                            return;
                                        } else {
                                            setAnchorEl(null); setOpenPopper(false);
                                        }
                       
                                    }}>
                                        {renderFilterationBox()}
                                    </ClickAwayListener>
                                </Paper>
                            </Grow>
                        )}
                    </Popper>
                    <div style={{ display: 'flex', height: data ? '400px' : '370px', width: '100%' }}>
                        {loadingData ?
                            <LoadingData /> :
                            data ?
                                <ResponsiveContainer width="100%" height="100%" >
             
                                    <BarChart width={'100%'} height={250} data={data}
                                        margin={{
                                            top: 5,
                                            bottom: 5,
                                            right: 0,
                                            left: 0
                                        }}>
                                        <CartesianGrid strokeDasharray="3 3" />
                                        <XAxis dataKey="name" />
                                        <YAxis />
                                        <Tooltip content={({ active, payload, label }) => {
                                            if (active && payload && payload.length) {
                                                return (
                                                    <div className="custom-tooltip d-flex align-items-center flex-column">
                                                        <p className="label" style={{ fontWeight: 700 }}>{label}</p>
                                                        <p style={{ fontSize: '12px' }}>{`${t("DealsCount")}: ${payload[0].value}`}</p>
                                                    </div>
                                                );
                                            }
                                            return null;
                                        }} />
                                        <Bar dataKey="value" fill="var(--main-color-one)" />
                                    </BarChart>
                                </ResponsiveContainer>
                                :
                                <NoData msg={t("NoDealsFound")} />}
                    </div>
                </div>
            </div>
        </>
    );
}

export default DealsPerStageReport;
