import { ExpandLessOutlined, ExpandMoreOutlined } from '@mui/icons-material';
import React, { useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom';
import { ReactComponent as CloseSvg } from "../../../assets/media/icons/close.svg";
import { priceFormat, renderPropertyIcon } from '../../../helpers';
import { ThemeProvider } from "@mui/material/styles";
import { Accordion, AccordionDetails, AccordionSummary, Checkbox, FormControl, FormControlLabel, RadioGroup, Slider } from '@mui/material';
import { getAllParamsForFilterForPropertyType, getPropertyTypesForCategory } from '../../../api/propertyApi';
import { useTranslation } from 'react-i18next';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const AdvancedSearch = ({ search, sort, setSearch, updateURL, showOptionsList, setShowOptionsList }) => {
    const [PropertyTypes, setPropertyTypes] = useState([]);
    const { t, i18n } = useTranslation();
    const [PricePeriodFilters, setPricePeriodFilters] = useState([
        {
            key: "All",
            name: t("All"),
            type: "rentType",
            isDefault: "1",
            value: "1",
        },
    ]);
    const [PriceFilter, setPriceFilter] = useState([0, 999999999999999]);
    const [RoomFilter, setRoomFilter] = useState([]);
    const [FeatureFilter, setFeatureFilter] = useState([]);
    const [Amenities, setAmenities] = useState([]);
    const [PriceFilters, setPriceFilters] = useState({
        Min: 0,
        Max: 1000000,
        Step: 5000,
    });
    const advancedSearchBtnRef = useRef(null);
    const [expanded, setExpanded] = useState(true);

    const toggleShowOptionsList = () => {
        let navigation = document?.querySelector(".bottom-navigation");
        setShowOptionsList(!showOptionsList);
        if (navigation)
            if (showOptionsList)
                navigation.style.display = "flex";
            else
                navigation.style.display = "none";

        document.body.click();
    };

    const handlePricePeriodFiltersChange = (key) => {
        let newroom = PricePeriodFilters.map((room) =>
            room.key === key ? { ...room, value: "1" } : { ...room, value: "0" }
        );
        setPricePeriodFilters(newroom);
    };
    
    const handlePriceFilterChange = (event, newValue) => setPriceFilter(newValue);

    const FilterPriceFormat = (value) => {
        if (value < PriceFilters?.Step) return t("LowerLimit");
        else if (value === PriceFilters?.Max) return t("UpperLimit");
        else return priceFormat(value.toString());
    }

    const handleRoomFilterChange = (key, value) => {
        let newroom = RoomFilter.map((room) =>
            room.key === key ? { ...room, value: value } : room
        );
    
        setRoomFilter(newroom);
    }

    const handleFeatureFilterChange = (key, value) => {
        let newfeature = FeatureFilter.map((feature) =>
            feature.key === key ? { ...feature, value: value } : feature
        );
    
        setFeatureFilter(newfeature);
    }
    
    const getPriceFiltersForPropertyType = (propertyId) => {
        let listedFor = search?.listedFor;
        switch (propertyId) {
            case "1":
            case "5":
                if (["2", "3"].includes(listedFor)) {
                    return { Min: 0, Max: 5100000, Step: 100000 };
                } else {
                    return { Min: 0, Max: 5050000, Step: 50000 };
                }
            case "2":
            case "6":
                if (["2", "3"].includes(listedFor)) {
                    return { Min: 0, Max: 21000000, Step: 1000000 };
                } else {
                    return { Min: 0, Max: 5200000, Step: 200000 };
                }
            case "3":
            case "12":
            case "13":
                if (["2", "3"].includes(listedFor)) {
                    return { Min: 0, Max: 1100000, Step: 100000 };
                } else {
                    return { Min: 0, Max: 105000, Step: 5000 };
                }
            case "4":
            case "10":
            case "11":
            case "14":
                if (["2", "3"].includes(listedFor)) {
                    return { Min: 0, Max: 5200000, Step: 200000 };
                } else {
                    return { Min: 0, Max: 250000, Step: 25000 };
                }
            case "7":
                if (["2", "3"].includes(listedFor)) {
                    return { Min: 0, Max: 8500000, Step: 500000 };
                } else {
                    return { Min: 0, Max: 550000, Step: 50000 };
                }
            case "8":
            case "15":
                if (["2", "3"].includes(listedFor)) {
                    return { Min: 0, Max: 5100000, Step: 100000 };
                } else {
                    return { Min: 0, Max: 550000, Step: 50000 };
                }
            case "9":
                if (["2", "3"].includes(listedFor)) {
                    return { Min: 0, Max: 2100000, Step: 100000 };
                } else {
                    return { Min: 0, Max: 300000, Step: 50000 };
                }
            default:
                break;
        }
    }
    
    const handlePropertyTypeChange = (target) => {
        // setProperties([]);
        // setLoadingProperties(true);
        // setProjects([]);
        setRoomFilter([]);
        setFeatureFilter([]);
        setAmenities([]);
        if (target.propertyType !== "0") {
            setPriceFilters(getPriceFiltersForPropertyType(target));
            setPriceFilter([
                0,
                getPriceFiltersForPropertyType(target)?.Max,
            ]);
        } else {
            setPriceFilter([0, 999999999999999]);
        }
    
        setSearch({
            ...search,
            propertyType: target === "0" ? "-1" : target,
            category: '',
            amenities: '',
            features: '',
            rooms: '',
            page: "1",
        });
    
        updateURL(
            search.listedFor,
            target === "0" ? "" : target,
            search.centerLat,
            search.centerLong,
            sort,
            search.zoomLevel,
            "1"
        );
    }

    const getFeaturesList = (key, minPrice) => {
        let list = [];
        let fKey = parseInt(key);
        if (fKey === 1 || fKey === 3 || fKey === 13 || fKey === 15 || fKey === 20) {
            list.push(minPrice + "-500");
            list.push("500-1,000");
            list.push("1,000-1,500");
            list.push("1,500-2,500");
            list.push("2,500-5,000");
            list.push("5,000-20,000");
            list.push("20,000+");
        }
        // Land Area - Villa
        else if (fKey === 10 || fKey === 27 || fKey === 30) {
            list.push(minPrice + "-400");
            list.push("400-700");
            list.push("700-1,000");
            list.push("1,000-2,000");
            list.push("2,000-2,500");
            list.push("2,500-5,000");
            list.push("5,000+");
        }
        // Street Width
        else if (
            fKey === 2 ||
            fKey === 8 ||
            fKey === 12 ||
            fKey === 14 ||
            fKey === 19 ||
            fKey === 22 ||
            fKey === 24 ||
            fKey === 29 ||
            fKey === 32
        ) {
            list.push("6-15");
            list.push("15-30");
            list.push("30-60");
            list.push("60-120");
        }
        // Construction Area - Apartment
        else if (fKey === 9) {
            list.push(minPrice + "-80");
            list.push("80-150");
            list.push("150-300");
            list.push("300-500");
            list.push("500+");
        }
    
        // Construction Area - Villa
        else if (fKey === 11 || fKey === 28 || fKey === 31) {
            list.push(minPrice + "-300");
            list.push("300-500");
            list.push("500-700");
            list.push("700-1,000");
            list.push("1,000-1,500");
            list.push("1,500+");
        }
    
        //  Construction Area - Commercial Building
        else if (fKey === 16) {
            list.push(minPrice + "-800");
            list.push("800-1,500");
            list.push("1,500-5,000");
            list.push("5,000-10,000");
            list.push("10,000+");
        }
    
        // Construction Area - Warehouse
        else if (fKey === 21) {
            list.push(minPrice + "-500");
            list.push("500-1,000");
            list.push("1,000-2,000");
            list.push("2,000-5,000");
            list.push("5,000-10,000");
            list.push("10,000+");
        }
    
        // Construction Area - Showroom
        else if (fKey === 23) {
            list.push(minPrice + "-150");
            list.push("150-300");
            list.push("300-500");
            list.push("500-1,000");
            list.push("1,000-3,000");
            list.push("3,000+");
        }
    
        //  Construction Area - Office
        else if (fKey === 26) {
            list.push(minPrice + "-120");
            list.push("120-250");
            list.push("250-400");
            list.push("400-800");
            list.push("800-1,500");
            list.push("1,500+");
        } else if (fKey === 6) {
            list.push(minPrice + "-2000");
            list.push("2000-2010");
            list.push("2010-2015");
            list.push("2015-2023");
        }
    
        return list;
    }
    
    const getFeatureFilterValue = (key, minPrice, maxPrice) => {
        let item = FeatureFilter.filter((e) => e.key === key);
        let vlaue = item[0].value;
        let list = getFeaturesList(key, minPrice);
        let returnedValue = "-1";
    
        list.forEach((element) => {
            let minVal = "0";
            if (element.includes("-")) {
                let valArr = element.split("-");
                minVal = valArr[0].replace(",", "");
            } else {
                minVal = element.replace("+", "");
                minVal = element.replace(",", "");
            }
            if (parseInt(minVal) === parseInt(vlaue)) returnedValue = element;
        });
        if (returnedValue !== '-1') {
            if (returnedValue.includes("-"))
                returnedValue = returnedValue.replace("-", ";");
            else returnedValue = returnedValue.replace("+", ";" + maxPrice);
    
            returnedValue = returnedValue.replace(/[,]+/g, "");
        }
    
        return returnedValue;
    }

    const FeatureFilterOptions = (key, minPrice, maxPrice) => {
        let list = getFeaturesList(key, minPrice);
        var arr = [];
        for (let i = 0; i < list.length; i++) {
            let value = list[i];
            if (value.includes("-")) value = list[i].replace("-", ";");
            else value = list[i].replace("+", ";" + maxPrice);
    
            value = value.replace(/[,]+/g, "");
            arr.push(
                <option key={i} value={value}>
                    {list[i]}
                </option>
            );
        }
        return arr;
    }
    const handleAmenitiesChange = (key) => {
        let newroom = Amenities.map((room) =>
            room.key === key
                ? { ...room, value: room.value === "0" ? "1" : "0" }
                : room
        );
        setAmenities(newroom);
    }

    useEffect(() => {
        getPropertyTypesForCategory({
            category: '1,2',
            language: i18n.language === 'ar' ? '0' : '1',
        }).then((_PropertyType) => setPropertyTypes(_PropertyType.response));
    }, [i18n.language]);
    
    useEffect(() => {
        if (search.propertyType !== '-1')
            getAllParamsForFilterForPropertyType({
                propertyType: search.propertyType,
                language: i18n.language === 'ar' ? '0' : '1',
            }).then((_PropertyType) => {
                if (_PropertyType.resCode === 0) {
                    if (_PropertyType.response.room.length > 0) {
                        let roomFilters = [];
                        _PropertyType.response.room.forEach((element) => {
                            element.value = "0";
                            roomFilters.push(element);
                        });
                        setRoomFilter(roomFilters);
                    } else {
                        setRoomFilter([]);
                    }
                    if (_PropertyType.response.feature.length > 0) {
                        let featureFilter = [];
                        _PropertyType.response.feature.forEach((element) => {
                            element.value = "-1";
                            featureFilter.push(element);
                        });
                        setFeatureFilter(featureFilter);
                    } else {
                        setFeatureFilter([]);
                    }
    
                    if (_PropertyType.response.amenity.length > 0) {
                        let amenityFilters = [];
                        _PropertyType.response.amenity.forEach((element) => {
                            element.value = "0";
                            amenityFilters.push(element);
                        });
                        setAmenities(amenityFilters);
                    } else {
                        setAmenities([]);
                    }
    
                    if (_PropertyType.response.price.length > 0) {
                        let PPFilters = [
                            {
                                key: "All",
                                name: t("All"),
                                type: "rentType",
                                isDefault: "1",
                                value: "1",
                            },
                        ];
                        _PropertyType.response.price.forEach((element) => {
                            element.value = "0";
                            PPFilters.push(element);
                        });
                        setPricePeriodFilters(PPFilters);
                    } else {
                        setPricePeriodFilters([
                            {
                                key: "All",
                                name: t("All"),
                                type: "rentType",
                                isDefault: "1",
                                value: "1",
                            },
                        ]);
                    }
                }
            });
    }, // eslint-disable-next-line
        [search.propertyType, i18n.language]);
    
    useEffect(() => {
        if (RoomFilter.length > 0) {
            let room = "";
            RoomFilter.forEach((element) => {
                if (element.value !== "0")
                    room += element.key + ":" + element.value + ",";
            });
            if (room !== "") room = room.slice(0, -1);
            setSearch({
                ...search,
                rooms: room,
                page: "1",
            });
        } else {
            setSearch({
                ...search,
                rooms: "",
                page: "1",
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [RoomFilter]);
    
    useEffect(() => {
        if (FeatureFilter.length > 0) {
            let feature = "";
            FeatureFilter.forEach((element) => {
                // if (element.value === "-1")
                //   feature += element.key + ":0,";
                if (element.value !== "0" && element.value !== "-1")
                    feature += element.key + ":" + element.value + ",";
    
            });
            if (feature !== "") feature = feature.slice(0, -1);
            setSearch({
                ...search,
                features: feature,
                page: "1",
            });
        } else {
            setSearch({
                ...search,
                features: "",
                page: "1",
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [FeatureFilter]);
    
    useEffect(() => {
        if (Amenities.length > 0) {
            let room = "";
            Amenities.forEach((element) => {
                if (element.value !== "0") room += element.key + ",";
            });
            if (room !== "") room = room.slice(0, -1);
            setSearch({
                ...search,
                amenities: room,
                page: "1",
            });
        } else {
            setSearch({
                ...search,
                amenities: "",
                page: "1",
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [Amenities]);
    
    useEffect(() => {
        let Max = PriceFilter[1]?.toString();
        if (PriceFilter[1] === PriceFilters?.Max) Max = "999999999999999";
        let pricefiltr = PriceFilter[0].toString() + "," + Max;
        setSearch({
            ...search,
            priceRange: pricefiltr,
            page: "1",
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [PriceFilter]);

    useEffect(() => {
        if (PricePeriodFilters.length > 1) {
            let pp = "All";
            let item = PricePeriodFilters.filter((e) => e.value === "1");
            if (item.length > 0) pp = item[0].key;
    
            setSearch({
                ...search,
                defaultPriceType: pp,
                page: "1",
            });
        } else {
            setSearch({
                ...search,
                defaultPriceType: "All",
                page: "1",
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [PricePeriodFilters]);
    
    return (
        <>
            <div
                ref={advancedSearchBtnRef}
                className={`advanced-search-btn ${showOptionsList || search?.propertyType !== "-1" ? "active" : ""}`}
                onClick={toggleShowOptionsList}>
                <Link
                    className='align-items-center d-flex'
                    style={{ fontSize: "15px" }}
                    to='#'>
                    {showOptionsList ? (
                        <ExpandLessOutlined className='hw-20' />
                    ) : (
                        <ExpandMoreOutlined className='hw-20' />
                    )}
                    <span>{t("AdvancedSearch")}</span>
                </Link>
            </div>
            <div
                className={`chat-info ${showOptionsList ? "chat-info-visible" : ""}`}
                style={{
                    left:
                        i18n.language === "ar" ? `calc(${+advancedSearchBtnRef?.current?.offsetLeft}px + 50%)` || 0 : 'unset',
                    right: i18n.language !== "ar" ? `calc(${+advancedSearchBtnRef?.current?.offsetLeft}px + 150px)` || 0 : 'unset',
                    top: advancedSearchBtnRef?.current?.offsetTop + 120 || 0,
                }}>
                <div className='d-flex h-100 flex-column'>
                    <Link
                        className='nav-link text-muted close-btn'
                        to='#'
                        onClick={toggleShowOptionsList}>
                        <CloseSvg />
                    </Link>
        
                    <div className='hide-scrollbar flex-fill'>
                        <div className='p-3 scrollbar'>
                            <div className='search-group'>
                              
                                <div className='search-group-body'>
                                    <Accordion expanded={expanded}
                                        onChange={() => setExpanded(!expanded)}
                                        sx={{
                                            background: 'transparent',
                                            boxShadow: 'none',
                                            padding: 0
                                        }}
                                        className='search-group-header col-12'>
                                        <AccordionSummary sx={{ minHeight: '0 !important', p: 0, "& .MuiAccordionSummary-content": { m: 0, mb: 1 } }} expandIcon={<ExpandMoreIcon />}>
                                            <h4 style={{
                                                fontSize: '16px',
                                                fontWeight: 600,
                                                color: 'var(--heading-color)'
                                            }}>{t("PropertyType")}</h4>
                                        </AccordionSummary >
                                        <AccordionDetails>
                                            <div className={`property-types-boxes pt-0 mt-0 pb-2 
                                            justify-content-center`}
                                                style={{ flexWrap: 'wrap', gap: 14 }}>
                                                <button
                                                    className={`property-type-box ${search?.propertyType === '-1' ? "selected" : ""}`}
                                                    onClick={(e) => search?.propertyType === '-1' ? {} : handlePropertyTypeChange('-1')}>
                                                    <span>{t("All")}</span>
                                                </button>
                                                {PropertyTypes.map((item) => (
                                                    <button
                                                        className={`property-type-box ${search?.propertyType === item.key ? "selected" : ""}`}
                                                        key={item.key}
                                                        onClick={(e) => search?.propertyType === item.key ? {} : handlePropertyTypeChange(item.key)}>
                                                        {renderPropertyIcon(item.key)}
                                                        <span>{item.name}</span>
                                                    </button>
                                                ))}
                                            </div>
                                        </AccordionDetails>
                                    </Accordion>
                                    {/* <div style={{ textAlign: "-webkit-center", overflowX: 'auto' }}>
                                        <div className={`property-types-boxes mt-1 pb-2 ${i18n.language==="ar"?"pl-3":"pr-3"} justify-content-start`} style={{ flexWrap: 'nowrap', gap: 10 }}>
                                            <button
                                                className={`property-type-box ${search?.propertyType === '-1' ? "selected" : ""}`}
                                                style={{ minWidth: 70 }}
                                                onClick={(e) => search?.propertyType === '-1' ? {} : handlePropertyTypeChange('-1')}>
                                                <span>{t("All")}</span>
                                            </button>
                                            {PropertyTypes.map((item) => (
                                                <button
                                                    className={`property-type-box ${search?.propertyType === item.key ? "selected" : ""}`}
                                                    key={item.key}
                                                    style={{ minWidth: 70 }}
                                                    onClick={(e) => search?.propertyType === item.key ? {} : handlePropertyTypeChange(item.key)}>
                                                    {renderPropertyIcon(item.key)}
                                                    <span>{item.name}</span>
                                                </button>
                                            ))}
                                        </div>
                                    </div> */}
                                </div>
                            </div>
             
                            {search?.listedFor === "1" && search?.propertyType !== '-1' && (
                                <div className='search-group'>
                                    <div className='search-group-header'>
                                        <h4>{t("RentalOptions")}</h4>
                                    </div>
                                    <div className='search-group-body'>
                                        <div className='btns-wrapper' style={{ direction: 'rtl !important' }}>
                                            {PricePeriodFilters.map((item, i) => (
                                                <button
                                                    key={"PP" + i}
                                                    style={PricePeriodFilters.length === 1 ? { borderRadius: '10px' } : {}}
                                                    className={`btn btn-search ${item.value === "1" ? "selected" : ""}`}
                                                    value={item.value}
                                                    onClick={(e) => {
                                                        handlePricePeriodFiltersChange(item.key);
                                                    }}>
                                                    {item.name}
                                                </button>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            )}

                            {(search?.defaultPriceType === "1" ||
                                search?.defaultPriceType === "All") &&
                                search?.propertyType !== "-1" && (
                                    <div className='search-group'>
                                        <div className='search-group-header'>
                                            <h4>{t("Price")}</h4>
                                        </div>
                                        <div className='search-group-body p-2'>
                                            <div style={{ marginTop: "30px", width: "88%", paddingInlineStart:'16px' }} className='mx-auto'>
                                                <ThemeProvider
                                                    theme={(outerTheme) => ({
                                                        ...outerTheme,
                                                        direction: "ltr",
                                                    })}>
                                                    <Slider
                                                        value={PriceFilter}
                                                        onChangeCommitted={handlePriceFilterChange}
                                                        aria-labelledby='range-slider'
                                                        valueLabelDisplay='on'
                                                        getAriaValueText={FilterPriceFormat}
                                                        valueLabelFormat={FilterPriceFormat}
                                                        min={PriceFilters?.Min}
                                                        style={{ width: "95%" }}
                                                        step={PriceFilters?.Step}
                                                        max={PriceFilters?.Max}
                                                    />
                                                </ThemeProvider>
                                            </div>
                                        </div>
                                    </div>
                                )}

                            {RoomFilter.map((item, i) => (
                                <div className='search-group' key={"Room" + i}>
                                    <div className='search-group-header'>
                                        <h4>{item.name}</h4>
                                    </div>
                                    <div className='search-group-body'>
                                        <div className='btns-wrapper rooms' style={{ direction: 'rtl !important' }}>
                                            <button
                                                value='0'
                                                className={`btn btn-search ${item.value === "0" ? "selected" : ""}`}
                                                onClick={(e) => {
                                                    handleRoomFilterChange(item.key, "0");
                                                }}>
                                                {t("All")}
                                            </button>
                                            <button
                                                value='1'
                                                className={`btn btn-search ${item.value === "1" ? "selected" : ""}`}
                                                onClick={(e) => {
                                                    handleRoomFilterChange(item.key, "1");
                                                }}>
                                                {parseInt(item.upperLimit) < 5 ? "1" : "3-1"}
                                            </button>

                                            <button
                                                value='2'
                                                className={`btn btn-search ${item.value === "2" ? "selected" : ""}`}
                                                onClick={(e) => {
                                                    handleRoomFilterChange(item.key, "2");
                                                }}>
                                                {parseInt(item.upperLimit) < 5 ? "2" : "5-3"}
                                            </button>
                                            <button
                                                value='3'
                                                className={`btn btn-search ${item.value === "3" ? "selected" : ""}`}
                                                onClick={(e) => {
                                                    handleRoomFilterChange(item.key, "3");
                                                }}>
                                                {parseInt(item.upperLimit) < 5 ? "3" : "7-5"}
                                            </button>
                                            <button
                                                value='4'
                                                className={`btn btn-search ${item.value === "4" ? "selected" : ""}`}
                                                onClick={(e) => {
                                                    handleRoomFilterChange(item.key, "4");
                                                }}>
                                                {parseInt(item.upperLimit) < 5 ? "4" : "7+"}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            ))}

                            {FeatureFilter.map((item, i) => (
                                <div className='search-group' key={"FF" + i}>
                                    <div className='search-group-header'>
                                        <h4>{item.name}</h4>
                                    </div>
                                    <div className='search-group-body'>
                                        <div style={{ textAlign: "-webkit-center" }}>
                                            <select
                                                id='sort'
                                                name='sort'
                                                defaultValue={"-1"}
                                                onChange={(e) => handleFeatureFilterChange(item.key, e.currentTarget.value)}
                                                value={getFeatureFilterValue(item.key, item.lowerLimit, item.upperLimit)}
                                                className='form-control sortDDL'>
                                                <option value='-1' key="-1">{t("All")}</option>
                                                {FeatureFilterOptions(item.key, item.lowerLimit, item.upperLimit)}
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            ))}

                            {Amenities.length > 0 && (
                                <div className='search-group'>
                                    <div className='search-group-header'>
                                        <h4>{t("Features")}</h4>
                                    </div>
                                    <div className='search-group-body row p-0 features'>
                                        <FormControl>
                                            <RadioGroup row style={{ marginRight: '-13px' }}>
                                                {Amenities.map((item, i) => (
                                                    <FormControlLabel
                                                        key={"Am" + i}
                                                        id={"btnAmenities" + i}
                                                        style={{ margin: 0 }}
                                                        value={item.key}
                                                        onClick={(e) => {
                                                            handleAmenitiesChange(item.key);
                                                        }}
                                                        control={<Checkbox />}
                                                        label={item.name}
                                                    />
                                                ))}
                                            </RadioGroup>
                                        </FormControl>
                                    </div>
                                </div>
                            )}
                            <div className='save-search-btn'>
                                <button
                                    className='btn primary-btn px-3 w-100 d-flex justify-content-center '
                                    onClick={(e) => {
                                        toggleShowOptionsList();
                                    }}>
                                    {t("Search")}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default AdvancedSearch;
