import React, { useEffect, useRef, useState } from 'react'
import { Box } from '@mui/material'
import { useTranslation } from 'react-i18next'

function getRandomInt(min, max) {
  min = Math.ceil(min)
  max = Math.floor(max)
  return Math.floor(Math.random() * (max - min)) + min
}

function genetateCaptcha(max) {
  let text = ''
  let i
  for (i = 0; i < max; i += 1) {
    switch (Math.floor(Math.random() * 3)) {
      case 0: text += String.fromCharCode(48 + Math.floor(Math.random() * 10)); break
      case 1: text += String.fromCharCode(65 + Math.floor(Math.random() * 26)); break
      case 2: text += String.fromCharCode(97 + Math.floor(Math.random() * 26)); break
      default: break
    }
  }
  return text
}
const AmakknCaptcha = ({ onChange, onRefresh, isSubmitClicked }) => {

  const [solution, setSolution] = useState(genetateCaptcha(6));
  const [input, setInput] = useState("");
  let canvas = useRef();
  const { t } = useTranslation();

  useEffect(() => {
    drawCaptcha();
  }, // eslint-disable-next-line
    []);


  const drawCaptcha = (newSolution) => {
    const { width, height } = canvas?.current;
    const ctx = canvas?.current?.getContext('2d')
    ctx.clearRect(0, 0, width, height)
    ctx.font = `${getRandomInt(30, 40)}px Dubai`
    ctx.textAlign = 'center'
    ctx.textBaseline = 'middle'
    ctx.fillStyle = '#243EAD'

    ctx.fillText(newSolution || solution, width / 2, height / 2 + 2)

    ctx.strokeStyle = 'orange'
    
    ctx.beginPath();
    ctx.moveTo(getRandomInt(5, 20), getRandomInt(5, 20));
    ctx.lineTo(width - getRandomInt(5, 20), height - getRandomInt(5, 20));
    ctx.stroke();
    ctx.moveTo(getRandomInt(15, 30), getRandomInt(15, 30));
    ctx.lineTo(width - getRandomInt(15, 30), height - getRandomInt(15, 30));
    ctx.stroke()
    ctx.moveTo(getRandomInt(5, 20), height - getRandomInt(5, 20));
    ctx.lineTo(width - getRandomInt(5, 20), getRandomInt(5, 20));
    ctx.stroke()
    ctx.moveTo(getRandomInt(15, 30), height - getRandomInt(15, 30));
    ctx.lineTo(width - getRandomInt(15, 30), getRandomInt(15, 30));
    ctx.stroke()
    ctx.moveTo(getRandomInt(width / 10, (width / 10) + 10), height - getRandomInt(15, 30));
    ctx.lineTo(getRandomInt(width / 2, width / 2 + 10), getRandomInt(5, 20));
    ctx.stroke()
    ctx.closePath()
  };

  const playAudio = () => {
    const audio = new SpeechSynthesisUtterance(solution.toString().split('').join(' '));
    audio.rate = 0.5;
    window.speechSynthesis.speak(audio);
  };

  const handleChange = e => {
    setInput(e.target.value);
    onChange(e.target.value, solution, solution === e.target.value);
  };

  useEffect(() => {
    if (!isSubmitClicked) {
      refresh();
    }
  }, // eslint-disable-next-line
    [isSubmitClicked]);

  const refresh = () => {
    let newSolution = genetateCaptcha(6);
    setSolution(newSolution);
    setInput("");
    onRefresh();
    setTimeout(() => {
      drawCaptcha(newSolution);
    }, 800);
  };

  return (
    <Box className='amakkn-captcha'>
      <div className='amakkn-captcha-row'>
        <canvas
          ref={canvas}
          width={200}
          height={50}
          className='amakkn-captcha-canvas'
          data-testid='captcha-canvas'
        />
        <div className='amakkn-captcha-column'>
          <button
            type='button'
            aria-label='get new captcha'
            onClick={refresh}
            className='amakkn-captcha-button'
            data-testid='captcha-refresh'
          >
            <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'>
              <g data-name='Layer 2'>
                <g data-name='refresh'>
                  <rect width='24' height='24' opacity='0' />
                  <path d='M20.3 13.43a1 1 0 0 0-1.25.65A7.14 7.14 0 0 1 12.18 19 7.1 7.1 0 0 1 5 12a7.1 7.1 0 0 1 7.18-7 7.26 7.26 0 0 1 4.65 1.67l-2.17-.36a1 1 0 0 0-1.15.83 1 1 0 0 0 .83 1.15l4.24.7h.17a1 1 0 0 0 .34-.06.33.33 0 0 0 .1-.06.78.78 0 0 0 .2-.11l.09-.11c0-.05.09-.09.13-.15s0-.1.05-.14a1.34 1.34 0 0 0 .07-.18l.75-4a1 1 0 0 0-2-.38l-.27 1.45A9.21 9.21 0 0 0 12.18 3 9.1 9.1 0 0 0 3 12a9.1 9.1 0 0 0 9.18 9A9.12 9.12 0 0 0 21 14.68a1 1 0 0 0-.7-1.25z' />
                </g>
              </g>
            </svg>
          </button>
          <button
            type='button'
            aria-label='play audio'
            onClick={playAudio}
            className='amakkn-captcha-button'
            data-testid='captcha-audio'
          >
            <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'>
              <g data-name='Layer 2'>
                <g data-name='volume-up'>
                  <rect width='24' height='24' opacity='0' />
                  <path d='M18.28 8.37a1 1 0 1 0-1.56 1.26 4 4 0 0 1 0 4.74A1 1 0 0 0 17.5 16a1 1 0 0 0 .78-.37 6 6 0 0 0 0-7.26z' />
                  <path d='M19.64 5.23a1 1 0 1 0-1.28 1.54A6.8 6.8 0 0 1 21 12a6.8 6.8 0 0 1-2.64 5.23 1 1 0 0 0-.13 1.41A1 1 0 0 0 19 19a1 1 0 0 0 .64-.23A8.75 8.75 0 0 0 23 12a8.75 8.75 0 0 0-3.36-6.77z' />
                  <path d='M15 3.12a1 1 0 0 0-1 0L7.52 7.57h-5a1 1 0 0 0-1 1v6.86a1 1 0 0 0 1 1h5l6.41 4.4a1.06 1.06 0 0 0 .57.17 1 1 0 0 0 1-1V4a1 1 0 0 0-.5-.88zm-1.47 15L8.4 14.6a1 1 0 0 0-.57-.17H3.5V9.57h4.33a1 1 0 0 0 .57-.17l5.1-3.5z' />
                </g>
              </g>
            </svg>
          </button>
        </div>
      </div>
      <input
        value={input}
        onChange={handleChange}
        placeholder={t("Captcha")}
        className='amakkn-captcha-input'
        data-testid='captcha-input'
      />
    </Box>
  );
  
}

export default AmakknCaptcha;