import React, { useState, useEffect } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import UploadLogo from "../../uploadLogo";
import Dream from "../../section-components/dream";
import VisibilityIcon from "@mui/icons-material/Visibility";
import AutoAwesomeMosaicIcon from "@mui/icons-material/AutoAwesomeMosaic";
import TemplateChooser from "../../template-chooser";
import ContactInfo from "../../contact-info";
import {
  Box,
  Stepper,
  Step,
  CircularProgress,
  Typography,
  StepLabel,
} from "@mui/material";
import AppLayout from "../../layouts/app-layout";
import ColorPalette from "../../../scenes/admin/styles/components/color-palette";
import { useAuth } from "../../../helpers/context";
import { toast } from "react-toastify";
import { chooseTemplate, getUserProile } from "../../../api/userApi";
import AboutUsSection from "../about-us-onboarding";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { useTranslation } from "react-i18next";

const WhiteLabel2 = (props) => {
  const { setToken, setLogo, setUserId, setUserType } = useAuth();
  const location = useLocation();
  let { id } = useParams();
  useEffect(() => {
    if (id) setToken(id);
  },
    [id, setToken]);
  const { t, i18n } = useTranslation();

  useEffect(() => {
    if (location.state && location.state.incomplete)
      toast.error(t("YourAccountIsIncompletePleaseCompleteAllSteps"));
    else {
      if (location.search.includes('successModal'))
        Swal.fire({
          title: t("YourDomainHasBeenSuccessfullyReserved"),
          text: '',
          icon: 'success',
          confirmButtonText: t("OK")
        });
      // toast.success( "تم حجز نطاقكم بنجاح، تحتاج العملية لبعض الوقت سيتم اعلامك عند الإنتهاء"   );
    }

  },// eslint-disable-next-line
    []);

  const [CSS, setCSS] = useState({
    mainColorOne: "",
    mainColorTwo: "",
    headingColor: "",
    paragraphColor: "",
    btnColor: "",
    bodyFontSize: "",
    bodyFont: "Dubai",
  });
  const [Stepp, setStep] = useState(1);
  const [loading, setLoading] = useState(false);
  const [completedSteps, setCompletedSteps] = useState([0]);
  const [finishStatus, setfinishStatus] = useState(false);
  const [TemplateItemSelected, setTemplateItemSelected] = useState('0');
  const [wlPlanId, setWlPlanId] = useState(7);
  
  const onBackButtonEvent = (e) => {
    e.preventDefault();
    if (!finishStatus) {
      if (
        window.confirm(t("ChangePage"))
      ) {
        setfinishStatus(true);
        // your logic
        props.history.push("/");
      } else {
        window.history.pushState(null, null, window.location.pathname);
        setfinishStatus(false);
      }
    }
  };

  useEffect(() => {
    window.history.pushState(null, null, window.location.pathname);
    window.addEventListener("popstate", onBackButtonEvent);
    return () => {
      window.removeEventListener("popstate", onBackButtonEvent);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const steps = [
    t("PaymentAndDomainSelection"),
    t("ContactInformation"),
    t("ChooseLogo"),
    t("ChooseTemplate"),
    t("ColorsAndFonts"),
    t("AboutUS"),
    t("Services"),
    t("Finishing"),
  ];

  useEffect(() => {
    if (id) {
      getUserProile({
        userId: id,
        language: i18n.language === 'ar' ? '0' : '1',
      }).then((_userInfo) => {
        if (_userInfo.resCode === 0) {
          setUserId(_userInfo.response.userProfile.userId);
          setUserType(_userInfo.response.userProfile.userType);
          setWlPlanId(_userInfo.response.userProfile.wlPlanId?.[0] || 7);
          const accountType = _userInfo.response.userProfile.accountType;
          chooseTemplate({
            userId: id,
            language: i18n.language === 'ar' ? '0' : '1',
            templateId: accountType === '2' ? '2' : '1'
          }).then().catch();
        }
      });
    }

  },// eslint-disable-next-line
    [id]);

  useEffect(() => {
    window.scroll({ top: 0, left: 0, behavior: "smooth" });
  }, [Stepp]);
  
  return (
    <AppLayout pageTitle={t("AmakknPro")} withoutNav>
      <div className='row page-wrapper-without-top page-wrapper-column pt-5 pb-5 px-3'>
        <div className='col-12'>
          <div className='container'>
            <Box sx={{ width: "100%", margin: "40px auto 32px", padding: 0 }}>
              <h2 className='page-title'>{t("AmakknProSetup")}</h2>
              <Stepper
                className='onboarding-steps'
                activeStep={Stepp}
                nonLinear
                sx={{ width: "100%" }}>
                {steps.map((label, index) => (
                  <Step
                    key={label}
                    completed={
                      completedSteps[index] !== undefined ? true : false
                    }>
                    <StepLabel>{label}</StepLabel>
                  </Step>
                ))}
              </Stepper>
              <div className='onboarding-mobile-steps'>
                <Box sx={{ position: "relative", display: "inline-flex" }}>
                  <CircularProgress
                    size={60}
                    thickness={6}
                    variant='determinate'
                    value={(100 / steps.length) * (Stepp + 1)}
                  />
                  <Box
                    sx={{
                      top: 0,
                      left: 0,
                      bottom: 0,
                      right: '-3px',
                      position: "absolute",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}>
                    <Typography
                      variant='caption'
                      component='div'
                      color='text.secondary'>
                      {t("Step")}
                      <br />
                      {`${Stepp + 1}/${steps.length}`}
                    </Typography>
                  </Box>
                </Box>
                <p style={{ margin: 0 }}>{steps[Stepp]}</p>
              </div>
            </Box>
          </div>
        </div>
        <div className='col-12 text-center'>
          {Stepp === 1 && (
            <>
              <h3 className='modal-heading'>{t("ContactInformation")}</h3>
              <p className='modal-desc'>{t("ContactInformationDesc1")}</p>
            </>
          )}
          {Stepp === 3 && (
            <>
              <h3 className='modal-heading'>{t("ChooseTemplate")}</h3>
              <p className='modal-desc'>{t("ChooseTemplateDesc")}</p>
            </>
          )}
          {Stepp === 5 && (
            <>
              <h3 className='modal-heading'>{t("AboutUS")}</h3>
              <p className='modal-desc'>{t("AboutDesc")}</p>
            </>
          )}
          {Stepp === 4 && (
            <>
              <h3 className='modal-heading'>{t("ColorsAndFonts")}</h3>
              <p className='modal-desc'>{t("ColorsAndFontsDesc")}</p>
            </>
          )}
          {Stepp === 6 && (
            <>
              <h3 className='modal-heading'>{t("Services")}</h3>
              <p className='modal-desc'>{t("ServicesDesc")}</p>
            </>
          )}
          {Stepp === 7 && (
            <h3 className='modal-heading'>{t("SetupCompletedSuccessfully")}</h3>
          )}
        </div>
        <div className='col-11'>
          <div className='container'>
            {Stepp === 1 && (
              <ContactInfo
                loadingButton={loading}
                setLoading={setLoading}
                withoutDefaultData={false}
                centerText
                goNext={() => {
                  setStep(Stepp + 1);
                  setCompletedSteps([0, 1]);
                }}
                token={id}
              />
            )}
            {Stepp === 2 && (
              <div>
                <UploadLogo
                  // LogoTOView={logo}
                  setLogoTOView={setLogo}
                  loadingButton={loading}
                  setLoading={setLoading}
                  goNext={() => {
                    setStep(Stepp + 1);
                    setCompletedSteps([0, 1, 2]);
                  }}
                  // goBack={() => { setStep(Stepp - 1); setCompletedSteps([0, 1]); }}
                  token={id}
                />
              </div>
            )}
            {Stepp === 5 && (
              <div>
                <AboutUsSection
                  loadingButton={loading}
                  setLoading={setLoading}
                  token={id}
                  goNext={() => {
                    setStep(Stepp + 1);
                    setCompletedSteps([0, 1, 2, 3, 4, 5]);
                  }}
                />
              </div>
            )}
            {Stepp === 3 && (
              <div>
                <TemplateChooser
                  token={id}
                  wlPlanId={wlPlanId}
                  loadingButton={loading}
                  setLoading={setLoading}
                  goNext={() => {
                    setStep(Stepp + 1);
                    setCompletedSteps([0, 1, 2, 3]);
                  }}
                  // goBack={() => { setStep(Stepp - 1); setCompletedSteps([0, 1, 2]); }}
                  ItemSelected={TemplateItemSelected}
                  setItemSelected={setTemplateItemSelected}
                />
              </div>
            )}
            {Stepp === 4 && (
              <div>
                <ColorPalette
                  token={id}
                  CSS={CSS}
                  setCSS={setCSS}
                  loadingButton={loading}
                  setLoading={setLoading}
                  goNext={() => {
                    setStep(Stepp + 1);
                    setCompletedSteps([0, 1, 2, 3, 4]);
                  }}
                // goBack={() => { setStep(Stepp - 1); setCompletedSteps([0, 1, 2, 3]); }}
                />
              </div>
            )}
            {Stepp === 6 && (
              <div>
                <Dream
                  isEdit={true}
                  loadingButton={loading}
                  setLoading={setLoading}
                  goNext={() => {
                    setStep(Stepp + 1);
                    setCompletedSteps([0, 1, 2, 3, 4, 5, 6]);
                  }}
                // goBack={() => { setStep(Stepp - 1); setCompletedSteps([0, 1, 2, 3, 4]); }}
                />
              </div>
            )}
            {Stepp === 7 && (
              <div className='final-step'>
                <img src={"/assets/img/check.png"} alt='check' />
                <p>{t("FinalizeWL")}</p>
                <div className='next-back-btns'>
                  <Link className='outlined-btn btn' to='/admin'>
                    <span>{t("SiteManagement")}</span>
                    <AutoAwesomeMosaicIcon />
                  </Link>
                  <Link className='btn primary-btn' to='/'>
                    <span>{t("WebsitePreview")}</span>
                    <VisibilityIcon />
                  </Link>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </AppLayout>
  );
}

export default WhiteLabel2;
