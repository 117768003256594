import React from "react";
import SwiperCore, { Autoplay } from "swiper";
import { Area, BathRooms, BedRooms } from "../../constants/icons";
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import { getLocalizedText, validateURL } from "../../helpers";
import ZoomOutMapIcon from '@mui/icons-material/ZoomOutMap';
import ImageModal from "../global-components/image-modal";
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import HomeIcon from '@mui/icons-material/Home';
import HolidayVillageIcon from '@mui/icons-material/HolidayVillage';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import GrassIcon from '@mui/icons-material/Grass';
import { useTranslation } from "react-i18next";

SwiperCore.use([Autoplay]);

const Models = (props) => {
  let data = props.data;
  const [selectedImage, setSelectedImage] = React.useState(undefined);
  const [imageModalOpened, setImageModalOpened] = React.useState(false);
  const { t, i18n } = useTranslation();

  return (
    <>
      <h3>{t("ResidentialUnitModels")}</h3>
      <div className="models-slider">
        {data.map((item, i) => (
          <div
            key={i}
            className='row mx-0 mb-5'
            style={{ direction: i % 2 === 0 ? 'rtl' : 'ltr' }}
          >
            <div className='col-12 col-lg-6 image p-0 m-0'>
              {item.image && (
                <>
                  <img src={item.image} alt={t("ModelImage", { number: i + 1 })} />
                  <span
                    className="zoom-icon"
                    onClick={() => {
                      setSelectedImage(item.image);
                      setImageModalOpened(true);
                    }}>
                    <ZoomOutMapIcon sx={{ color: 'var(--main-color-one)', background: 'var(--main-color-two)', fontSize: '20px', fontWeight: 'bold' }} />
                  </span>
                  <ImageModal
                    data={
                      <div className='model-info'>
                        <div className='row m-0 py-3 px-sm-3'>
                          <div className='col-6 col-sm-6 col-lg-3 col-xl-2 box'>
                            <div className='icon'>
                              <HomeIcon />
                            </div>
                            <div className='info'>
                              <h5>{t("ModelType")}</h5>
                              <span>{t(item.modelType)}</span>
                            </div>
                          </div>
                          <div className='col-6 col-sm-6 col-lg-3 col-xl-2 box'>
                            <div className='icon'>
                              <GrassIcon />
                            </div>
                            <div className='info'>
                              <h5>{t("ModelName")}</h5>
                              <span>{item.name ? getLocalizedText(item.name, i18n.language, false) : t("NotAvailable")}</span>
                            </div>
                          </div>
                          <div className='col-6 col-sm-6 col-lg-3 col-xl-2 box'>
                            <div className='icon'>
                              <AttachMoneyIcon />
                            </div>
                            <div className='info'>
                              <h5>{t("PricesStartFrom")}</h5>
                              <span>{item.minimumPrice !== '0' ? Intl.NumberFormat("en").format(item.minimumPrice) + ` ${t('SAR')}` : t("NotAvailable")}</span>
                            </div>
                          </div>
                          {item.area &&
                            <div className='col-6 col-sm-6 col-lg-3 col-xl-2 box'>
                              <div className='icon'><Area /></div>
                              <div className='info'>
                                <h5>{t("Area")}</h5>
                                <span>{item?.area?.indexOf('@') ?
                                  `${item?.area?.substring(0, item?.area?.indexOf('@'))}${t("M2")} - ${item?.area?.substring(item?.area?.indexOf('@') + 1)}${t("M2")}`
                                  : item?.area}</span>
                              </div>
                            </div>
                          }
                          {item.room &&
                            <div className='col-6 col-sm-6 col-lg-3 col-xl-2 box'>
                              <div className='icon'><BedRooms /></div>
                              <div className='info'>
                                <h5>{t("Rooms")}</h5>
                                <span>{item.room}</span>
                              </div>
                            </div>
                          }
                          {item.bathroom &&
                            <div className='col-6 col-sm-6 col-lg-3 col-xl-2 box'>
                              <div className='icon'><BathRooms /></div>
                              <div className='info'>
                                <h5>{t("Bathrooms")}</h5>
                                <span>{item.bathroom}</span>
                              </div>
                            </div>
                          }
                          {item.numberOfUnits !== '0' &&
                            <div className='col-6 col-sm-6 col-lg-3 col-xl-2 box'>
                              <div className='icon'><HolidayVillageIcon /></div>
                              <div className='info'>
                                <h5>{t("TotalNumberOfUnits")}</h5>
                                <span>{item.numberOfUnits}</span>
                              </div>
                            </div>
                          }
                          {item.availableUnits !== '0' &&
                            <div className='col-6 col-sm-6 col-lg-3 col-xl-2 box'>
                              <div className='icon'><HolidayVillageIcon /></div>
                              <div className='info'>
                                <h5>{t("NumberOfUnitsAvailable")}</h5>
                                <span>{item.availableUnits}</span>
                              </div>
                            </div>
                          }
                          {item.floorPlan &&
                            <div className='col-6 col-sm-6 col-lg-3 col-xl-2 box'>
                              <div className='icon'><PictureAsPdfIcon /></div>
                              <div className='info'>
                                <h5>{t("Plan")}</h5>
                                <span><a rel="noreferrer" href={validateURL(item.floorPlan) ? item.floorPlan.substring(0, item.floorPlan.indexOf('?')) : ''} target="_blank">
                                  <span style={{ cursor: 'pointer' }}>{t("ViewThePlan")}</span>
                                </a></span>
                              </div>
                            </div>
                          }
                          {item.amenities && (
                            <>
                              {item.amenities.split('@').map((text, i) => (
                                <div className='col-6 col-sm-6 col-lg-3 col-xl-2 box'>
                                  <div className='icon'><AutoAwesomeIcon /></div>
                                  <div className='info'>
                                    <h5>{`${t("Feature")} ${i + 1}`}</h5>
                                    <span> {text}</span>
                                  </div>
                                </div>
                              )
                              )}
                            </>
                          )}
                        </div>
                      </div>
                    }
                    src={selectedImage}
                    open={imageModalOpened}
                  
                    handleClose={() => setImageModalOpened(false)}
                  />
                </>
              )}
            </div>
            <div className='col-12 col-sm-11 col-lg-6 px-0 px-sm-3 m-auto m-lg-0 d-flex align-items-center justify-content-sm-center justify-content-between'>
              <div className="row w-100 mx-0">
                <div className='model-info'>
                  <div className='row m-0 py-3 px-sm-3'>
                    <div className='col-6 col-sm-6 col-xl-4 box'>
                      <div className='icon'>
                        <HomeIcon />
                      </div>
                      <div className='info'>
                        <h5>{t("ModelType")}</h5>
                        <span>{t(item.modelType)}</span>
                      </div>
                    </div>
                    <div className='col-6 col-sm-6 col-xl-4 box'>
                      <div className='icon'>
                        <GrassIcon />
                      </div>
                      <div className='info'>
                        <h5>{t("ModelName")}</h5>
                        <span>{item.name ? getLocalizedText(item.name, i18n.language, false) : t("NotAvailable")}</span>
                      </div>
                    </div>
                    <div className='col-6 col-sm-6 col-xl-4 box'>
                      <div className='icon'>
                        <AttachMoneyIcon />
                      </div>
                      <div className='info'>
                        <h5>{t("PricesStartFrom")}</h5>
                        <span>{item.minimumPrice !== '0' ? Intl.NumberFormat("en").format(item.minimumPrice) + ` ${t('SAR')}` : t("NotAvailable")}</span>
                      </div>
                    </div>
                    {item.area &&
                      <div className='col-6 col-sm-6 col-xl-4 box'>
                        <div className='icon'><Area /></div>
                        <div className='info'>
                          <h5>{t("Area")}</h5>
                          <span>{item?.area?.indexOf('@') ?
                            `${item?.area?.substring(0, item?.area?.indexOf('@'))}${t("M2")} - ${item?.area?.substring(item?.area?.indexOf('@') + 1)}${t("M2")}`
                            : item?.area}</span>
                        </div>
                      </div>
                    }
                    {item.room &&
                      <div className='col-6 col-sm-6 col-xl-4 box'>
                        <div className='icon'><BedRooms /></div>
                        <div className='info'>
                          <h5>{t("Rooms")}</h5>
                          <span>{item.room}</span>
                        </div>
                      </div>
                    }
                    {item.bathroom &&
                      <div className='col-6 col-sm-6 col-xl-4 box'>
                        <div className='icon'><BathRooms /></div>
                        <div className='info'>
                          <h5>{t("Bathrooms")}</h5>
                          <span>{item.bathroom}</span>
                        </div>
                      </div>
                    }
                    {item.numberOfUnits !== '0' &&
                      <div className='col-6 col-sm-6 col-xl-4 box'>
                        <div className='icon'><HolidayVillageIcon /></div>
                        <div className='info'>
                          <h5>{t("TotalNumberOfUnits")}</h5>
                          <span>{item.numberOfUnits}</span>
                        </div>
                      </div>
                    }
                    {item.availableUnits !== '0' &&
                      <div className='col-6 col-sm-6 col-xl-4 box'>
                        <div className='icon'><HolidayVillageIcon /></div>
                        <div className='info'>
                          <h5>{t("NumberOfUnitsAvailable")}</h5>
                          <span>{item.availableUnits}</span>
                        </div>
                      </div>
                    }
                    {item.floorPlan &&
                      <div className='col-6 col-sm-6 col-xl-4 box'>
                        <div className='icon'><PictureAsPdfIcon /></div>
                        <div className='info'>
                          <h5>{t("Plan")}</h5>
                          <span><a rel="noreferrer" href={validateURL(item.floorPlan) ? item.floorPlan.substring(0, item.floorPlan.indexOf('?')) : ''} target="_blank">
                            <span style={{ cursor: 'pointer' }}>{t("ViewThePlan")}</span>
                          </a></span>
                        </div>
                      </div>
                    }
                    {item.amenities && (
                      <>
                        {item.amenities.split('@').map((text, i) => (
                          <div className='col-6 col-sm-6 col-xl-4 box'>
                            <div className='icon'><AutoAwesomeIcon /></div>
                            <div className='info'>
                              <h5>{`${t("Feature")} ${i + 1}`}</h5>
                              <span> {text}</span>
                            </div>
                          </div>
                        )
                        )}
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </>
  );
}

export default Models;
