import React, { useEffect, useMemo, useState } from 'react'
import AppLayout from '../../components/layouts/app-layout'
import PageHeader from "../../components/global-components/page-header";
import { useTranslation } from 'react-i18next';
import { Alert, Autocomplete, Box, FormControl, FormControlLabel, FormHelperText, InputAdornment, InputLabel, MenuItem, OutlinedInput, Radio, RadioGroup, Select, TextField } from '@mui/material';
import { preventString, resolvePersianAndArabicNumbers, validatePhone } from '../../helpers';
import { CITIES, DISTRICTS, REGIONS } from '../../constants';
import LoadingButton from '../../components/global-components/loading-btn';
import { ArrowBack } from '@mui/icons-material';
import { createRequest } from '../../api/userApi';
import { toast } from 'react-toastify';
import { useAuth } from '../../helpers/context';
import Swal from "sweetalert2/dist/sweetalert2.js";
import AmakknCaptcha from '../../components/global-components/amakkn-captcha';
// import ReCAPTCHA from "react-google-recaptcha";

export default function RequestProperty() {

  const { t, i18n } = useTranslation();
  const { vendor } = useAuth();
  const [data, setData] = useState({
    propertyType: '',
    name: '',
    phone: '',
    desire: "استئجار",
    neighbourhoodIds: [],
    priceFrom: '',
    priceTo: '',
    areaFrom: '',
    areaTo: '',
    area: '',
    city: '',
    notes: ''
  });
  const [errors, setErrors] = useState({});
  const [isSubmittingData, setIsSubmittingData] = useState(false);
  // const recaptchaRef = useRef(null);
  // const [captcha, setCaptcha] = useState({ captcha: false, "g-recaptcha-response": 0 });
  const [captchaFromUser, setCaptchaFromUser] = useState();
  const [isCaptchaValid, setIsCaptchaValid] = useState(false);

  const onCaptchaRefresh = () => {
    setCaptchaFromUser("");
    setIsCaptchaValid(false);
  };

  const onCaptchaChange = (v, s, success) => {
    setCaptchaFromUser(v);
    setIsCaptchaValid(success);
  };

  const propertiesOptions = [
    {
      id: 'أرض',
      label: t("Land"),
    },
    {
      id: 'دور',
      label: t("Floor"),
    },
    {
      id: 'شقة',
      label: t("Apartment"),
    },
    {
      id: 'فيلا',
      label: t("Villa"),
    },
    {
      id: 'استوديو',
      label: t("Studio"),
    },
    {
      id: 'غرفة',
      label: t("Room"),
    },
    {
      id: 'استراحة',
      label: t("Resort"),
    },
    {
      id: 'معرض',
      label: t("Showroom"),
    },
    {
      id: 'مكتب',
      label: t("Office"),
    },
    {
      id: 'مستودع',
      label: t("Warehouse"),
    },
    {
      id: 'مزرعة',
      label: t("Farm"),
    },
    {
      id: 'عمارة',
      label: t("Building"),
    }
  ];
  const regionsOptions = useMemo(
    () => {
      return REGIONS.map((region) => ({
        id: +region.REGION_ID,
        label: i18n.language === "ar" ? region.REGIONNAME_AR : region.REGIONNAME_EN,
      }));
    }, // eslint-disable-next-line
    [i18n.language]);
      
  const citiesOptions = useMemo(
    () => {
      return CITIES.map((city) => ({
        id: +city.CITY_ID,
        label: i18n.language === "ar" ? city.CITYNAME_AR : city.CITYNAME_EN,
        regionId: +city.REGION_ID,
      }))
    },// eslint-disable-next-line
    [i18n.language]);
    
  const districtsOptions = useMemo(
    () => {
      return DISTRICTS.map((district) => ({
        id: +district.DISTRICT_ID,
        label: i18n.language === "ar" ? district.DISTRICTNAME_AR : district.DISTRICTNAME_EN,
        regionId: +district.REGION_ID,
        cityId: +district.CITY_ID,
      }));
    }, // eslint-disable-next-line
    [i18n.language]);
    
  useEffect(() => {
    window.scroll({ top: 0, left: 0, behavior: "smooth" });
  }, []);
   
  
  function formIsValid() {
    const _errors = {};
    if (!data.name)
      _errors.name = t("EnterName");
  
    if (!data.phone) _errors.phone = t("EnterPhoneNumber");
    else if (!validatePhone(data.phone))
      _errors.phone = t("PhoneNumberIsIncorrect");

    if (!data.desire || !data.propertyType) _errors.desire = t("ChooseYourDesire");
  
    
    if (data.area === '') {
      _errors.area = t("PleaseSelectTheRegion");
    }
    if (data.city === '') {
      _errors.city = t("PleaseSelectTheCity");
    }
    if (data.neighbourhoodIds?.length === 0) {
      _errors.neighbourhoodIds = t("PleaseSelectTheNeighborhoods");
    }
    if (data.priceTo !== "" && data.priceFrom !== "") {
      if (+data.priceFrom?.toString().replace(/[,]+/g, "") > +data.priceTo?.toString().replace(/[,]+/g, ""))
        _errors.price = t("PleaseCheckPrices");
    } else {
      _errors.price = t("PleaseSpecifyPrices");
    }

    if (data.areaFrom !== "" && data.areaTo !== "") {
      if (+data.areaFrom?.toString().replace(/[,]+/g, "") > +data.areaTo?.toString().replace(/[,]+/g, ""))
        _errors.areas = t("PleaseCheckArea");
    } else {
      _errors.areas = t("PleaseSpecifyArea");
    }

    if (!captchaFromUser || !isCaptchaValid) {
      _errors.captcha = t("PleaseCheckRecapatcha");
    }
      
    setErrors(_errors);
    return Object.keys(_errors).length === 0;
  }

  function getFormatedPrice(price) {
    let value = price?.toString()?.replace(/[,]+/g, "");
    return value?.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,");
  }

  const handleSave = () => {
    if (!formIsValid())
      return;
    // if (captcha.captcha) {
    
      setIsSubmittingData(true);
      createRequest({
        ...data,
        language: i18n.language === "ar" ? "0" : "1",
        vendorName: vendor,
        interestingType: data.desire,
        priceFrom: data?.priceFrom?.toString().replace(/[,]+/g, ""),
        priceTo: data?.priceTo?.toString().replace(/[,]+/g, ""),
        areaFrom: data?.areaFrom?.toString().replace(/[,]+/g, ""),
        areaTo: data?.areaTo?.toString().replace(/[,]+/g, ""),
        seriousness: 3
        // "userId",
     
      }).then(res => {
        if (res.resCode === 0) {
          Swal.fire({
            title: t("DoneSuccessfully"),
            text: t("RequestHasBeenSentSuccessfully"),
            icon: "success",
            confirmButtonText: t("Close"),
          });
          setData({
            propertyType: '',
            name: '',
            phone: '',
            desire: "استئجار",
            neighbourhoodIds: [],
            priceFrom: '',
            priceTo: '',
            areaFrom: '',
            areaTo: '',
            area: '',
            city: '',
            notes: ''
          });
          window.scroll({ top: 0, left: 0, behavior: "smooth" });
        } else {
          toast.error(res.resStr);
          Swal.fire({
            title: t("TheProcessCannotBeCompleted"),
            text: res.resStr,
            icon: "error",
            confirmButtonText: t("Close"),
          });
        }
      }).finally(() => {
        setIsSubmittingData(false);
        // setCaptcha(old => { return { ...old, captcha: false }; });
    });
    // } 
  
  }
  
  return (
    <AppLayout pageTitle={t("RequestProperty")} withFooter>
      <PageHeader name={t("RequestProperty")} />
      <div className='contact-area pd-top-60 pd-bottom-65 '>
        <div className="col-12 contact-top">
          <h4>{t("FeelFreeToRequestAProperty")}</h4>
          <p>{t("FillOutTheFormBelowAndClickTheSendButton")}</p>
        </div>
        <div className="row pd-top-60 col-12 col-md-8 col-lg-4 mx-auto">
        
          <div className='col-12 mb-3'>
            <h4>{t("CustomerInfo")}</h4>
          </div>
          <div className={'col-12 col-lg-6 mb-4'}>
            <FormControl
              error={Boolean(errors.name)}
              className="col-12"
            >
              <TextField
                label={t("Name")}
                sx={{ width: "100%" }}
                type='text'
                inputProps={{ maxLength: 50 }}
                InputLabelProps={{
                  shrink: true,
                }}
                autoComplete="off"
                required
                variant={'outlined'}
                size='small'
                onChange={(e) => {
                  setData({ ...data, name: resolvePersianAndArabicNumbers(e.currentTarget.value) });
                }}
                error={Boolean(errors.name)}
                value={data.name}
              />
              {errors.name &&
                <FormHelperText component={Box}>
                  <Alert severity="error" className="custom-alert">
                    {errors.name}
                  </Alert>
                </FormHelperText>}
            </FormControl>
          </div>

          <div className={'col-12 mb-4 col-lg-6'}>
            <FormControl
              error={Boolean(errors.phone)}
              className="col-12"
            >
              <TextField
                label={t("PhoneNumber")}
                sx={{ width: "100%" }}
                type='text'
                inputProps={{ maxLength: 10 }}
                InputLabelProps={{
                  shrink: true,
                }}
                autoComplete="off"
                required
                variant={'outlined'}
                size='small'
                onChange={(e) => {
                  setData({ ...data, phone: preventString(e.currentTarget.value) });
                }}
                error={Boolean(errors.phone)}
                value={data.phone}
              />
              {errors.phone &&
                <FormHelperText component={Box}>
                  <Alert severity="error" className="custom-alert">
                    {errors.phone}
                  </Alert>
                </FormHelperText>}
            </FormControl>
          </div>

          <div className='col-12 mb-3 mt-3'>
            <h4>{t("RequestSpecification")}</h4>
          </div>
          <div className={'col-12 mb-4'}>
            <FormControl
              error={Boolean(errors.desire)}
              className="col-12"
            >
              {/* <FormLabel id="desire-group-label">{t("Desire")}</FormLabel> */}
              <RadioGroup
                aria-labelledby="desire-group-label"
                row
                value={data.desire}
                onChange={(e) => setData({ ...data, desire: e.target.value })}
              >
                <FormControlLabel sx={{ marginInlineStart: 0 }} value="استئجار" control={<Radio />} label={t("Rent")} />
                <FormControlLabel sx={{ marginInlineEnd: 0 }} value="شراء" control={<Radio />} label={t("Buy")} />
              </RadioGroup>
                      
            </FormControl>
          </div>

          <div className={'col-12 mb-4'}>
            <FormControl
              error={Boolean(errors.desire)}
              className="col-12"
            >
              <InputLabel htmlFor='type' required>{t("PropertyType")}</InputLabel>

              <Select
                label={t("PropertyType")}
                sx={{ width: "100%" }}
                type='text'
                required
                variant={'outlined'}
                size='small'
                onChange={(e) => {
                  setData({ ...data, propertyType: e.target.value });
                }}
                input={<OutlinedInput
                  notched
                  label={t("PropertyType")}
                  id="type"
                />}
                inputProps={{
                  name: "type",
                  id: "type",
                }}
                error={Boolean(errors.desire)}
                value={data.propertyType}
              >
                {propertiesOptions.map((item) => (
                  <MenuItem value={item.id}>{item.label}</MenuItem>
                ))}
              </Select>
            </FormControl>
            {errors.desire &&
              <FormHelperText component={Box}>
                <Alert severity="error" className="custom-alert">
                  {errors.desire}
                </Alert>
              </FormHelperText>}
          </div>
        
          <div className="col-12 mb-4">
            <>
              <TextField
                label={t("AreaFrom")}
                sx={{ width: "50%" }}
                InputLabelProps={{
                  shrink: true,
                }}
                required
                error={Boolean(errors.areas)}
                InputProps={{
                  inputProps: { min: 0 },
                  endAdornment: (
                    <InputAdornment position='end' sx={{ '& .MuiTypography-root': { fontSize: '13px' } }}>{t("M2")}</InputAdornment>
                  ),
                }}
                value={getFormatedPrice((data.areaFrom))}
                size='small'
                onChange={(v) => {
                  setData({ ...data, areaFrom: getFormatedPrice(Math.abs(preventString(v.currentTarget.value?.replace(/[,]+/g, "")))) });
                }}
              />
              <TextField
                label={t("AreaTo")}
                sx={{ width: "50%" }}
                InputLabelProps={{
                  shrink: true,
                }}
                required
                error={Boolean(errors.areas)}
                InputProps={{
                  inputProps: { min: 0 },
                  endAdornment: (
                    <InputAdornment position='end' sx={{ '& .MuiTypography-root': { fontSize: '13px' } }}>{t("M2")}</InputAdornment>
                  ),
                }}
                value={getFormatedPrice(data.areaTo)}
                size='small'
                onChange={(v) => {
                  setData({
                    ...data,
                    areaTo: getFormatedPrice(Math.abs(preventString(v.currentTarget.value?.replace(/[,]+/g, "")))),
                  });
                }}
              />
            </>
            {errors.areas && (
              <FormHelperText component={Box}>
                <Alert severity='error' className='custom-alert'>
                  {errors.areas}
                </Alert>
              </FormHelperText>
            )}
          </div>

          <div className="col-12 mb-4">
            <TextField
              label={t("PriceFrom")}
              sx={{ width: "50%" }}
              required
              error={Boolean(errors.price)}
              InputLabelProps={{
                shrink: true,
              }}
              InputProps={{
                inputProps: { min: 0 },
                endAdornment: (
                  <InputAdornment position='end' sx={{ '& .MuiTypography-root': { fontSize: '13px' } }}>{t("SAR")}</InputAdornment>
                ),
              }}
              value={getFormatedPrice((data.priceFrom))}
              size='small'
              onChange={(v) => {
                setData({
                  ...data,
                  priceFrom: getFormatedPrice(Math.abs(preventString(v.currentTarget.value?.replace(/[,]+/g, "")))),
                });
              }}
            />
            <TextField
              label={t("PriceTo")}
              required
              error={Boolean(errors.price)}
              sx={{ width: "50%" }}
              InputLabelProps={{
                shrink: true,
              }}
              InputProps={{
                inputProps: { min: 0 },
                endAdornment: (
                  <InputAdornment position='end' sx={{ '& .MuiTypography-root': { fontSize: '13px' } }}>{t("SAR")}</InputAdornment>
                ),
              }}
              value={getFormatedPrice(data.priceTo)}
              size='small'
              onChange={(v) => {
                setData({
                  ...data,
                  priceTo: getFormatedPrice(Math.abs(preventString(v.currentTarget.value?.replace(/[,]+/g, "")))),
                });
              }}
            />
            {errors.price && (
              <FormHelperText component={Box}>
                <Alert severity='error' className='custom-alert'>
                  {errors.price}
                </Alert>
              </FormHelperText>
            )}
          </div>
             
          <div className="col-12 mb-4">
            <FormControl
              sx={{ width: "100%" }}
              error={Boolean(errors.area)}
            >
              <Autocomplete
                // disablePortal
                size='small'
                required
                slotProps={{ popper: { sx: { direction: 'rtl', zIndex: 9999999999999 } } }}
                options={regionsOptions}
                value={regionsOptions.filter((i) => +i.id === +data?.area)?.[0] || null}
                sx={{ width: "100%" }}
                onChange={(v, newValue) => {
                  setData({
                    ...data,
                    area: newValue?.id || "",
                    neighbourhoodIds: [],
                    city: ''
                  });
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    sx={{ width: "100%" }}
                    required
                    InputLabelProps={{ shrink: true }}
                    error={Boolean(errors.area)}
                    variant='outlined'
                    label={t("Region")}
                  />
                )}
              />
              {errors.area && (
                <FormHelperText component={Box}>
                  <Alert severity='error' className='custom-alert'>
                    {errors.area}
                  </Alert>
                </FormHelperText>
              )}
            </FormControl>
          </div>
          <div className="col-12 mb-4">
            <FormControl
              sx={{ width: "100%" }}
              error={Boolean(errors.city)}
            >
              <Autocomplete
                // disablePortal
                size='small'
                slotProps={{ popper: { sx: { direction: 'rtl', zIndex: 9999999999999 } } }}
                required
                disabled={!data?.area}
                options={citiesOptions.filter((i) => +i.regionId === +data?.area)}
                value={citiesOptions.filter((i) => +i.id === +data?.city)?.[0] || null}
                sx={{ width: "100%" }}
                noOptionsText={t("ThereAreNoCitiesAssociatedWithTheSelectedRegion")}
                onChange={(event, newValue) => setData({ ...data, city: newValue?.id || "", neighbourhoodIds: [] })}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    sx={{ width: "100%" }}
                    required
                    InputLabelProps={{ shrink: true }}
                    error={Boolean(errors.city)}
                    variant='outlined'
                    label={t("City")}
                  />
                )}
              />
              {errors.city && (
                <FormHelperText component={Box}>
                  <Alert severity='error' className='custom-alert'>
                    {errors.city}
                  </Alert>
                </FormHelperText>
              )}
            </FormControl>
          </div>
          <div className="col-12 mb-4">
            <FormControl
              sx={{ width: "100%" }}
              error={Boolean(errors.neighbourhoodIds)}
            >
              <Autocomplete
                // disablePortal
                size='small'
                slotProps={{ popper: { sx: { direction: 'rtl', zIndex: 9999999999999 } } }}
                required
                multiple={true}
                disabled={!data?.city}
                options={districtsOptions.filter(
                  (i) =>
                    +i.cityId === +data?.city &&
                    +i.regionId === +data?.area
                )}
                value={
                  districtsOptions.filter(
                    (i) => data?.neighbourhoodIds?.includes(+i.id) || data?.neighbourhood?.includes(+i.id)
                  ) || []
                }
                sx={{ width: "100%" }}
                noOptionsText={t("ThereAreNoNeighborhoodsAssociatedWithTheSelectedCity")}
                onChange={(event, newValue) => {
                  const ids = newValue?.map(i => i.id);
                  setData({ ...data, neighbourhoodIds: ids });
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    sx={{ width: "100%" }}
                    required
                    InputLabelProps={{ shrink: true }}
                    error={Boolean(errors.neighbourhoodIds)}
                    variant='outlined'
                    label={t("Neighborhoods")}
                  />
                )}
              />
              {errors.neighbourhoodIds && (
                <FormHelperText component={Box}>
                  <Alert severity='error' className='custom-alert'>
                    {errors.neighbourhoodIds}
                  </Alert>
                </FormHelperText>
              )}
            </FormControl>
          </div>
          <div className="col-12 mb-4">
            <TextField
              label={t("Notes")}
              sx={{ width: "100%" }}
              InputLabelProps={{
                shrink: true,
              }}
              inputProps={{ maxLength: 500 }}
              value={data.notes}
              size='small'
              multiline
              rows={5}
              onChange={(v) => {
                setData({
                  ...data, notes: resolvePersianAndArabicNumbers(
                    v.currentTarget.value?.length >= 500 ? v.currentTarget.value?.substring(0, 500) : v.currentTarget.value
                  )
                });
              }}
            />
            <h6 className='rightalign mt-2'>
              {data.notes
                ? parseInt(data?.notes?.length) + "/500"
                : "0/500"}
            </h6>
          </div>
          <div className='col-12'>
          <AmakknCaptcha
                              onChange={(v, s, success) =>
                                onCaptchaChange(v, s, success)
                              }
                              onRefresh={() => onCaptchaRefresh()}
                              isSubmitClicked={isSubmittingData}
                            />
                </div>
          {/* <div className='col-12 recaptcha-wrapper'>
                  <ReCAPTCHA
                    onChange={(key) => setCaptcha({
                      captcha: true,
                      "g-recaptcha-response": key,
                    })}
                    // size="invisible"
                    onExpired={() => {
                      recaptchaRef.current?.reset();
                    }}
                    ref={recaptchaRef}
                    sitekey={process.env.REACT_APP_ReCAPTCHA}
                    theme='light'
            />
            
          </div> */}
          {errors.captcha &&
              <FormHelperText component={Box}>
                <Alert severity="error" className="custom-alert">
                  {errors.captcha}
                </Alert>
            </FormHelperText>}
          
          <div className='col-12 mt-2'>
            <LoadingButton
              classes='btn primary-btn'
              handleClick={handleSave}
              label={
                isSubmittingData ? t("RequestNow") : (
                  <>
                    {t("RequestNow")} <ArrowBack sx={i18n.language === "en" ? { transform: 'rotate(180deg)' } : {}} />
                  </>
                )
              }
              loading={isSubmittingData}
            />
          </div>
        </div>
     
      </div>
    </AppLayout>
  );
}
