import React, { useEffect, useState } from "react";
import { Avatar, IconButton, Paper, Skeleton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { toast } from "react-toastify";
import { getDemoSnakbar, getLocalizedText, openDeleteModal } from "../../../../helpers";
import NoData from '../../../../components/global-components/no-data';
import { useAuth } from "../../../../helpers/context";
import { addOrEditProjectDetails, getProjects } from "../../../../api/userApi";
import InterestsModal from "../../projects/components/interests-modal";
import LoadingData from "../../../../components/global-components/loading-data";
import { DEMO_VENDORS } from "../../../../constants";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { useNavigate } from "react-router-dom";
import ActionsPopper from "../../../../components/global-components/actions-popper";
import { useTranslation } from "react-i18next";

const publicUrl = process.env.REACT_APP_URL + "/";

const Projects = () => {
    const { token, vendor } = useAuth();
    const [loadingData, setLoadingData] = useState(true);
    const [data, setData] = useState([]);
    const [timeStamp, setTimeStamp] = useState(new Date());
    const [openInterestsModal, setOpenInterestsModal] = useState({ visible: false, data: [], projectName: '' });
    const [openDemoSnackBar, setOpenDemoSnackBar] = React.useState(false);
    const [project, setProject] = useState();
    const [openPopper, setOpenPopper] = useState(false);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const history = useNavigate();
    const { t, i18n } = useTranslation();

    useEffect(() => {
        if (vendor) {
            getProjects({
                userName: vendor,
                userId: token,
                language: i18n.language === "ar" ? '0' : '1',
            }).then((_response) => {
                if (_response.resCode === 0) setData(_response.response);
                setLoadingData(false);
            }).catch(e => setLoadingData(false));
        }
    }, [timeStamp, vendor, token, i18n.language]);

    const handleDelete = (id) => {
        addOrEditProjectDetails({
            projectId: id,
            userId: token,
            language: i18n.language === "ar" ? '0' : '1',
            isDelete: "1",
        }).then((property) => {
            if (property.resCode === 0) {
                toast.success(t("ProjectHasBeenDeletedSuccessfully"));
                setTimeStamp(new Date());
            }
        });
    }
    const handlePublishStatus = (id, status) => {
        addOrEditProjectDetails({
            projectId: id,
            userId: token,
            language: i18n.language === "ar" ? '0' : '1',
            publishStatus: status
        }).then((property) => {
            if (property.resCode === 0) {
                toast.success(status === '0' ? t("ProjectHasBeenSuccessfullyUnpublished") : t("ProjectHasBeenPublishedSuccessfully"));
                setTimeStamp(new Date());
            }
        });
    }
    
  
    return (
        <>
            {loadingData ? (
                <Skeleton
                    variant='rectangular'
                    height={450}
                    style={{ borderRadius: '16px' }}
                    width={'100%'}
                />
            ) : (
                <div className='custom-card'>
                    <div className="card-header">
                        <h4>{t("ProjectsList", { count: data?.length > 0 ? data?.length : null })}</h4>
                        <h6>{t("ProjectsListDesc")}</h6>
                    </div>
                    <div className="card-body">
                        <div style={{ display: 'flex', height: data.length > 0 ? '420px' : '370px', width: '100%' }}>

                            <TableContainer className="table-container responsive-table"
                                component={Paper}>
                                <Table sx={{ minWidth: '100%' }}>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell align="right">{t("Image")}</TableCell>
                                            <TableCell align="right">{t("Name")}</TableCell>
                                            <TableCell align="right">{t("Area")}</TableCell>
                                            <TableCell align="right">{t("TotalUnits")}</TableCell>
                                            <TableCell align="right">{t("Actions")}</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {loadingData ? <TableRow
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        ><TableCell colSpan={5}><LoadingData /></TableCell></TableRow> :
                                            data?.length > 0 ? data.map((row) => (
                                                <TableRow
                                                    key={row.id}
                                                    sx={{ '&:last-child td, &:last-child th': { border: 0 }, '& td, & th': { pt: 0.57, pb: 0.57 } }}
                                                >
                                                    <TableCell align="right" component="th" scope="row">
                                                        <Avatar
                                                            sx={{ width: 48, height: 48 }}
                                                            variant="square"
                                                            src={row.images.length > 0 && row.images[0] ? row.images[0] :
                                                                `${publicUrl}assets/img/defimgs/1.svg`} />
                                                    </TableCell>
                                                    <TableCell align="right">{getLocalizedText(row.name, i18n.language, false)}</TableCell>
                                                    <TableCell align="right">{Intl.NumberFormat("en").format(row.area)}</TableCell>
                                                    <TableCell align="right">{Intl.NumberFormat("en").format(row.totalUnits)}</TableCell>
                                                    <TableCell align="right">
                                                        <IconButton
                                                            onClick={(e) => {
                                                                setOpenPopper(!openPopper);
                                                                setAnchorEl(anchorEl ? null : e.target);
                                                                setProject(row);
                                                            }}
                                                            sx={{
                                                                background: '#fff',
                                                                zIndex: 99,
                                                                color: 'var(--main-color-one)',
                                                                boxShadow: 'rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px',
                                                                '&:hover,&:focus': {
                                                                    color: '#fff',
                                                                    background: 'var(--main-color-one)'
                                                                }
                                                            }}>
                                                            <MoreVertIcon />
                                                        </IconButton>
                                                    </TableCell>
                                                </TableRow>
                                            )) :
                                                <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                                    <TableCell colSpan={5}>
                                                        <NoData msg={t("ThereAreNoProjectsPublishedByYou")} />
                                                    </TableCell>
                                                </TableRow>
                                        }
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </div>
                            
                    </div>
                    <ActionsPopper
                        openPopper={openPopper}
                        anchorEl={anchorEl}
                        setAnchorEl={setAnchorEl}
                        setOpenPopper={setOpenPopper}
                        items={
                            [
                                {
                                    title: t("ProjectDetails"),
                                    action: () => history(`/admin/project-details/${project.id}`)
                                },
                                {
                                    title: t("ShowInterests"),
                                    action: () => setOpenInterestsModal({
                                        visible: true,
                                        data: project.interests,
                                        projectName: project.name
                                    })
                                },
                                {
                                    title: t("Edit"),
                                    action: () => history(`/admin/edit-project/${project.id}`)
                                },
                                {
                                    title: project?.publishStatus === '1' ? t("UnpublishTheProject") : t("PublishTheProject"),
                                    action: () => {
                                        if (DEMO_VENDORS.includes(vendor)) {
                                            setOpenDemoSnackBar(true);
                                        } else {
                                            if (project?.publishStatus === '1')
                                                openDeleteModal(t("ConfirmUnpublishing"), t("AreYouSureYouWantToUnpublishThisProject"),
                                                    () => handlePublishStatus(project.id, '0'), t("Unpublish"));
                                            else
                                                openDeleteModal(t("ConfirmPublication"), t("AreYouSureToPublishThisProject"),
                                                    () => handlePublishStatus(project.id, '1'), t("Publish"));
                                        }
                                    }
                                },
                                {
                                    title: t("Delete"),
                                    action: () => {
                                        if (DEMO_VENDORS.includes(vendor)) {
                                            setOpenDemoSnackBar(true);
                                        } else {
                                            openDeleteModal(t("ConfirmDeletion"), t("AreYouSureToDeleteThisProject"),
                                                () => handleDelete(project.id))
                                        }
                                    }
                                }
                            ]
                        }
                    />
                    <InterestsModal
                        open={openInterestsModal.visible}
                        data={openInterestsModal.data || []}
                        onClose={() => setOpenInterestsModal({ visible: false, data: [], projectName: '' })}
                        loadingData={false}
                        projectName={openInterestsModal.projectName}
                    />
                    {DEMO_VENDORS.includes(vendor) && getDemoSnakbar(openDemoSnackBar, setOpenDemoSnackBar)}

                </div>
            )}
        </>
    );
}

export default Projects;
